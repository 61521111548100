/* eslint-disable max-classes-per-file */
/* eslint-disable no-useless-constructor */
import { CONCEPTS } from '@rover/rsdk/src/concepts';
import type { ConsentPurpose } from '@rover/rsdk/src/modules/Analytics';
import { AnalyticsEvent } from '@rover/types/src/Analytics';

/**
 * &nbsp;
 *
 * # Description
 *
 * Fires when user clicks on "Learn More" to open the modal to find out more about RoverProtect
 *
 * &nbsp;
 */
export class LearnMoreClick extends AnalyticsEvent {
  eventName = 'roverprotect-learn-more';

  consentPurpose: ConsentPurpose = 'performance';

  concept = CONCEPTS.bookings.trust_and_safety;

  constructor(
    eventProperties: { screenReference?: string },
    observabilityTags: {
      screenReference: string;
    }
  ) {
    super(eventProperties, observabilityTags);
  }
}

/**
 * &nbsp;
 *
 * # Description
 *
 * Fires when user clicks on "Learn More about RoverProtect" button inside modal, or info card on homepage
 *
 * &nbsp;
 */
export class InfoCardLearnMoreClick extends AnalyticsEvent {
  eventName = 'roverprotect-inforcard-learnmore-click';

  consentPurpose: ConsentPurpose = 'performance';

  concept = CONCEPTS.bookings.trust_and_safety;

  constructor(
    eventProperties: { screenReference?: string },
    observabilityTags: {
      screenReference: string;
    }
  ) {
    super(eventProperties, observabilityTags);
  }
}

/**
 * &nbsp;
 *
 * # Description
 *
 * Fires when user clicks on "Learn More about Rover Guarantee" button inside modal, or info card on homepage
 *
 * &nbsp;
 */
export class InfoCardLearnMoreGuaranteeClick extends AnalyticsEvent {
  eventName = 'roverprotect-infocard-rover-guarantee';

  consentPurpose: ConsentPurpose = 'performance';

  concept = CONCEPTS.bookings.trust_and_safety;

  constructor(
    eventProperties: { screenReference?: string },
    observabilityTags: {
      screenReference: string;
    }
  ) {
    super(eventProperties, observabilityTags);
  }
}

/**
 * &nbsp;
 *
 * # Description
 *
 * Fires when user clicks on "Book a local sitter" button in info card on homepage
 *
 * &nbsp;
 */
export class HomepageBookSitterClick extends AnalyticsEvent {
  eventName = 'roverprotect-homepage-search-click';

  consentPurpose: ConsentPurpose = 'performance';

  concept = CONCEPTS.bookings.trust_and_safety;

  constructor(
    eventProperties: { screenReference?: string },
    observabilityTags: {
      screenReference: string;
    }
  ) {
    super(eventProperties, observabilityTags);
  }
}

/**
 * &nbsp;
 *
 * # Description
 *
 * RoverProtect survey event
 * Fires when user meets the conditions to display the survey (scroll/wait requirements on the homepage,
 * or reaching the checkout screen) and survey successfully displays
 *
 * https://roverdotcom.atlassian.net/wiki/spaces/TECH/pages/3663986689/Copy+of+Functional+Spec+C2+RoverProtect+T+S+messaging+across+owners+core+booking+funnel#%5BinlineExtension%5DAwareness-Survey
 *
 * &nbsp;
 */

export class SurveyDisplay extends AnalyticsEvent {
  eventName = 'roverprotect-ch-2-survey-display';

  consentPurpose: ConsentPurpose = 'performance';

  concept = CONCEPTS.bookings.trust_and_safety;

  constructor(
    eventProperties: {
      commonId?: string;
      personOpk?: string;
      screenReference: string;
      variant: string;
      shownSurvey: boolean;
      surveySlug: string;
    },
    observabilityTags: {
      screenReference: string;
    }
  ) {
    super(eventProperties, observabilityTags);
  }
}

/**
 * &nbsp;
 *
 * # Description
 *
 * RoverProtect survey event
 * Fires when user submits their answers to the survey
 *
 * https://roverdotcom.atlassian.net/wiki/spaces/TECH/pages/3663986689/Copy+of+Functional+Spec+C2+RoverProtect+T+S+messaging+across+owners+core+booking+funnel#%5BinlineExtension%5DAwareness-Survey
 *
 * &nbsp;
 */

export class SurveyAnswer extends AnalyticsEvent {
  eventName = 'roverprotect-ch-2-survey-answer';

  consentPurpose: ConsentPurpose = 'performance';

  concept = CONCEPTS.bookings.trust_and_safety;

  constructor(
    eventProperties: {
      commonId?: string;
      personOpk?: string;
      screenReference: string;
      surveySlug: string;
      questionSlug: string;
      answers: Array<{ answerSlug: string; selected?: boolean | null }>;
    },
    observabilityTags: {
      screenReference: string;
    }
  ) {
    super(eventProperties, observabilityTags);
  }

  static getAnswers(
    allAnswers: Array<{ answerSlug: string; content: string }>,
    selectedAnswers: Record<string, boolean>
  ): Array<{ answerSlug: string; selected: boolean }> {
    return allAnswers.map((answer) => ({
      answerSlug: answer.answerSlug,
      selected: selectedAnswers[answer.answerSlug] ?? false,
    }));
  }
}
