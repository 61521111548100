import React, { ComponentType } from 'react';
import { MessageDescriptor } from '@lingui/core';
import { Trans } from '@lingui/react';
import styled, { css } from 'styled-components';

import { Color, DSTokenMap, FontSize, FontWeight, Spacing } from '@rover/kibble/styles';
import { isTranslatable } from '@rover/utilities/i18n';

export type Props = {
  id?: string;
  title?: MessageDescriptor | React.ReactNode;
  icon: JSX.Element | null;
  IconClass?: ComponentType<{
    fill?: Color;
  }>;
  label?: string;
  color?: Color | string;
  content: MessageDescriptor | React.ReactNode;
  hasBorder: boolean;
  horizontal?: boolean | null | undefined;
  role?: string | undefined;
};

export const Wrapper = styled.li`
  display: flex;
  width: 100%;
  &:not(:first-child) {
    margin-top: ${Spacing.L.toString()};
  }
`;

type BulletProps = {
  bulletColor?: Color | string;
  hasBorder: boolean;
};

export const Bullet = styled.div<BulletProps>`
  align-items: center;
  fill: ${(props) => (props.bulletColor ? props.bulletColor.toString() : '')};
  color: ${(props) => (props.bulletColor ? props.bulletColor.toString() : '')};
  display: flex;
  height: 32px;
  justify-content: center;
  width: 32px;

  ${(props) =>
    props.hasBorder
      ? css<BulletProps>`
          border-radius: 16px;
          border: 1px solid
            ${({ bulletColor = DSTokenMap.BORDER_COLOR_PRIMARY }) =>
              bulletColor !== 'inherit'
                ? bulletColor.toString()
                : DSTokenMap.BORDER_COLOR_PRIMARY.toString()};
        `
      : ''}

  ${(props) =>
    !props.hasBorder
      ? css`
          font-size: ${FontSize.XL.toString()};
        `
      : ''};
`;

export const ContentColumn = styled.div`
  font-size: ${FontSize.L.toString()};
  margin-left: ${Spacing.S.toString()};
  text-align: left;
`;

export const Title = styled.span`
  display: inline-block;
  font-weight: ${FontWeight.BOLD.toString()};
  margin-bottom: ${Spacing.XS.toString()};
  max-width: 100%;
`;

export const ContentContainer = styled.div<{ hasTitle: boolean }>`
  ${(props) =>
    !props.hasTitle
      ? css`
          margin-top: ${Spacing.XS.toString()};
        `
      : ''};
`;

const renderTitle = (title): JSX.Element | null => {
  if (!title) return null;

  if (React.isValidElement(title)) {
    return <Title>{title}</Title>;
  }

  return (
    <Title>
      <Trans id={title} />
    </Title>
  );
};

const renderBullet = (label, icon, IconClass, color): JSX.Element => {
  if (label) {
    return label;
  }

  if (IconClass) {
    return <IconClass fill={color} />;
  }

  if (icon) {
    return icon;
  }

  throw new Error('renderBullet method in BulletedListItem called without label or icon args');
};

const BulletedListItem = (props: Props): JSX.Element => {
  const { title, icon, IconClass, label, content, hasBorder, color, ...other } = props;
  const translatedContent = isTranslatable(content) ? (
    <Trans id={typeof content === 'string' ? content : content.id} />
  ) : (
    content
  );
  return (
    <Wrapper {...other}>
      <div>
        <Bullet hasBorder={hasBorder} bulletColor={color}>
          {renderBullet(label, icon, IconClass, color)}
        </Bullet>
      </div>
      <ContentColumn>
        {renderTitle(title)}
        <ContentContainer hasTitle={!!title}>{translatedContent}</ContentContainer>
      </ContentColumn>
    </Wrapper>
  );
};

BulletedListItem.defaultProps = {
  id: '',
  color: 'inherit',
  hasBorder: true,
  icon: null,
  label: '',
  title: '',
};
export default BulletedListItem;
