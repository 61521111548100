import React from 'react';
import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import styled from 'styled-components';

import { DSTokenMap, MQ, Spacing } from '@rover/kibble/styles';
import { Image } from '@rover/react-lib/src/components/images';
import { PageLayout, VerticalLayout } from '@rover/react-lib/src/components/layouts';
import globeDoodle from '@rover/react-lib/src/images/homepage/globe_doodle.svg?url';
import { useI18n } from '@rover/rsdk/src/modules/I18n';
import type { CountryFooterLink } from '@rover/types';

const NUM_COLUMNS = 5;

export type Props = {
  countryFooterLinks: CountryFooterLink[];
};

const CountryBackgroundWrapper = styled.div`
  background: ${DSTokenMap.BACKGROUND_COLOR_CONTRAST.toString()};
  padding-bottom: ${Spacing.S.toString()};
`;

const CountryWrapper = styled(PageLayout)`
  margin-bottom: ${Spacing.S.toString()};
  margin-top: 0px;
  padding-top: ${Spacing.S.toString()};
`;

const StyledCountryLayout = styled(VerticalLayout)`
  align-items: normal;
  margin-top: ${Spacing.S.toString()};
  background: ${DSTokenMap.BACKGROUND_COLOR_CONTRAST.toString()};
  color: ${DSTokenMap.BACKGROUND_COLOR_SECONDARY.toString()};

  a:link,
  a:visited,
  a:hover,
  a:active {
    color: ${DSTokenMap.BACKGROUND_COLOR_SECONDARY.toString()};
    text-decoration: none;
  }
`;

const StyledFlexContainerDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledLeftDiv = styled.div`
  width: 80%;

  ${MQ.SM_DOWN.toString()} {
    width: 100%;
  }
`;

const StyledRightDiv = styled.div`
  padding-bottom: 20px;
  margin-right: auto;
  margin-left: auto;
`;

export const StyledCountryHeader = styled.h3`
  text-align: left;
  font-weight: bold;
  margin-bottom: 0px;
`;

export const StyledCountryList = styled.ul`
  column-count: ${NUM_COLUMNS};
  column-gap: ${Spacing.L.toString()};
  list-style-type: none;
  padding: 0;
  list-style-type: none;

  a:hover {
    text-decoration: underline;
  }

  ${MQ.SM_DOWN.toString()} {
    column-count: ${NUM_COLUMNS - 2};
  }
  ${MQ.XS_DOWN.toString()} {
    column-count: ${NUM_COLUMNS - 3};
  }
`;

const StyledCountryListItem = styled.li<{ separator: boolean }>`
  margin-bottom: ${Spacing.XS.toString()};
  padding-bottom: 0px;
`;

const CountriesLinks = ({ countryFooterLinks }: Props): JSX.Element => {
  const { i18n } = useI18n();

  return (
    <CountryBackgroundWrapper>
      <CountryWrapper>
        <StyledCountryLayout>
          <StyledCountryHeader>
            <Trans id="Trusted around the world" />
          </StyledCountryHeader>
          <StyledFlexContainerDiv>
            <StyledLeftDiv>
              <StyledCountryList role="list">
                {countryFooterLinks.map(({ name, url }: CountryFooterLink, index: number) => {
                  const columnMarker = Math.round(countryFooterLinks.length / NUM_COLUMNS);

                  return (
                    <StyledCountryListItem
                      key={name}
                      separator={index % columnMarker === 0}
                      role="listitem"
                    >
                      <a key={url} href={url}>
                        {name}
                      </a>
                    </StyledCountryListItem>
                  );
                })}
              </StyledCountryList>
            </StyledLeftDiv>
            <StyledRightDiv>
              <Image
                src={globeDoodle}
                alt={i18n._(/* i18n: Alt text for globe image */ t`Globe Doodle`)}
                height="125"
                backgroundColor="transparent"
              />
            </StyledRightDiv>
          </StyledFlexContainerDiv>
        </StyledCountryLayout>
      </CountryWrapper>
    </CountryBackgroundWrapper>
  );
};

export default CountriesLinks;
