/* eslint-disable max-classes-per-file */

import { CONCEPTS } from '@rover/rsdk/src/concepts';
import type { ConsentPurpose } from '@rover/rsdk/src/modules/Analytics';
import { AnalyticsEvent } from '@rover/types/src/Analytics';

abstract class MarketingOptInEvent extends AnalyticsEvent {
  consentPurpose: ConsentPurpose = 'marketing';

  concept = CONCEPTS.marketing.growth;
}

/**
 * &nbsp;
 *
 * # Description
 *
 * Triggered when the user views the marketing opt-in modal.
 *
 * &nbsp;
 */
export class MarketingOptInView extends MarketingOptInEvent {
  eventName = 'opt-in-modal-view';
}

/**
 * &nbsp;
 *
 * # Description
 *
 * Triggered when the user declines the marketing opt-in modal.
 *
 * &nbsp;
 */
export class MarketingOptInDecline extends MarketingOptInEvent {
  eventName = 'opt-in-modal-decline';
}

/**
 * &nbsp;
 *
 * # Description
 *
 * Triggered when the user accepts the marketing opt-in modal.
 *
 * &nbsp;
 */
export class MarketingOptInAccept extends MarketingOptInEvent {
  eventName = 'opt-in-modal-accept';
}
