import React from 'react';
import styled, { css } from 'styled-components';

import { Box, Heading } from '@rover/kibble/core';
import { Props as BoxProps } from '@rover/kibble/core/Box/Box';
import { Color } from '@rover/kibble/styles';

import type { CardStyleOptions } from './Card.constants';
import CardStyle from './Card.constants';

export type Props = BoxProps & {
  cardStyle?: CardStyleOptions;
  footer?: React.ReactNode;
  isStacked?: boolean;
  hasHr?: boolean;
};

type CardWrapperProps = {
  isStacked?: boolean;
};

// we need to use a styled-component here for the pseudo selectors
const CardWrapper = styled(Box)<CardWrapperProps>`
  ${(props) =>
    props.isStacked &&
    css`
      &:not(:first-of-type) {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }

      &:not(:last-of-type) {
        border-bottom-width: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    `};
`;

const Card = ({
  hasHr = false,
  footer = false,
  cardStyle = 'default',
  isStacked = false,
  children,
  ...boxProps
}: Props): React.ReactElement => (
  <CardWrapper
    data-testid="card-wrapper"
    width="100%"
    borderRadius="4px"
    padding="6x"
    position="relative"
    isStacked={isStacked}
    {...CardStyle[cardStyle]}
    {...boxProps}
  >
    {hasHr && (
      <Box
        as="hr"
        data-testid="card-hr"
        width="70px"
        mb="5x"
        border="0 none"
        borderBottom={`3px solid ${Color.GREEN_600.toString()}`}
      />
    )}
    {children}
    {footer && (
      <Box
        data-testid="card-footer"
        textAlign="right"
        position={boxProps.height ? 'absolute' : 'static'}
        right={boxProps.padding || '6x'}
        bottom={boxProps.padding || '6x'}
      >
        {/* We're using a Heading here to match the previous design's 26px text */}
        <Heading as="span" size="400" responsive={false} fontWeight="regular" textColor="tertiary">
          {footer}
        </Heading>
      </Box>
    )}
  </CardWrapper>
);

export default Card;
