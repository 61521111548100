import React, { useState } from 'react';
import z from 'zod';

import { Button, Flex } from '@rover/kibble/core';
import { Form, useKibbleForm } from '@rover/kibble/forms';
import RadioField from '@rover/kibble/forms/fields/RadioField';
import { QuestionDefinitionSelect } from '@rover/rsdk/src/apiClient/latest';

export type Props = {
  questionDefinition: QuestionDefinitionSelect;
  submitButtonText: string;
  onSubmit: (selectedAnswers: Record<string, boolean>) => void;
};

const schema = z.object({
  answerSlug: z.string().min(1),
});

const BottomSheetSurveySingleSelectQuestion = ({
  questionDefinition,
  submitButtonText,
  onSubmit,
}: Props): JSX.Element => {
  const [selectedAnswer, setSelectedAnswer] = useState<string | null>(null);
  const { configureField, formProps } = useKibbleForm({ schema });

  return (
    <Form {...formProps} hideRequiredFieldWarning>
      <Flex flexDirection="column">
        <RadioField
          {...configureField('answerSlug')}
          required={false}
          primaryLabel={questionDefinition.content}
          options={questionDefinition.answers.map(({ answerSlug, content }) => ({
            value: answerSlug,
            primaryLabel: content,
          }))}
          onChange={({ value }) => setSelectedAnswer(value as string)}
        />
        <Button
          variant="primary"
          size="small"
          fullWidth
          onClick={() => onSubmit({ [selectedAnswer!]: true })}
          disabled={selectedAnswer === null}
        >
          {submitButtonText}
        </Button>
      </Flex>
    </Form>
  );
};

export default BottomSheetSurveySingleSelectQuestion;
