import React, { useEffect, useState } from 'react';

import BottomSheetSurvey from '@rover/react-lib/src/components/BottomSheet/BottomSheetSurvey';
import type { ScreenReferenceEnum } from '@rover/react-lib/src/components/RoverProtectSignals/utils/analyticsTypes';
import EXPERIMENTS from '@rover/react-lib/src/constants/experiments.constants';
import {
  HiddenSurveyDisplay,
  SurveyDefinition,
  useApiSurveyCreate,
  useApiSurveyRetrieve,
} from '@rover/rsdk/src/apiClient/latest';
import { emitAnalyticsEvent } from '@rover/rsdk/src/modules/Analytics';
import { Logger } from '@rover/rsdk/src/modules/Logging';
import { getCookie } from '@rover/rsdk/src/modules/Network/cookies';
import { isMobileBrowser } from '@rover/rsdk/src/modules/Network/userAgent';

import { SurveyAnswer, SurveyDisplay } from '../utils/analyticsEvents';
import {
  SURVEY_NEXT_BUTTON_TEXT,
  SURVEY_SUBMIT_BUTTON_TEXT,
} from '../utils/RoverProtectSignals.constants';

const SURVEY_SLUG = 'rover-protect-ch-2-survey';

type Props = {
  showRoverProtectSignalsWithSurvey: boolean;
  showRoverProtectNoSignalsWithSurvey: boolean;
  screenReference: ScreenReferenceEnum;
};

const RoverProtectSurvey = ({
  showRoverProtectSignalsWithSurvey,
  showRoverProtectNoSignalsWithSurvey,
  screenReference,
}: Props): JSX.Element | null => {
  const { data } = useApiSurveyRetrieve(SURVEY_SLUG);
  const { mutate: mutateCreate } = useApiSurveyCreate();
  const isMobile = isMobileBrowser();
  const commonId = getCookie('rover_cid');

  const [isClient, setIsClient] = useState<boolean>(false);
  const [showSurvey, setShowSurvey] = useState<boolean>();
  const [isSurveyDisplaySent, setIsSurveyDisplaySent] = useState<boolean>(false);

  const shouldDisplaySurvey =
    showRoverProtectSignalsWithSurvey || showRoverProtectNoSignalsWithSurvey;

  const getVariant = (): string => {
    if (showRoverProtectSignalsWithSurvey)
      return EXPERIMENTS.ROLLOUT_ROVER_PROTECT_CH_2_EXPERIMENT.roverProtectSignalsWithSurvey;
    if (showRoverProtectNoSignalsWithSurvey) {
      return EXPERIMENTS.ROLLOUT_ROVER_PROTECT_CH_2_EXPERIMENT.roverProtectNoSignalsWithSurvey;
    }
    return EXPERIMENTS.ROLLOUT_ROVER_PROTECT_CH_2_EXPERIMENT.holdout;
  };

  // MARK: COMMON LOGIC
  const getAnswersSlug = (selectedAnswers): string[] => {
    const answers = Object.keys(selectedAnswers);
    if (answers.includes('none-of-the-above')) {
      return ['none-of-the-above'];
    }
    return answers;
  };

  const getDisablingSelectedAnswers = (
    allAnswers,
    selectedAnswers
  ): Record<string, boolean> | null => {
    const disablingAnswers = allAnswers.filter((answer) => answer.disabling);
    const disablingSelectedAnswer = disablingAnswers.filter((answer) =>
      Object.keys(selectedAnswers).some((selectedSlug) => selectedSlug === answer.answerSlug)
    )[0];

    return disablingSelectedAnswer !== undefined
      ? { [disablingSelectedAnswer.answerSlug]: true }
      : null;
  };

  // Checks if the client is available
  // useEffect is only avail on Client and is used to avoid SSR issues.
  useEffect(() => {
    setIsClient(true);
  }, []);

  useEffect(() => {
    if (data) {
      const isHiddenSurvey = (data as HiddenSurveyDisplay)?.hiddenSurvey ?? false;

      if (!isHiddenSurvey && shouldDisplaySurvey) {
        setShowSurvey(true);
      }
      if (!isSurveyDisplaySent) {
        const shownSurvey =
          shouldDisplaySurvey && (data as SurveyDefinition)?.surveydisplayId !== undefined;

        emitAnalyticsEvent(
          new SurveyDisplay(
            {
              screenReference,
              commonId,
              surveySlug: SURVEY_SLUG,
              variant: getVariant(),
              shownSurvey: shownSurvey && !isHiddenSurvey,
            },
            {
              screenReference,
            }
          )
        );
        setIsSurveyDisplaySent(true);
      }
    }
  }, [data]);

  if (!isClient || !data) return null;

  if ((data as HiddenSurveyDisplay)?.hiddenSurvey ?? false) return null;

  const { introText, onSubmissionText, questionDefinitions, surveydisplayId } =
    data as SurveyDefinition;

  const onSubmit = (selectedAnswers: Record<string, boolean>): void => {
    mutateCreate(
      {
        surveySlug: SURVEY_SLUG,
        data: {
          surveyId: surveydisplayId,
          questionSlug: questionDefinitions[0].questionSlug,
          answersSlug: getAnswersSlug(selectedAnswers),
        },
      },
      {
        onSuccess: () => {
          const disablingAnswer = getDisablingSelectedAnswers(
            questionDefinitions[0].answers,
            selectedAnswers
          );
          const answers = SurveyAnswer.getAnswers(
            questionDefinitions[0].answers,
            disablingAnswer || selectedAnswers
          );
          emitAnalyticsEvent(
            new SurveyAnswer(
              {
                commonId,
                screenReference,
                surveySlug: SURVEY_SLUG,
                questionSlug: questionDefinitions[0].questionSlug,
                answers,
              },
              {
                screenReference,
              }
            )
          );
        },
        onError(): void {
          Logger.error(new Error('Error submitting RoverProtect Survey'));
        },
      }
    );
  };

  return showSurvey ? (
    <BottomSheetSurvey
      showIntroduction={isMobile ?? false}
      introText={introText}
      introButtonText={SURVEY_NEXT_BUTTON_TEXT}
      submitButtonText={SURVEY_SUBMIT_BUTTON_TEXT}
      confirmationText={onSubmissionText}
      questionDefinition={questionDefinitions[0]}
      onSubmit={onSubmit}
    />
  ) : null;
};

export default RoverProtectSurvey;
