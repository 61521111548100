import React from 'react';

import { Box, Button, Flex, Heading, Image, Paragraph } from '@rover/kibble/core';
import Card from '@rover/react-lib/src/components/cards/Card';
import DogPolaroid from '@rover/shared/images/homepage/dog-polaroid.png';
import { HomepageDaycareBannerClick } from '@rover/shared/js/search/utilities/analyticsEvents';
import { storeCrossPageAnalyticsEvent } from '@rover/utilities/fireCrossPageEvent';

const FacilityPilotCard: React.FC = () => {
  const url = `search/?service_type=doggy-day-care&daycare_type=facility&alternate_results=true`;

  return (
    <Card padding="3x" maxWidth="100%" borderColor={['transparent', 'default']}>
      <Flex alignItems="center" maxWidth="100%">
        <Box width={['105px', '115px']}>
          <Image width="100%" height="100%" description="dog in polaroid frame" src={DogPolaroid} />
        </Box>
        <Flex flexDirection={['column', 'row']} width="100%">
          <Flex
            flexDirection="column"
            paddingLeft="2x"
            justifyContent="flex-start"
            alignItems="flex-start"
            maxWidth={['100%', '385px', '460px']}
          >
            <Heading size="300" fontWeight="semibold" my="2x" sx={['300px', '100%']}>
              Doggy day care centers available on Rover!
            </Heading>
            <Paragraph
              textColor="secondary"
              size="200"
              fontWeight="regular"
              display={['none', 'block']}
            >
              Now you can search for and book with local day care facilities offering socialization
              and ample opportunity for your dog to burn energy.
            </Paragraph>
          </Flex>
          <Flex justifyContent={['center', 'end']} py="2x" px="4x" flexGrow="1">
            <Button
              href={url}
              py="2x"
              px="4x"
              fontWeight="semibold"
              onClick={() => {
                storeCrossPageAnalyticsEvent(new HomepageDaycareBannerClick());
              }}
              size="small"
              minWidth="200px"
            >
              Search day care
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};

export default FacilityPilotCard;
