import React from 'react';
import { t, Trans } from '@lingui/macro';
import styled from 'styled-components';

import { Box, Button, Flex, Heading, Image, Link, Text } from '@rover/kibble/core';
import { StarRating } from '@rover/react-lib/src/components';
import { HomepageRebookingProfile } from '@rover/rsdk/src/apiClient/latest';
import { useI18n } from '@rover/rsdk/src/modules/I18n';
import LOCAL_STORAGE_KEYS from '@rover/shared/js/constants/localStorage.constants';
import { ServiceType } from '@rover/shared/js/constants/service';
import fireCrossPageEvent from '@rover/utilities/fireCrossPageEvent';

import useInTrainingPilot from '../../../../training/hooks/useInTrainingPilot';

export type Props = {
  sitters: HomepageRebookingProfile[];
  language?: string;
};

const DateText = styled(Text)`
  font-size: 12px;

  span {
    white-space: nowrap;
    font-size: 12px;
  }
`;

const dateFormatter = (startDateString, endDateString, i18n, language = 'en-us'): JSX.Element => {
  const startDate = new Date(startDateString.replace(/-/g, '/'));
  const endDate = new Date(endDateString.replace(/-/g, '/'));
  const options: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric' };
  if (startDate.getTime() !== endDate.getTime()) {
    return (
      <DateText textColor="placeholder" mb="4x">
        <Trans description="The first variable is the start date and the second one the end date of a booked service">
          Booked <Text>{startDate.toLocaleString(language, options)} -</Text>{' '}
          <Text>{endDate.toLocaleString(language, options)}</Text>
        </Trans>
      </DateText>
    );
  }
  return (
    <DateText textColor="placeholder" mb="4x">
      <Trans description="The variable is the start date and the end date of a booked service">
        Booked{' '}
        <Text size="50" sx={{ whiteSpace: 'nowrap' }}>
          {startDate.toLocaleString(language, options)}
        </Text>
      </Trans>
    </DateText>
  );
};

const RebookingItems = ({ language, sitters = [] }: Props): JSX.Element => {
  const { i18n } = useI18n();

  const { shouldShowTrainingEntrypoints } = useInTrainingPilot();

  return (
    <Flex flexGrow="1" width="100%">
      {sitters.map(
        (
          {
            isRatable,
            name,
            profileUrl,
            imageUrl,
            startDate,
            endDate,
            showRating,
            rating,
            reviewLinkAnchorText,
            reviewUrl,
            contactUrl,
            serviceTypeSlug,
            opk,
            serviceTypeDisplayName,
            trainerRebookUrl,
            trainerProfileUrl,
          },
          index
        ) => (
          <Flex
            mr={index === 0 && sitters.length > 1 ? '4x' : '0x'}
            key={`sitter-rebook-${index}`}
            flexDirection="column"
            justifyContent="space-between"
            width="100%"
            minHeight={312}
          >
            <Flex flexDirection="column" width="100%">
              <Box
                href={
                  serviceTypeSlug === ServiceType.DOG_TRAINING && shouldShowTrainingEntrypoints
                    ? trainerProfileUrl
                    : `${profileUrl}?service_type=${serviceTypeSlug}`
                }
                aria-label={i18n._(t`${name}'s Profile page`)}
                onClick={() =>
                  fireCrossPageEvent(LOCAL_STORAGE_KEYS.HOMEPAGE_REBOOK, {
                    event: 'rebook-provider-profile-click',
                    providerId: opk,
                    rank: (index + 1).toString(),
                  })
                }
                as="a"
                sx={{
                  cursor: 'pointer',
                  '&:hover, &:focus, &:visited': {
                    textDecoration: 'none !important',
                  },
                }}
              >
                <Image
                  src={imageUrl}
                  height={130}
                  width="100%"
                  description={name}
                  mb="3x"
                  borderRadius="primary"
                />
                <Heading textColor="primary">{name}</Heading>
              </Box>
              <Text size="100">{i18n._(serviceTypeDisplayName)}</Text>
              {dateFormatter(startDate, endDate, i18n, language)}
              {showRating && rating !== null ? (
                <>
                  <StarRating rating={rating} />
                  <Text textColor="placeholder" size="50" mb="4x">
                    <Trans>My review</Trans>
                  </Text>
                </>
              ) : (
                <Flex flexGrow="1" mb="6x" alignItems="center">
                  {isRatable && reviewLinkAnchorText && (
                    <Link size="100" href={reviewUrl}>
                      {reviewLinkAnchorText}
                    </Link>
                  )}
                </Flex>
              )}
            </Flex>
            <Button
              href={
                serviceTypeSlug === ServiceType.DOG_TRAINING && shouldShowTrainingEntrypoints
                  ? trainerRebookUrl
                  : `${contactUrl}?service_type=${serviceTypeSlug}`
              }
              fullWidth
              variant="primary"
              size="small"
              onClick={() =>
                fireCrossPageEvent(LOCAL_STORAGE_KEYS.HOMEPAGE_REBOOK, {
                  event: 'rebook-provider-rebook-click',
                  providerId: opk,
                  rank: (index + 1).toString(),
                })
              }
              aria-label={i18n._(t`Rebook ${name}`)}
              py="3x"
            >
              <Text size="200">
                <Trans>Rebook</Trans>
              </Text>
            </Button>
          </Flex>
        )
      )}
    </Flex>
  );
};

export default RebookingItems;
