import { getSourceDescription } from '@rover/rsdk/src/modules/Network/userAgent';
import emitMetric from '@rover/utilities/emitMetric';
import fireCrossPageEvent from '@rover/utilities/fireCrossPageEvent';

const emitMetricOnCtaClick = (metric: string): void => {
  const tags = {
    device: getSourceDescription(),
  };
  fireCrossPageEvent(metric, { ...tags, event: metric });
  emitMetric(metric, tags);
};

export default emitMetricOnCtaClick;
