import React, { FC } from 'react';
import get from 'lodash-es/get';

import { UpdateFiltersFunc } from '@rover/react-lib/src/pages/index/HomePage/components/SearchBox/UpdateFiltersFunc';
import PetCounter from '@rover/react-lib/src/pages/search/SearchPage/components/PetCounter/index';
import SimplifiedMobileOwnerSearchPetSelector from '@rover/shared/js/search/components/SimplifiedMobileOwnerSearchPetSelector';
import getIsCatOnlyPetType from '@rover/shared/js/search/utilities/getIsCatOnlyPetType';
import getServiceType from '@rover/shared/js/search/utilities/getServiceType';
import { Pet, SearchFilters, ServiceType, ServiceTypeSlug } from '@rover/types';
import { HomepageSearchFilters } from '@rover/types/src/HomepageSearchFilters';

export interface NarrowDeviceSearchBoxPetSelectorProps {
  isAuthenticated: boolean;
  pets: Pet[];
  dogCount?: number;
  catCount?: number;
  filters: HomepageSearchFilters;
  serviceType: ServiceType | undefined;
  updateFilters: UpdateFiltersFunc;
}

const NarrowDeviceSearchBoxPetSelector: FC<NarrowDeviceSearchBoxPetSelectorProps> = ({
  isAuthenticated,
  filters,
  serviceType,
  pets,
  dogCount,
  catCount,
  updateFilters,
}) => {
  const handlePetCounterChange = ({
    petType,
    counter: newPetCounter,
    dogCount: newDogCount,
    catCount: newCatCount,
  }): void => {
    const localIsCatOnly = getIsCatOnlyPetType(petType as []);
    const newServiceType = getServiceType(
      localIsCatOnly,
      filters.serviceTypeSlug as ServiceTypeSlug,
      get(serviceType, 'suggestedPetType')
    );

    const priceTransparencyFilters = {
      dogCount: newDogCount,
      catCount: newCatCount,
    };

    const updatedFilters = {
      petType,
      catCare: localIsCatOnly,
      serviceTypeSlug: newServiceType,
      ...priceTransparencyFilters,
    };

    const updatedPetCounterData = {
      counter: newPetCounter,
      dogCount: newDogCount,
      catCount: newCatCount,
    };

    updateFilters(updatedFilters, updatedPetCounterData);
  };

  const handlePetSelectorChange = ({
    pet,
    petType,
  }: Partial<SearchFilters | HomepageSearchFilters>): void => {
    const localIsCatOnly = getIsCatOnlyPetType(petType as []);
    const newServiceType = getServiceType(
      localIsCatOnly,
      filters.serviceTypeSlug as ServiceTypeSlug,
      get(serviceType, 'suggestedPetType')
    );

    updateFilters({
      pet,
      petType,
      catCare: localIsCatOnly,
      serviceTypeSlug: newServiceType,
    });
  };

  return isAuthenticated && pets.length > 0 ? (
    <SimplifiedMobileOwnerSearchPetSelector
      onChange={handlePetSelectorChange}
      petOptions={pets}
      searchFilters={filters}
      hideHelperText
      showErrorMessage
    />
  ) : (
    <PetCounter
      dogCount={dogCount}
      catCount={catCount}
      triggeredFrom="Homepage"
      onChange={handlePetCounterChange}
      hideHelperText
      isHomePage
    />
  );
};

export default NarrowDeviceSearchBoxPetSelector;
