import React, { useEffect } from 'react';

import dataLayer from '@rover/globals/dataLayer';
import { Flex } from '@rover/kibble/core';
import { HomepageRebookingProfile } from '@rover/rsdk/src/apiClient/latest';

import ServiceTypeDescription from '../../ServiceTypeDescription';

import RebookingProfiles from './RebookingProfiles';
import RebookingSearch from './RebookingSearch';

export type Props = {
  sitters: HomepageRebookingProfile[];
  services?: ServiceTypeDescription[];
  language?: string;
};

const Rebooking: React.FC<Props> = ({ language, sitters = [], services = [] }) => {
  useEffect(() => {
    dataLayer.push({
      event: 'rebook-provider-view',
      moduleLocation: 'homepage',
      moduleLayout: 'horizontal',
      providers: sitters.map((s) => s.opk),
    });
  }, [sitters]);

  return sitters.length !== 0 ? (
    <Flex display={['none', 'flex']} justifyContent="center" mt="8x">
      <Flex width={sitters.length === 1 ? 570 : '100%'}>
        <RebookingProfiles language={language} sitters={sitters} />
        <RebookingSearch services={services} />
      </Flex>
    </Flex>
  ) : null;
};

export default Rebooking;
