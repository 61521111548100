import React from 'react';
import { Trans } from '@lingui/macro';

import { Flex, Heading } from '@rover/kibble/core';
import Card from '@rover/react-lib/src/components/cards/Card';
import { HomepageRebookingProfile } from '@rover/rsdk/src/apiClient/latest';

import RebookingItems from './RebookingItems';

export type Props = {
  sitters: HomepageRebookingProfile[];
  language?: string;
};

const RebookingProfiles = ({ language, sitters = [] }: Props): JSX.Element => (
  <Card marginRight="4x">
    <Flex width="100%" flexDirection="column">
      <Heading size="300">
        <Trans>Rebook a previous sitter</Trans>
      </Heading>
      <RebookingItems language={language} sitters={sitters} />
    </Flex>
  </Card>
);

export default RebookingProfiles;
