import React from 'react';
import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import styled from 'styled-components';

import { Display } from '@rover/kibble/core';
import { MQ, Spacing } from '@rover/kibble/styles';
import { Image } from '@rover/react-lib/src/components/images';
import { Alignment } from '@rover/react-lib/src/components/layouts/layout.constants';
import ResponsiveLayout from '@rover/react-lib/src/components/layouts/ResponsiveLayout';
import VerticalLayout from '@rover/react-lib/src/components/layouts/VerticalLayout';
import appShowcaseDaDkSource from '@rover/react-lib/src/images/homepage/localized-app-showcase-images/app-showcase-da-dk.webp';
import appShowcaseDeAtSource from '@rover/react-lib/src/images/homepage/localized-app-showcase-images/app-showcase-de-at.webp';
import appShowcaseDeChSource from '@rover/react-lib/src/images/homepage/localized-app-showcase-images/app-showcase-de-ch.webp';
import appShowcaseDeDeSource from '@rover/react-lib/src/images/homepage/localized-app-showcase-images/app-showcase-de-de.webp';
import appShowcaseEnCaSource from '@rover/react-lib/src/images/homepage/localized-app-showcase-images/app-showcase-en-ca.webp';
import appShowcaseEnGbSource from '@rover/react-lib/src/images/homepage/localized-app-showcase-images/app-showcase-en-gb.webp';
import appShowcaseEnIeSource from '@rover/react-lib/src/images/homepage/localized-app-showcase-images/app-showcase-en-ie.webp';
import appShowcaseEnUsSource from '@rover/react-lib/src/images/homepage/localized-app-showcase-images/app-showcase-en-us.webp';
import appShowcaseEsEsSource from '@rover/react-lib/src/images/homepage/localized-app-showcase-images/app-showcase-es-es.webp';
import appShowcaseFiFiSource from '@rover/react-lib/src/images/homepage/localized-app-showcase-images/app-showcase-fi-fi.webp';
import appShowcaseFrBeSource from '@rover/react-lib/src/images/homepage/localized-app-showcase-images/app-showcase-fr-be.webp';
import appShowcaseFrCaSource from '@rover/react-lib/src/images/homepage/localized-app-showcase-images/app-showcase-fr-ca.webp';
import appShowcaseFrChSource from '@rover/react-lib/src/images/homepage/localized-app-showcase-images/app-showcase-fr-ch.webp';
import appShowcaseFrFrSource from '@rover/react-lib/src/images/homepage/localized-app-showcase-images/app-showcase-fr-fr.webp';
import appShowcaseItChSource from '@rover/react-lib/src/images/homepage/localized-app-showcase-images/app-showcase-it-ch.webp';
import appShowcaseItItSource from '@rover/react-lib/src/images/homepage/localized-app-showcase-images/app-showcase-it-it.webp';
import appShowcaseNlBeSource from '@rover/react-lib/src/images/homepage/localized-app-showcase-images/app-showcase-nl-be.webp';
import appShowcaseNlNlSource from '@rover/react-lib/src/images/homepage/localized-app-showcase-images/app-showcase-nl-nl.webp';
import appShowcaseNoNoSource from '@rover/react-lib/src/images/homepage/localized-app-showcase-images/app-showcase-no-no.webp';
import appShowcasePlPlSource from '@rover/react-lib/src/images/homepage/localized-app-showcase-images/app-showcase-pl-pl.webp';
import appShowcasePtPtSource from '@rover/react-lib/src/images/homepage/localized-app-showcase-images/app-showcase-pt-pt.webp';
import appShowcaseSvSeSource from '@rover/react-lib/src/images/homepage/localized-app-showcase-images/app-showcase-sv-se.webp';
import { useI18n } from '@rover/rsdk/src/modules/I18n';

import AndroidAppStoreButton from './AndroidAppStoreButton';
import IosAppStoreButton from './IosAppStoreButton';

const DEFAULT_APP_SHOWCASE_IMAGE = appShowcaseEnUsSource;

const appShowcaseImageLocaleMap = {
  'da-dk': appShowcaseDaDkSource,
  'de-at': appShowcaseDeAtSource,
  'de-ch': appShowcaseDeChSource,
  'de-de': appShowcaseDeDeSource,
  'en-ca': appShowcaseEnCaSource,
  'en-gb': appShowcaseEnGbSource,
  'en-ie': appShowcaseEnIeSource,
  'en-us': appShowcaseEnUsSource,
  'es-es': appShowcaseEsEsSource,
  'fi-fi': appShowcaseFiFiSource,
  'fr-be': appShowcaseFrBeSource,
  'fr-ca': appShowcaseFrCaSource,
  'fr-ch': appShowcaseFrChSource,
  'fr-fr': appShowcaseFrFrSource,
  'it-ch': appShowcaseItChSource,
  'it-it': appShowcaseItItSource,
  'nl-be': appShowcaseNlBeSource,
  'nl-nl': appShowcaseNlNlSource,
  'nb-no': appShowcaseNoNoSource,
  'pl-pl': appShowcasePlPlSource,
  'pt-pt': appShowcasePtPtSource,
  'sv-se': appShowcaseSvSeSource,
};

const AppShowCaseImage = styled(Image)`
  border-bottom: 3px solid rgb(238, 238, 238);
  // width of the image (file)
  max-width: 394px;
  // we use aspect-ratio instead of using height auto to reduce CLS
  aspect-ratio: 1.36;
`;

const ButtonContainer = styled(ResponsiveLayout)`
  margin-bottom: ${Spacing.M.toString()};
  row-gap: ${Spacing.S.toString()};

  ${MQ.NOT_XS.toString()} {
    column-gap: ${Spacing.XL.toString()};
  }
`;

const AppStoreCallout = (): JSX.Element => {
  const { i18n, locale } = useI18n();
  const appShowcaseImageSrc = appShowcaseImageLocaleMap[locale] || DEFAULT_APP_SHOWCASE_IMAGE;
  return (
    <VerticalLayout vAlign={Alignment.CENTER} hAlign={Alignment.CENTER}>
      <Display as="h3" size="500" responsive={false} textAlign="center" mb="6x">
        <Trans>Connect anywhere with the Rover app</Trans>
      </Display>
      <ButtonContainer hAlign={Alignment.CENTER}>
        <IosAppStoreButton />
        <AndroidAppStoreButton />
      </ButtonContainer>
      <AppShowCaseImage
        src={appShowcaseImageSrc}
        alt={
          /* i18n: Alt text for devices showing the app */
          i18n._(
            t`An iPhone and an Android device each displaying sitter search results from the Rover mobile app.`
          )
        }
        width="100%"
      />
    </VerticalLayout>
  );
};

export default AppStoreCallout;
