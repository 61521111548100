import React, { FC } from 'react';

import { useI18n } from '@rover/rsdk/src/modules/I18n';
import type { HomepageSearchFilters } from '@rover/types/src/HomepageSearchFilters';

import { DaycareTypeFilter } from '../../../../../search/SearchPage/components';
import type { UpdateFiltersFunc } from '../UpdateFiltersFunc';

export interface Props {
  filters: HomepageSearchFilters;
  updateFilters: UpdateFiltersFunc;
  isFilterRequired: boolean;
  hasFilterError: boolean;
}

const DaycareTypeConnector: FC<Props> = ({
  filters,
  updateFilters,
  isFilterRequired,
  hasFilterError,
}: Props) => {
  const { i18n } = useI18n();

  return (
    <DaycareTypeFilter
      onChange={(newInSittersHome, newAtDaycareFacility) => {
        updateFilters({
          inSittersHome: newInSittersHome,
          atDaycareFacility: newAtDaycareFacility,
        });
      }}
      inSittersHome={isFilterRequired ? filters.inSittersHome : true}
      atDaycareFacility={isFilterRequired ? filters.atDaycareFacility : true}
      layoutVariant="fully-inline"
      title={
        isFilterRequired
          ? i18n._('Show day care options (required):')
          : i18n._('Show day care options:')
      }
      required={!!isFilterRequired}
      error={hasFilterError}
      placement="homepage-search"
    />
  );
};

export default DaycareTypeConnector;
