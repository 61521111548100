import React, { FC } from 'react';

import { Box, Flex } from '@rover/kibble/core';
import type { TrustPilotProps } from '@rover/react-lib/src/components/Trustpilot';
import Trustpilot from '@rover/react-lib/src/components/Trustpilot';
import NarrowDeviceSearchBoxContainer from '@rover/react-lib/src/pages/index/HomePage/components/IntuitiveOwnerWizard/NarrowDeviceSearchBoxContainer';
import type { ServiceLinksByCountry } from '@rover/react-lib/src/redux/ducks/serviceLinks.duck';

import FacilityPilotCard from '../../../../home/FacilityPilotCard';

export type Props = TrustPilotProps & {
  isAuthenticated: boolean;
  serviceLinksByCountry: ServiceLinksByCountry | null;
  shouldShowTrustpilotBadgeStatic?: boolean;
  countryCode?: string;
  isInGingrFacilitiesInSearchExperiment: boolean;
};

const NarrowDeviceSearchBoxWrapper: FC<Props> = ({
  serviceLinksByCountry,
  shouldShowTrustpilotBadgeStatic,
  languageCode,
  countryCode,
  isAuthenticated,
  isInGingrFacilitiesInSearchExperiment,
}) => {
  const showTrustPilot = !isAuthenticated && shouldShowTrustpilotBadgeStatic;

  return (
    <Flex
      display={['flex', 'none']}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      background="secondary"
      paddingY="6x"
    >
      <Box width="100%" pb={!showTrustPilot && isInGingrFacilitiesInSearchExperiment ? '6x' : '0x'}>
        <NarrowDeviceSearchBoxContainer
          serviceLinksByCountry={serviceLinksByCountry}
          countryCode={countryCode}
          isAuthenticated={isAuthenticated}
        />
      </Box>
      {showTrustPilot && <Trustpilot languageCode={languageCode} />}
      {isInGingrFacilitiesInSearchExperiment && <FacilityPilotCard />}
    </Flex>
  );
};

export default NarrowDeviceSearchBoxWrapper;
