import { getAxiosInstance } from '@rover/rsdk/src/modules/Network/axios';
import { camelize, snakeify } from '@rover/rsdk/src/modules/Network/object-utilities';
import { fullApiUrl } from '@rover/utilities/apiUrl';

import DefaultAPIErrorHandler from './DefaultAPIErrorHandler';

export const MARKETING_OPT_IN_URL = fullApiUrl('blocking-modal/marketing-opt-in/');

export const post = async (turnNotificationsOn?: boolean): Promise<void> => {
  const ai = getAxiosInstance();
  try {
    const data = snakeify({ turnNotificationsOn });
    await ai.post(MARKETING_OPT_IN_URL, data);
  } catch (error: any) {
    throw DefaultAPIErrorHandler(error, 'There was an error saving your intent');
  }
};

export const get = async (): Promise<{ showModal: boolean }> => {
  const ai = getAxiosInstance();
  try {
    const { data } = await ai.get(MARKETING_OPT_IN_URL);
    return camelize(data, true);
  } catch (error: any) {
    throw DefaultAPIErrorHandler(
      error,
      'There was an error fetching if should show marketing opt in modal'
    );
  }
};
