import React from 'react';
import { Trans } from '@lingui/react';
import styled from 'styled-components';

import { DSTokenMap, MQ, Spacing } from '@rover/kibble/styles';
import { PageLayout, VerticalLayout } from '@rover/react-lib/src/components/layouts';
import type { CountryRegionLink } from '@rover/types';

const NUM_COLUMNS = 6;

export type Props = {
  countryRegionLinks: CountryRegionLink[];
  countryName: string;
  countryFlag: string;
};

const CountryBackgroundWrapper = styled.div`
  background: ${DSTokenMap.BACKGROUND_COLOR_CONTRAST.toString()};
`;

const CountryWrapper = styled(PageLayout)`
  margin: 0 auto;
  padding-top: ${Spacing.S.toString()};
`;

const StyledCountryLayout = styled(VerticalLayout)`
  align-items: normal;
  margin-top: ${Spacing.S.toString()};
  color: ${DSTokenMap.BACKGROUND_COLOR_SECONDARY.toString()};
  h3 {
    font-size: 26px;
    font-weight: 700;
    line-height: 1.4;
    text-align: left;

    ${MQ.SM_DOWN.toString()} {
      font-size: 22px;
    }
  }

  a:link,
  a:visited,
  a:hover,
  a:active {
    color: ${DSTokenMap.BACKGROUND_COLOR_SECONDARY.toString()};
    text-decoration: none;
  }

  ul {
    list-style-type: none;
    margin: 0;
  }
`;

const StyledFlexContainerDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
`;

export const StyledCountryHeader = styled.h3`
  text-align: center;
  padding-bottom: 10px;
`;

export const StyledCountryList = styled.ul`
  column-count: ${NUM_COLUMNS};
  column-gap: ${Spacing.L.toString()};
  list-style-type: none;
  width: 100%;
  padding-left: 0px;

  ${MQ.SM_DOWN.toString()} {
    column-count: ${NUM_COLUMNS / 2};
  }

  ${MQ.XS_DOWN.toString()} {
    column-count: ${NUM_COLUMNS / 3};
  }
`;

const StyledCountryListItem = styled.li<{ separator: boolean }>`
  margin-bottom: ${Spacing.XS.toString()};
  padding-bottom: 0;
  a:hover {
    text-decoration: underline;
  }
`;

const CountriesLinks = ({ countryRegionLinks, countryName, countryFlag }: Props): JSX.Element => (
  <CountryBackgroundWrapper>
    <CountryWrapper>
      <StyledCountryLayout>
        <StyledCountryHeader>
          <Trans>{`Thousands of pet sitters across ${countryName}`}</Trans>
          <span aria-hidden="true"> {countryFlag}</span>
        </StyledCountryHeader>
        <StyledFlexContainerDiv>
          <StyledCountryList role="list">
            {countryRegionLinks &&
              countryRegionLinks.map(({ name, url }: CountryRegionLink, index: number) => {
                const columnMarker = Math.round(countryRegionLinks.length / NUM_COLUMNS);

                return (
                  <StyledCountryListItem
                    key={name}
                    separator={index % columnMarker === 0}
                    role="listitem"
                  >
                    <a key={url} href={url}>
                      {name}
                    </a>
                  </StyledCountryListItem>
                );
              })}
          </StyledCountryList>
        </StyledFlexContainerDiv>
      </StyledCountryLayout>
    </CountryWrapper>
  </CountryBackgroundWrapper>
);

export default CountriesLinks;
