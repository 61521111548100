import React from 'react';

import type {
  CountryFooterLink,
  FetchCountryRegionLinksResponse,
} from '@rover/types';

import CountriesLinks from './CountriesLinks';
import CountryRegionLinks from './CountryRegionLinks';

export type FooterProps = {
  countryRegionLinks: FetchCountryRegionLinksResponse;
  countryFooterLinks: CountryFooterLink[];
};

const Footer = ({ countryRegionLinks, countryFooterLinks }: FooterProps): JSX.Element => (
  <aside>
    {countryRegionLinks && (
      <CountryRegionLinks
        countryRegionLinks={countryRegionLinks.results}
        countryName={countryRegionLinks.countryName}
        countryFlag={countryRegionLinks.countryFlag}
      />
    )}
    {countryFooterLinks && <CountriesLinks countryFooterLinks={countryFooterLinks} />}
  </aside>
);

export default Footer;
