import React from 'react';

function CurvedMask(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1425 38" data-testid="styled-svg">
      <path
        fill="#FFF"
        fillRule="evenodd"
        shapeRendering="geometricPrecision"
        d="M1425,721 L1425,687.990681 C954.094828,663.233692 475.081897,740.974764 0,696.243011 L0,721 L1425,721 Z"
        transform="translate(0 -683)"
      />
    </svg>
  );
}
export default CurvedMask;
