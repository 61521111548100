import React, { FC } from 'react';
import { t } from '@lingui/macro';
import styled from 'styled-components';

import { DSTokenMap } from '@rover/kibble/styles';
import {
  SELECT_BUTTON_IS_SELECTED_CLASS,
  SelectButtonLabel,
} from '@rover/react-lib/src/components/buttons/SelectButton/SelectButton';
import { StyledSelectButton } from '@rover/react-lib/src/components/formFields/SingleSelectButtonGroup';
import type { HomepageSearchFilters } from '@rover/types/src/HomepageSearchFilters';
import { ServiceFrequencyEnum } from '@rover/types/src/ServiceFrequency';
import { useI18n } from '@rover/rsdk/src/modules/I18n';
import OneTimeRepeatToggleButtons, {
  StyledButtonGroup,
} from '@rover/shared/js/search/components/OneTimeRepeatToggleButtons';

import type { UpdateFiltersFunc } from '../UpdateFiltersFunc';

const StyledOneTimeRepeatToggleButtons = styled(OneTimeRepeatToggleButtons)`
  ${StyledButtonGroup} {
    margin-bottom: 15px;

    ${StyledSelectButton} {
      padding: ${DSTokenMap.SPACE_2X};
    }
  }

  svg {
    fill: ${DSTokenMap.TEXT_COLOR_TERTIARY.toString()};
    height: 20px;
    width: 20px;
    min-width: unset;
    max-width: unset;
    min-height: unset;
    max-height: unset;
    margin-top: 2px;
  }

  ${SelectButtonLabel} {
    &:hover {
      svg {
        fill: ${DSTokenMap.TEXT_COLOR_SECONDARY.toString()};
      }
    }
    &.${SELECT_BUTTON_IS_SELECTED_CLASS} {
      color: ${DSTokenMap.TEXT_COLOR_PRIMARY.toString()};
      svg {
        fill: ${DSTokenMap.TEXT_COLOR_PRIMARY.toString()};
      }
    }
  }
`;
export interface Props {
  filters: HomepageSearchFilters;
  updateFilters: UpdateFiltersFunc;
}

const OneTimeRepeatToggleConnector: FC<Props> = ({ filters, updateFilters }: Props) => {
  const { i18n } = useI18n();

  return (
    <StyledOneTimeRepeatToggleButtons
      displayAs="compact"
      frequency={filters.frequency}
      label={i18n._(t`How often do you need this service?`)}
      onChange={(frequency?: ServiceFrequencyEnum) => updateFilters({ frequency })}
    />
  );
};

export default OneTimeRepeatToggleConnector;
