import type { ReactNode } from 'react';
import React from 'react';
import styled, { css } from 'styled-components';

import { Flex, Heading } from '@rover/kibble/core';
import type { Props as DisplayProps } from '@rover/kibble/core/Display';
import Display from '@rover/kibble/core/Display';
import type { Props as HeadingProps } from '@rover/kibble/core/Heading';
import { DSTokenMap, MQ, Spacing } from '@rover/kibble/styles';
import Trustpilot from '@rover/react-lib/src/components/Trustpilot/Trustpilot';
import Crossfader from '@rover/react-lib/src/components/utils/Crossfader';

import CurvedMask from './CurvedMask';

type WrapperProps = {
  desktopHeight: number;
  tabletHeight: number;
  mobileHeight: number;
};
export const Wrapper = styled.div<WrapperProps>`
  position: relative;
  overflow: hidden;

  height: ${(props) => props.mobileHeight}px;
  width: 100%;

  ${MQ.NOT_XS.toString()} {
    height: ${(props) => props.tabletHeight}px;
  }

  ${MQ.MD_UP.toString()} {
    height: ${(props) => props.desktopHeight}px;
  }
`;

type BackgroundImageProps = {
  imageSrc: string;
  mobileImageSrc: string;
  tabletImageSrc?: string;
};

export const BackgroundImage = styled.div<BackgroundImageProps>`
  width: 100%;
  height: 100%;

  background: url(${(props) => props.imageSrc});
  ${MQ.XS.toString()} {
    background: url(${(props) => props.mobileImageSrc});
    background-size: cover;
  }
  ${MQ.SM.toString()} {
    background: url(${(props) => props.tabletImageSrc || props.imageSrc});
    background-size: cover;
  }

  background-size: cover;
  background-position: center;
`;

const BackgroundImageCrossfader = styled(Crossfader)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

type BackgroundImageOverlayProps = {
  flatOverlay?: boolean;
  gradientOverlay?: boolean;
};

export const BackgroundImageOverlay = styled.div<BackgroundImageOverlayProps>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  ${(props) =>
    !props.flatOverlay
      ? ''
      : css`
          background: rgba(0, 0, 0, 0.5);
        `};
  ${(props) =>
    !props.gradientOverlay
      ? ''
      : css`
          background: rgba(0, 0, 0, 0.5);

          ${MQ.NOT_XS.toString()} {
            background: linear-gradient(90deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
          }

          ${MQ.MD_UP.toString()} {
            background: linear-gradient(
              90deg,
              rgba(0, 0, 0, 0.65) 0%,
              rgba(0, 0, 0, 0) 50%,
              rgba(0, 0, 0, 0) 100%
            );
          }
        `};
`;

type ContentProps = {
  headlineTextShadow?: boolean;
};

export const Content = styled.div<ContentProps>`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 ${Spacing.M.toString()};

  ${MQ.NOT_XS.toString()} {
    width: 50%;
    padding: 0;
  }

  ${(props) =>
    !props.headlineTextShadow
      ? ''
      : css`
          text-shadow: 0 1px 5px rgba(0, 0, 0, 0.6);
        `};
`;

const Mask = styled(CurvedMask)`
  position: absolute;

  /* prevents occasional 1 px of image showing on bottom */
  bottom: -1px;

  /* prevents occasional 1-2px of image showing on sides */
  left: -2px;
  width: calc(100% + 4px);
`;

const heroText = css`
  flex: 0;
  margin-bottom: ${Spacing.L.toString()};
  color: ${DSTokenMap.TEXT_COLOR_PRIMARY_INVERSE.toString()};
`;

export const HeroDisplay = styled(Display)<DisplayProps>`
  flex: 1 !important;
  min-height: 90px;

  ${MQ.SM_UP.toString()} {
    max-width: 480px !important;
  }

  ${heroText}
`;

export const HeroSubHeading = styled(Heading)<HeadingProps>`
  ${heroText}
`;

export type Props = {
  cta?: ReactNode;
  headlineText: string;
  headlineDataId?: string;
  subHeadlineText?: string;
  subHeadlineDataId?: string;
  imageSrc: string;
  mobileImageSrc?: string;
  tabletImageSrc?: string;
  flatOverlay?: boolean;
  gradientOverlay?: boolean;
  mobileHeight?: number;
  tabletHeight?: number;
  desktopHeight?: number;
  curvedBottom?: boolean;
  headlineTextShadow?: boolean;
  transitionTime?: number;
  children?: ReactNode;
  shouldShowTrustpilotBadgeStatic?: boolean;
  languageCode?: string;
};

function Hero({
  cta,
  headlineText,
  headlineDataId,
  subHeadlineText,
  subHeadlineDataId,
  imageSrc,
  mobileImageSrc,
  tabletImageSrc,
  flatOverlay = false,
  gradientOverlay = false,
  tabletHeight = 540,
  desktopHeight = 720,
  curvedBottom = false,
  headlineTextShadow = false,
  transitionTime,
  children,
  shouldShowTrustpilotBadgeStatic,
  languageCode,
  mobileHeight = languageCode === 'fr-fr' || languageCode === 'de-de' ? 460 : 360,
  ...other
}: Props): JSX.Element {
  const heroProps = {
    ...other,
    desktopHeight,
    tabletHeight,
    mobileHeight,
  };

  const gradientProps = {
    flatOverlay,
    gradientOverlay,
  };

  const contentProps = {
    headlineTextShadow,
  };

  const MaskComponent = curvedBottom ? <Mask /> : null;
  const mobileSrc = mobileImageSrc || imageSrc;

  const content = (
    <Content {...contentProps} data-testid="styled-content">
      {children}

      <Crossfader transitionTime={transitionTime} data-testid="crossfader">
        {headlineText && (
          <Flex justifyContent="center">
            <HeroDisplay forwardedAs="h1" size="500" data-qa-id={headlineDataId}>
              {headlineText}
            </HeroDisplay>
          </Flex>
        )}
        {subHeadlineText && (
          <HeroSubHeading forwardedAs="h2" size="300" data-qa-id={subHeadlineDataId}>
            {subHeadlineText}
          </HeroSubHeading>
        )}
      </Crossfader>
      {cta}

      {shouldShowTrustpilotBadgeStatic && (
        <Trustpilot languageCode={languageCode} inverse hideOnMobileView />
      )}
    </Content>
  );

  return (
    <Wrapper {...heroProps} data-testid="styled-wrapper">
      <BackgroundImageCrossfader transitionTime={transitionTime} data-testid="styled-crossfader">
        <BackgroundImage
          imageSrc={imageSrc}
          mobileImageSrc={mobileSrc}
          tabletImageSrc={tabletImageSrc}
          data-testid="styled-img"
        />
      </BackgroundImageCrossfader>

      <BackgroundImageOverlay {...gradientProps} />

      <Flex justifyContent="center" alignItems="center" paddingX="4x" height="100%">
        {content}
      </Flex>

      {MaskComponent}
    </Wrapper>
  );
}

export default Hero;
