export const getTrustpilotUrl = (languageCode: string | undefined): URL => {
  if (!languageCode) return new URL('https://trustpilot.com/review/rover.com?languages=all');
  switch (languageCode) {
    case 'fr-ca':
      return new URL('https://fr.trustpilot.com/review/rover.com?languages=all');
    case 'en-gb':
      return new URL('https://uk.trustpilot.com/review/rover.com?languages=all');
    default:
      return new URL(
        `https://${languageCode?.split('-')[1]}.trustpilot.com/review/rover.com?languages=all`
      );
  }
};

export default getTrustpilotUrl;
