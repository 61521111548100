import React, { FC } from 'react';

import { Connect } from '@rover/react-lib/src/components/utils/Redux';
import NarrowDeviceSearchBox from '@rover/react-lib/src/pages/index/HomePage/components/IntuitiveOwnerWizard/NarrowDeviceSearchBox';
import {
  actions as searchBoxActions,
  effects as searchBoxEffects,
  selectors as searchBoxSelectors,
} from '@rover/react-lib/src/pages/index/HomePage/components/SearchBox/SearchBox.duck';
import { selectors as searchPageSelectors } from '@rover/react-lib/src/pages/search/SearchPage/SearchPage.duck';
import { selectors as countryConfigSelectors } from '@rover/react-lib/src/redux/ducks/countryConfiguration.duck';
import { selectors as currentUserSelectors } from '@rover/react-lib/src/redux/ducks/currentUser.duck';
import { selectors as petsSelectors } from '@rover/react-lib/src/redux/ducks/pets.duck';
import { ServiceLinksByCountry } from '@rover/react-lib/src/redux/ducks/serviceLinks.duck';

export interface NarrowDeviceSearchBoxContainerProps {
  isAuthenticated: boolean;
  serviceLinksByCountry: ServiceLinksByCountry | null;
  countryCode?: string;
}

const actions = {
  updateFilters: searchBoxActions.updateFilters,
};

const selector = (state) => ({
  pets: petsSelectors.getPets(state),
  filters: searchBoxSelectors.getFilters(state),
  serviceType: searchBoxSelectors.getServiceType(state),
  serviceTypeOptions: searchBoxSelectors.getPetTypeServiceTypeOptions(state),
  countryHasDogTraining: countryConfigSelectors.getNavigationHasDogTraining(state),
  currentUserPostalCode: currentUserSelectors.getPostalCode(state),
  rolloutPriceTransparency: searchPageSelectors.getRolloutPriceTransparency(state),
  isDaycareFilterEarlierExperiment: searchBoxSelectors.getIsDaycareFilterEarlierInExperiment(state),
  isDaycareFilterEarlierExperimentRequired:
    searchBoxSelectors.getIsDaycareFilterEarlierExperimentRequired(state),
  isDaycareFilterOutsideMarketsInExperiment:
    searchBoxSelectors.getIsDaycareFilterOutsideMarketsInExperiment(state),
  isBoardingFilterOutsideMarketsInExperiment:
    searchBoxSelectors.getIsBoardingFilterOutsideMarketsInExperiment(state),
  hasFilterError: searchBoxSelectors.getHasFilterError(state),
});

const effects = {
  submit: searchBoxEffects.submit,
};

const NarrowDeviceSearchBoxContainer: FC<NarrowDeviceSearchBoxContainerProps> = (props) => {
  return (
    <Connect actions={actions} selector={selector} effects={effects}>
      {(connectedProps) => <NarrowDeviceSearchBox {...connectedProps} {...props} />}
    </Connect>
  );
};

export default NarrowDeviceSearchBoxContainer;
