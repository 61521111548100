import { DSTokenMap } from '@rover/kibble/styles';

export const iconStyles = {
  fill: DSTokenMap.TEXT_COLOR_SUCCESS.toString(),
  width: '24px',
  height: '24px',
};
export const desktopInputStyle = {
  fontSize: DSTokenMap.TEXT_200_FONT_SIZE.toString(),
  cursor: 'pointer',
};
export const mobileInputStyle = {
  ...desktopInputStyle,
  fontSize: DSTokenMap.TEXT_100_FONT_SIZE.toString(),
};
