import React from 'react';

import { Button, Flex, Text } from '@rover/kibble/core';

export type Props = {
  text: string;
  buttonText: string;
  onButtonClick: () => void;
};

const BottomSheetSurveyIntro = ({ text, buttonText, onButtonClick }: Props): JSX.Element => (
  <Flex flexDirection="column">
    <Text size="200" textColor="primary">
      {text}
    </Text>
    <Button variant="primary" size="small" mt="4x" fullWidth onClick={onButtonClick}>
      {buttonText}
    </Button>
  </Flex>
);

export default BottomSheetSurveyIntro;
