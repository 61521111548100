import React from 'react';
import { MessageDescriptor } from '@lingui/core/i18n';
import styled from 'styled-components';

import { Color, DSTokenMap, MQ, Spacing } from '@rover/kibble/styles';
import Hero, {
  BackgroundImage,
  Content,
  HeroDisplay,
  HeroSubHeading,
  Props as HeroProps,
} from '@rover/react-lib/src/components/heroes/Hero';
import Connect from '@rover/react-lib/src/components/utils/Redux/Connect';
import roverHomepageHeroDesktop from '@rover/react-lib/src/images/homepage/rover_homepage_hero_desktop.webp';
import roverHomepageHeroTablet from '@rover/react-lib/src/images/homepage/rover_homepage_hero_tablet.webp';
import { selectors as searchPageSelectors } from '@rover/react-lib/src/pages/search/SearchPage/SearchPage.duck';
import { selectors as countryConfigSelectors } from '@rover/react-lib/src/redux/ducks/countryConfiguration.duck';
import { selectors as currentUserSelectors } from '@rover/react-lib/src/redux/ducks/currentUser.duck';
import { selectors as frontendConfigurationSelectors } from '@rover/react-lib/src/redux/ducks/frontendConfiguration.duck';
import { selectors as petsSelectors } from '@rover/react-lib/src/redux/ducks/pets.duck';
import {
  selectors as serviceLinksSelectors,
  ServiceLinksByCountry,
} from '@rover/react-lib/src/redux/ducks/serviceLinks.duck';
import { HomepageRebookingProfile } from '@rover/rsdk/src/apiClient/latest';
import { useI18n } from '@rover/rsdk/src/modules/I18n';

import { selectors as homePageSelectors } from '../HomePage.duck';
import ServiceTypeDescription from '../ServiceTypeDescription';

import NarrowDeviceSearchBoxWrapper from './IntuitiveOwnerWizard/NarrowDeviceSearchBoxWrapper';
import RebookingOrSearchBox from './RebookingOrSearchBox';

type Props = {
  isAuthenticated: boolean;
  headlineText: MessageDescriptor;
  subHeadlineText: MessageDescriptor;
  shouldShowTrustpilotBadgeStatic: boolean;
  languageCode: string;
  countryCode: string;
  countryHasDogTraining: boolean;
  isInGingrFacilitiesInSearchExperiment: boolean;
  rebookingProfiles: HomepageRebookingProfile[];
  serviceTypeDescriptions: ServiceTypeDescription[];
  serviceLinksByCountry: ServiceLinksByCountry | null;
};

interface StyledHeroProps extends HeroProps {
  isAuthenticated: boolean;
}

const StyledHero = styled(Hero)<StyledHeroProps>`
  overflow: visible;
  label {
    display: inline-flex;
  }

  // We need to override the height delcarations in the imported Hero
  // with min-height so that the hero can grow to fit the content
  ${MQ.SM_UP.toString()} {
    min-height: ${(props) => props.tabletHeight}px;
    height: auto;
  }

  ${MQ.MD_UP.toString()} {
    min-height: ${(props) => props.desktopHeight}px;
  }
  // --

  ${BackgroundImage} {
    background-position: 'right top';
    background-color: ${Color.NEUTRAL_600.toString()};
  }

  ${Content} {
    padding: 96px 0 20px;
    width: 100%;
    text-align: left;

    ${HeroDisplay} {
      margin-bottom: 0;
      text-align: center;
      max-width: 750px;
      // TODO: To match the django layout for our experiment, we are overriding the Kibble line-height of 1.25. This should be revisited post-experiment
      line-height: 1.4;
      text-shadow: 0 1px 5px rgba(0, 0, 0, 0.6);
    }

    ${HeroSubHeading} {
      text-align: center;
    }
  }

  ${HeroSubHeading} {
    margin: 0 auto ${Spacing.L.toString()};
    font-size: 32px;
    width: 704px;
    text-shadow: 0 1px 5px rgba(0, 0, 0, 0.6);
  }

  ${MQ.XS_DOWN.toString()} {
    ${Content} {
      width: 100%;
      padding: 0;

      ${HeroDisplay} {
        font-size: ${(props) =>
          props.subHeadlineText
            ? DSTokenMap.DISPLAY_400_FONT_SIZE
            : DSTokenMap.DISPLAY_500_FONT_SIZE};
        margin-top: 0;
        text-shadow: none;
      }
      ${HeroSubHeading} {
        font-size: ${DSTokenMap.HEADING_200_FONT_SIZE.toString()};
        margin-bottom: 0;
        width: 100%;
        padding-top: ${DSTokenMap.SPACE_4X.toString()};
        text-shadow: none;
      }
    }
  }
`;

const HomePageHero = ({
  isAuthenticated,
  headlineText,
  subHeadlineText,
  shouldShowTrustpilotBadgeStatic,
  languageCode,
  countryCode,
  countryHasDogTraining,
  isInGingrFacilitiesInSearchExperiment,
  rebookingProfiles,
  serviceTypeDescriptions,
  serviceLinksByCountry,
}: Props): JSX.Element => {
  const { i18n } = useI18n();

  const mobileHeight = 200;

  return (
    <>
      <StyledHero
        isAuthenticated={isAuthenticated}
        cta={
          <RebookingOrSearchBox
            hasDogTraining={countryHasDogTraining}
            isInGingrFacilitiesInSearchExperiment={isInGingrFacilitiesInSearchExperiment}
            rebookingProfiles={rebookingProfiles}
            serviceTypeDescriptions={serviceTypeDescriptions}
            language={languageCode}
            serviceLinksByCountry={serviceLinksByCountry}
            countryCode={countryCode}
          />
        }
        desktopHeight={723} // 774 - 51
        flatOverlay={false}
        headlineText={i18n._(headlineText)}
        headlineTextShadow={false} // this must be false otherwise it will add a text-shadow to the whole RebookingOrSearchBox component :(
        imageSrc={roverHomepageHeroDesktop}
        mobileHeight={mobileHeight}
        mobileImageSrc={roverHomepageHeroTablet}
        tabletImageSrc={roverHomepageHeroTablet}
        subHeadlineText={i18n._(subHeadlineText)}
        tabletHeight={716} // 767 - 51
        shouldShowTrustpilotBadgeStatic={shouldShowTrustpilotBadgeStatic}
        languageCode={languageCode}
      />
      <NarrowDeviceSearchBoxWrapper
        serviceLinksByCountry={serviceLinksByCountry}
        shouldShowTrustpilotBadgeStatic={!!shouldShowTrustpilotBadgeStatic}
        languageCode={languageCode}
        countryCode={countryCode}
        isAuthenticated={isAuthenticated}
        isInGingrFacilitiesInSearchExperiment={isInGingrFacilitiesInSearchExperiment}
      />
    </>
  );
};

const selector = (state) => ({
  isAuthenticated: currentUserSelectors.isAuthenticated(state),
  headlineText: homePageSelectors.getHeadlineText(state),
  subHeadlineText: homePageSelectors.getSubheadlineText(state),
  shouldShowTrustpilotBadgeStatic: homePageSelectors.getShouldShowTrustpilotBadgeStatic(state),
  countryCode: frontendConfigurationSelectors.getSettings(state).countryCode,
  languageCode: frontendConfigurationSelectors.getSettings(state).language,
  countryHasDogTraining: countryConfigSelectors.getNavigationHasDogTraining(state),
  isInGingrFacilitiesInSearchExperiment:
    searchPageSelectors.getIsGingrFacilitiesInSearchExperiment(state),
  rebookingProfiles: homePageSelectors.getRebookingProfiles(state),
  serviceTypeDescriptions: homePageSelectors.getPetTypeBrowsableServiceTypeOptions(state),
  pets: petsSelectors.getPets(state),
  serviceLinksByCountry: serviceLinksSelectors.getServiceLinksByCountry(state),
});

const HeroContainer = (): JSX.Element => (
  <Connect selector={selector}>{(connectedProps) => <HomePageHero {...connectedProps} />}</Connect>
);

export default HeroContainer;
