import React, { FC, useEffect } from 'react';

import Box from '@rover/kibble/core/Box';
import { useI18n } from '@rover/rsdk/src/modules/I18n';
import PetTypeFilter from '@rover/shared/js/search/components/PetTypeFilter';
import getIsCatOnlyPetType from '@rover/shared/js/search/utilities/getIsCatOnlyPetType';
import type { HomepageSearchFilters } from '@rover/types/src/HomepageSearchFilters';
import { PetType } from '@rover/types/src/Pet';
import { SelectOption } from '@rover/types/src/SelectOption';
import { ServiceType, ServiceTypeSlug } from '@rover/types/src/ServiceType';

import type { UpdateFiltersFunc } from '../UpdateFiltersFunc';

export interface Props {
  filters: HomepageSearchFilters;
  updateFilters: UpdateFiltersFunc;
  serviceType: ServiceType | undefined;
  serviceTypeOptions: SelectOption[];
}

const shouldUseDropIn = (petTypes: PetType[]): boolean => {
  return getIsCatOnlyPetType(petTypes);
};

export const prepareArgsForHandlePetChanges = (
  petTypes: PetType[]
): { petType: PetType[]; serviceTypeSlug?: string } => {
  if (shouldUseDropIn(petTypes)) {
    return {
      petType: petTypes,
      serviceTypeSlug: ServiceTypeSlug.DROP_IN,
    };
  }
  return {
    petType: petTypes,
  };
};

const PetTypeConnector: FC<Props> = (props: Props) => {
  const { i18n } = useI18n();
  const { filters, updateFilters, serviceType, serviceTypeOptions } = props;

  const areServicesLoaded = serviceTypeOptions?.length > 0;

  const handlePetTypesChange = (petTypes: PetType[]): void => {
    const preparedArgs = prepareArgsForHandlePetChanges(petTypes);
    return updateFilters({ ...preparedArgs });
  };

  const shouldSetDefaultServiceType =
    serviceType && !serviceTypeOptions.map((service) => service.value).includes(serviceType.slug);

  const setDefaultServiceType = (): void => {
    updateFilters({
      serviceTypeSlug: ServiceTypeSlug.OVERNIGHT_BOARDING,
    });
  };

  useEffect(() => {
    if (shouldSetDefaultServiceType) {
      setDefaultServiceType();
    }
  });

  return (
    <Box
      background="secondary"
      borderRadius="secondary"
      borderBottomLeftRadius="0"
      borderBottomRightRadius="0"
      pt="4x"
      pb="2x"
      pl="6x"
      pr="6x"
      minHeight={areServicesLoaded ? undefined : '54px'}
    >
      {areServicesLoaded && (
        <PetTypeFilter
          label={i18n._("I'm looking for service for my:")}
          layout="row"
          searchFilters={filters}
          onChange={(petTypes: PetType[]) => handlePetTypesChange(petTypes as PetType[])}
        />
      )}
    </Box>
  );
};

export default PetTypeConnector;
