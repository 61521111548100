import { FLAGS } from '@rover/rsdk/src/modules/Data/Core/FrontendConfiguration';
import { COUNTRY_CODE } from '@rover/shared/js/constants/geo.constants';
import {
  CountryUrlPrefix,
  getCountryUrlPrefix,
  US_COUNTRY_URL_PREFIX,
} from '@rover/shared/js/constants/roverGeo.constants';
import useCountryConfigurationShim from '@rover/shared/js/hooks/useCountryConfigurationShim';
import useFrontendConfigurationShim from '@rover/shared/js/hooks/useFrontendConfigurationShim';
import { getURL } from '@rover/shared/js/utils/getFullUrl';
import { redirect } from '@rover/utilities/url';

type RedirectToCountrySpecificPageOptions = {
  getNewPath(path: string, nextCountryUrlPrefix: string): string;
  canRedirect?(requestCountryUrlPrefix: CountryUrlPrefix | null): boolean;
};

/**
 * When a user in a non-US country (determined by current country config)
 * views a US-specific page, this will redirect them to the corresponding
 * page for their own country, if extant.
 * @param getNewPath given the current path and the desired country url
 *                   prefix, returns the desired path
 * @param canRedirect (optional) given the desired country url prefix,
 *                    returns false if the redirect should not happen,
 *                    and true otherwise. Defaults to true.
 */
export default function useRedirectToCorrectCountryPage({
  getNewPath,
  canRedirect = () => true,
}: RedirectToCountrySpecificPageOptions): void {
  const { current: currentCountryConfig } = useCountryConfigurationShim();
  const { isFlagActive } = useFrontendConfigurationShim();

  const requestUrl = getURL();

  // satisfy TS, since we know this will exist (assuming it's prefetched)
  if (!currentCountryConfig) return;

  // country for the page we're on, e.g. rover.com/ca/ => 'ca'
  const requestCountryUrlPrefix = getCountryUrlPrefix(requestUrl.pathname);
  // country for the page we should be on, e.g. user located in UK => 'uk'
  const userCountryUrlPrefix = currentCountryConfig.urlPrefix;

  // only redirect on the server
  if (!process.env.JS_ENV_SERVER) return;

  // only redirect when this feature is enabled
  if (!isFlagActive(FLAGS.FEATURE_REDIRECT_USERS_TO_CORRECT_COUNTRY_PAGES)) return;

  // don't redirect if the `prevent_country_redirect` query param is set
  if (requestUrl.searchParams.has('prevent_country_redirect')) return;

  if (!canRedirect(requestCountryUrlPrefix)) return;

  // Don't ever redirect US users
  if (currentCountryConfig.countryCode === COUNTRY_CODE.US) return;

  // redirect only *from* US pages (to other countries')
  if (
    ![
      US_COUNTRY_URL_PREFIX, // `/us/*`
      null, // no explicit country url prefix
    ].includes(requestCountryUrlPrefix)
  ) {
    return;
  }

  // clone the full URL to ensure that query params and hash are maintained
  const redirectUrl = new URL(requestUrl);
  redirectUrl.pathname = getNewPath(requestUrl.pathname, userCountryUrlPrefix);

  // reconstruct just the path because in dev `redirectUrl.host` is incorrect
  redirect(redirectUrl.pathname + redirectUrl.search + redirectUrl.hash);
}
