import React, { FC } from 'react';

import type { HomepageSearchFilters } from '@rover/types/src/HomepageSearchFilters';

import BoardingTypeFilter from '../../../../../search/SearchPage/components/Filters/BoardingTypeFilter';
import type { UpdateFiltersFunc } from '../UpdateFiltersFunc';

export interface Props {
  filters: HomepageSearchFilters;
  updateFilters: UpdateFiltersFunc;
  required: boolean;
  hasFilterError: boolean;
}

const BoardingTypeConnector: FC<Props> = ({
  filters,
  updateFilters,
  required,
  hasFilterError,
}: Props) => {
  return (
    <BoardingTypeFilter
      onChange={(newInSittersHome, newAtDaycareFacility) => {
        updateFilters({
          inSittersHome: newInSittersHome,
          atDaycareFacility: newAtDaycareFacility,
        });
      }}
      inSittersHome={required ? filters.inSittersHome : true}
      atDaycareFacility={required ? filters.atDaycareFacility : true}
      layoutVariant="fully-inline"
      required={!!required}
      error={hasFilterError}
      placement="homepage-search"
    />
  );
};

export default BoardingTypeConnector;
