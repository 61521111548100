import React, { useState } from 'react';
import z from 'zod';

import { Button, Flex } from '@rover/kibble/core';
import { Form, useKibbleForm } from '@rover/kibble/forms';
import CheckboxField from '@rover/kibble/forms/fields/CheckboxField';
import { CheckboxFieldCommonProps } from '@rover/kibble/forms/fields/CheckboxField/CheckboxField.common';
import { QuestionDefinitionSelect } from '@rover/rsdk/src/apiClient/latest';

export type Props = {
  questionDefinition: QuestionDefinitionSelect;
  submitButtonText: string;
  onSubmit: (selectedAnswers: Record<string, boolean>) => void;
};

const schema = z.object({
  answerSlug: z.array(z.string()).min(1),
});

const BottomSheetSurveyMultiSelectQuestion = ({
  questionDefinition,
  submitButtonText,
  onSubmit,
}: Props): JSX.Element => {
  const [selectedAnswers, setSelectedAnswers] = useState<Record<string, boolean>>({});
  const { configureField, formProps } = useKibbleForm({ schema });

  const handleCheckBoxChange: CheckboxFieldCommonProps['onChange'] = ({ values }) => {
    const newSelectedAnswers = values.reduce((acc, v) => ({ ...acc, [v]: true }), {});
    setSelectedAnswers(newSelectedAnswers);
  };

  return (
    <Form {...formProps} hideRequiredFieldWarning>
      <Flex flexDirection="column">
        <CheckboxField
          {...configureField('answerSlug')}
          required={false}
          primaryLabel={questionDefinition.content}
          options={questionDefinition.answers.map(({ answerSlug, content, disabling }) => ({
            value: answerSlug,
            primaryLabel: content,
            disabled: selectedAnswers['none-of-the-above'] && !disabling,
          }))}
          onChange={handleCheckBoxChange}
        />
        <Button
          variant="primary"
          size="small"
          fullWidth
          onClick={() => onSubmit(selectedAnswers)}
          disabled={Object.keys(selectedAnswers).length === 0}
        >
          {submitButtonText}
        </Button>
      </Flex>
    </Form>
  );
};

export default BottomSheetSurveyMultiSelectQuestion;
