import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { DSTokenMap, MQ, Spacing } from '@rover/kibble/styles';

import Card from '../../cards/Card';

type WrapperProps = {
  position: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
};

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  position: relative;

  ${(props) =>
    !props.position.includes('top')
      ? ''
      : css`
          flex-direction: column;
        `}

  ${(props) =>
    !props.position.includes('bottom')
      ? ''
      : css`
          flex-direction: column-reverse;
        `};
`;

type ContainerProps = {
  imageSrc: string;
  mobileBreakpoint: MQ;
  position: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
};

export const Container = styled.div<ContainerProps>`
  height: 200px;
  position: relative;
  background-image: url(${(props) => props.imageSrc});
  background-size: cover;
  background-repeat: no-repeat;
  background-color: ${DSTokenMap.BACKGROUND_COLOR_PRIMARY.toString()};
  background-position-x: center;

  ${(props) => props.mobileBreakpoint.toString()} {
    height: 360px;

    ${(props) =>
      !props.position.includes('top')
        ? ''
        : css`
            margin-top: 60px;
          `}

    ${(props) =>
      !props.position.includes('bottom')
        ? ''
        : css`
            margin-bottom: 60px;
          `};
  }
`;

export const MobileCard = styled(Card)<{ mobileBreakpoint: MQ }>`
  display: block;

  ${(props) => props.mobileBreakpoint.toString()} {
    display: none;
  }
`;

export const DesktopCard = styled(Card)<{
  position: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
  mobileBreakpoint: MQ;
}>`
  display: none;
  margin: 0;
  position: absolute;
  width: 360px;

  ${(props) =>
    !props.position.includes('left')
      ? ''
      : css`
          left: 75px;
        `} ${(props) =>
    !props.position.includes('right')
      ? ''
      : css`
          right: 75px;
        `} ${(props) =>
    !props.position.includes('top')
      ? ''
      : css`
          top: -55px;
        `} ${(props) =>
    !props.position.includes('bottom')
      ? ''
      : css`
          bottom: -55px;
        `} ${(props) => props.mobileBreakpoint.toString()} {
    display: block;
  }

  ${MQ.LG.toString()} {
    ${(props) =>
      !props.position.includes('left')
        ? ''
        : css`
            left: 95px;
          `}
    ${(props) =>
      !props.position.includes('right')
        ? ''
        : css`
            right: 95px;
          `};
  }
`;

const ImageCreditWrapper = styled.span`
  background-color: ${DSTokenMap.BACKGROUND_COLOR_CONTRAST.withOpacity(0.5)};
  border-radius: 4px;
  bottom: ${Spacing.L.toString()};
  color: ${DSTokenMap.TEXT_COLOR_PRIMARY_INVERSE.toString()};
  left: auto;
  padding: 3px 5px;
  position: absolute;
  right: ${Spacing.L.toString()};
`;

export type Props = {
  imageSrc: string;
  imageAriaLabel?: string;
  imageCreditText?: string;
  imageLocationText?: string;
  position?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
  children: ReactNode;
  mobileBreakpoint?: MQ;
} & Omit<React.ComponentProps<typeof Wrapper>, 'position'>;

const CaptionedImage = ({
  imageSrc,
  imageAriaLabel,
  imageCreditText,
  imageLocationText,
  position = 'top-left',
  children,
  mobileBreakpoint = MQ.SM_UP,
  ...other
}: Props): JSX.Element => (
  <Wrapper position={position} {...other} data-testid="styled-div-wrapper">
    <MobileCard hasHr mobileBreakpoint={mobileBreakpoint}>
      {children}
    </MobileCard>
    <Container
      position={position}
      imageSrc={imageSrc}
      role="img"
      aria-label={imageAriaLabel}
      mobileBreakpoint={mobileBreakpoint}
    >
      <DesktopCard
        hasHr
        position={position}
        mobileBreakpoint={mobileBreakpoint}
        data-testid="styled-desktop-card"
      >
        {children}
      </DesktopCard>
    </Container>
    {imageCreditText && imageLocationText && (
      <ImageCreditWrapper data-testid="styled-span">
        <strong data-testid="styled-span2">{imageCreditText}&nbsp;</strong>in {imageLocationText}
      </ImageCreditWrapper>
    )}
  </Wrapper>
);

export default CaptionedImage;
