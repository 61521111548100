import React from 'react';
import { t } from '@lingui/macro';
import styled from 'styled-components';

import { TrustLogo } from '@rover/icons';
import { Box, Button, Display, Flex, Heading, Image, Paragraph } from '@rover/kibble/core';
import { DSTokenMap } from '@rover/kibble/styles';
import { emitAnalyticsEvent } from '@rover/rsdk/src/modules/Analytics';
import { useI18n } from '@rover/rsdk/src/modules/I18n';
import RoverProtect from '@rover/shared/images/homepage/rover-protect-homepage.png';
import RoverProtectMobile from '@rover/shared/images/homepage/rover-protect-homepage-mobile.png';
import useCountryConfigurationShim from '@rover/shared/js/hooks/useCountryConfigurationShim';

import {
  HomepageBookSitterClick,
  InfoCardLearnMoreGuaranteeClick,
  LearnMoreClick,
} from '../utils/analyticsEvents';
import { ScreenReferenceEnum } from '../utils/analyticsTypes';
import {
  HOME_HEADING_TEXT,
  HOME_SUB_HEADING_TEXT,
  HOME_SUBMIT_BUTTON_TEXT,
  LEARN_MORE_BUTTON_TEXT,
  LEARN_MORE_TEXT,
  MESSAGE_PHOTO_TEXT,
  ROVER_GUARANTEE_TEXT,
  SCREENED_PET_SITTERS_TEXT,
  SUPPORT_TEXT,
} from '../utils/RoverProtectSignals.constants';
import { StyledAnchor } from '../utils/styles';

const StyledImageMobile = styled(Image)`
  border-top-left-radius: ${DSTokenMap.BORDER_RADIUS_PRIMARY};
  border-top-right-radius: ${DSTokenMap.BORDER_RADIUS_PRIMARY};
`;

const HomePageSignals = (): JSX.Element => {
  const { i18n } = useI18n();
  const { current } = useCountryConfigurationShim();

  const eventProperties = {
    screenReference: ScreenReferenceEnum.HOMEPAGE,
  };
  const observabilityTags = {
    screenReference: ScreenReferenceEnum.HOMEPAGE,
  };

  return (
    <Flex
      my={['8x', '0x']}
      borderRadius="primary"
      border="2x solid"
      sx={{ boxShadow: 'border_primary' }}
      justifyContent="center"
      flexDirection="column"
      width="100%"
      heigth="auto"
    >
      <Box display={['block', 'none']}>
        <StyledImageMobile
          width="100%"
          height="auto"
          description={i18n._(t`rover protect homepage image of human and pet`)}
          src={RoverProtectMobile}
        />
      </Box>
      <Box p={['6x', '12x']}>
        <Flex>
          <Flex flex="1 0 60%" flexDirection="column">
            <Box display="flex" flexWrap="wrap" alignItems="center">
              <Flex mr="4x">
                <TrustLogo width={36} height={48} />
              </Flex>
              <Display size="400" mb="0x">
                {i18n._(HOME_HEADING_TEXT)}
              </Display>
            </Box>
            <Heading size="300" mt="4x" mb="6x" fontWeight="semibold">
              {i18n._(HOME_SUB_HEADING_TEXT)}
            </Heading>
            <Paragraph mb="6x">{SCREENED_PET_SITTERS_TEXT}</Paragraph>
          </Flex>
          <Box
            display={['none', 'block']}
            position="relative"
            top="-60px"
            right={['auto', '-35px', '0px', '0px']}
          >
            <Box display="flex" height={['auto', '40%', '70%', '100%']}>
              <Image
                height="auto"
                width="auto"
                description={i18n._(t`rover protect homepage image of human and pet`)}
                src={RoverProtect}
              />
            </Box>
          </Box>
        </Flex>
        <Paragraph mb="6x">{MESSAGE_PHOTO_TEXT}</Paragraph>
        <Paragraph mb="6x">
          {ROVER_GUARANTEE_TEXT(current?.formattedVetCareReimbursementAmount || '$25,000')}{' '}
          <StyledAnchor
            href={current?.roverGuaranteeUrl || '/rover-guarantee'}
            target="_blank"
            onClick={() =>
              emitAnalyticsEvent(
                new InfoCardLearnMoreGuaranteeClick(eventProperties, observabilityTags)
              )
            }
          >
            {i18n._(LEARN_MORE_TEXT)}
          </StyledAnchor>
        </Paragraph>
        <Paragraph>{SUPPORT_TEXT}</Paragraph>
        <Box flexDirection="column" mt="12x">
          <Button
            mb="4x"
            variant="primary"
            onClick={() =>
              emitAnalyticsEvent(new HomepageBookSitterClick(eventProperties, observabilityTags))
            }
            href="/search"
            data-testid="rover-protect-signal-book-a-sitter"
            fullWidth
          >
            {i18n._(HOME_SUBMIT_BUTTON_TEXT)}
          </Button>
          <Button
            href="/rover-protect"
            target="_blank"
            variant="flat"
            fullWidth
            onClick={() =>
              emitAnalyticsEvent(new LearnMoreClick(eventProperties, observabilityTags))
            }
          >
            {i18n._(LEARN_MORE_BUTTON_TEXT)}
          </Button>
        </Box>
      </Box>
    </Flex>
  );
};

export default HomePageSignals;
