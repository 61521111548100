import React, { FC } from 'react';
import { t } from '@lingui/macro';

import { useI18n } from '@rover/rsdk/src/modules/I18n';
import Pets from '@rover/shared/js/search/components/Pets';
import type { HomepageSearchFilters } from '@rover/types/src/HomepageSearchFilters';
import { Pet, PetType } from '@rover/types/src/Pet';
import type { SearchFilters } from '@rover/types/src/SearchFilters';
import { ServiceTypeSlug } from '@rover/types/src/ServiceType';

import type { UpdateFiltersFunc } from '../UpdateFiltersFunc';

export interface Props {
  filters: HomepageSearchFilters;
  pets: Pet[];
  updateFilters: UpdateFiltersFunc;
}

interface PetChangeArgs {
  pet: string[];
  petType: PetType[];
  serviceTypeSlug: string;
}

export const prepareArgsForHandlePetChanges = (
  pet: string[],
  petType: PetType[]
): PetChangeArgs => {
  const shouldUseDropIn = petType.length > 0 && petType.every((type) => type === PetType.CAT);
  return {
    pet,
    petType,
    serviceTypeSlug: shouldUseDropIn ? ServiceTypeSlug.DROP_IN : ServiceTypeSlug.OVERNIGHT_BOARDING,
  };
};

const PetsConnector: FC<Props> = ({ filters, pets, updateFilters }: Props) => {
  const { i18n } = useI18n();

  const handlePetChange = (pet: string[], petType: PetType[]): void => {
    const preparedArgs = prepareArgsForHandlePetChanges(pet, petType);
    return updateFilters({ ...preparedArgs });
  };

  return (
    <Pets
      petOptions={pets}
      label={i18n._(t`Your pets:`)}
      searchFilters={filters}
      onChange={(searchFilters: Partial<SearchFilters>) =>
        handlePetChange(searchFilters.pet as string[], searchFilters.petType as PetType[])
      }
    />
  );
};

export default PetsConnector;
