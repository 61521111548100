import React from 'react';

import type { QuestionDefinitionSelect } from '@rover/rsdk/src/apiClient/latest';

import BottomSheetSurveyConfirmation from './BottomSheetSurveyConfirmation';
import BottomSheetSurveyIntro from './BottomSheetSurveyIntro';
import BottomSheetSurveyMultiSelectQuestions from './BottomSheetSurveyMultiSelectQuestion';
import BottomSheetSurveySingleSelectQuestion from './BottomSheetSurveySingleSelectQuestion';

export enum SurveySteps {
  INTRODUCTION = 'introduction',
  QUESTIONS = 'questions',
  CONFIRMATION = 'confirmation',
}

export type Props = {
  confirmationText: string;
  introButtonText: string;
  introText: string;
  surveyStep?: SurveySteps;
  submitButtonText: string;
  questionDefinition: QuestionDefinitionSelect;
  onSubmit: (selectedAnswers: Record<string, boolean>) => void;
  onStepChange: (step: SurveySteps) => void;
};

const BottomSheetSurveyContent = ({
  confirmationText,
  introButtonText,
  introText,
  surveyStep,
  submitButtonText,
  questionDefinition,
  onSubmit,
  onStepChange,
}: Props): JSX.Element | null => {
  switch (surveyStep) {
    case SurveySteps.INTRODUCTION:
      return (
        <BottomSheetSurveyIntro
          text={introText}
          buttonText={introButtonText}
          onButtonClick={() => onStepChange(SurveySteps.QUESTIONS)}
        />
      );
    case SurveySteps.QUESTIONS:
      return questionDefinition.multiSelect ? (
        <BottomSheetSurveyMultiSelectQuestions
          questionDefinition={questionDefinition}
          submitButtonText={submitButtonText}
          onSubmit={(selectedAnswers) => {
            onSubmit(selectedAnswers);
            onStepChange(SurveySteps.CONFIRMATION);
          }}
        />
      ) : (
        <BottomSheetSurveySingleSelectQuestion
          questionDefinition={questionDefinition}
          submitButtonText={submitButtonText}
          onSubmit={(selectedAnswer) => {
            onSubmit(selectedAnswer);
            onStepChange(SurveySteps.CONFIRMATION);
          }}
        />
      );
    /* Add extra conditionals for different types of question definitions */
    case SurveySteps.CONFIRMATION:
      return <BottomSheetSurveyConfirmation text={confirmationText} />;
    default:
      return null;
  }
};

export default BottomSheetSurveyContent;
