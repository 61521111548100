export enum ScreenReferenceEnum {
  HOMEPAGE = 'homepage',
  MEMBER_PROFILE = 'member-profile',
  CONVERSATION = 'conversation',
  CHECKOUT = 'checkout',
}

export type EventProperties = {
  personId?: string;
  screenReference?: ScreenReferenceEnum;
};
export type ObservabilityTags = {
  screenReference: ScreenReferenceEnum;
};
