import React from 'react';
import styled, { css } from 'styled-components';

import { Flex } from '@rover/kibble/core';
import { DSTokenMap, MQ, Spacing } from '@rover/kibble/styles';
import {
  Container as CaptionedImageContainer,
  DesktopCard,
} from '@rover/react-lib/src/components/images/CaptionedImage';
import QuoteCaptionedImage, {
  AttributionWrapper,
  Props as QuoteCaptionedImageProps,
  StyledHeading,
} from '@rover/react-lib/src/components/images/QuoteCaptionedImage/QuoteCaptionedImage';
import leftDETestimonial from '@rover/react-lib/src/images/homepage/testimonial-de-left@1x.webp';
import rightDETestimonial from '@rover/react-lib/src/images/homepage/testimonial-de-right@1x.webp';
import leftFRTestimonial from '@rover/react-lib/src/images/homepage/testimonial-fr-left@1x.webp';
import rightFRTestimonial from '@rover/react-lib/src/images/homepage/testimonial-fr-right@1x.webp';
import leftITESTestimonial from '@rover/react-lib/src/images/homepage/testimonial-it-es-left@1x.webp';
import rightITESTestimonial from '@rover/react-lib/src/images/homepage/testimonial-it-es-right@1x.webp';
import leftNLTestimonial from '@rover/react-lib/src/images/homepage/testimonial-nl-left@1x.webp';
import rightNLTestimonial from '@rover/react-lib/src/images/homepage/testimonial-nl-right@1x.webp';
import leftSENOTestimonial from '@rover/react-lib/src/images/homepage/testimonial-se-no-left@1x.webp';
import rightSENOTestimonial from '@rover/react-lib/src/images/homepage/testimonial-se-no-right@1x.webp';
import leftUKTestimonial from '@rover/react-lib/src/images/homepage/testimonial-uk-left@1x.webp';
import rightUKTestimonial from '@rover/react-lib/src/images/homepage/testimonial-uk-right@1x.webp';
import leftUSTestimonial from '@rover/react-lib/src/images/homepage/vision-testimonial-left@1x.webp';
import rightUSTestimonial from '@rover/react-lib/src/images/homepage/vision-testimonial-right@1x.webp';
import {
  CountryUrlPrefix,
  getCountryUrlPrefix,
} from '@rover/shared/js/constants/roverGeo.constants';
import { getURL } from '@rover/shared/js/utils/getFullUrl';

// For the future Irish English page, use the UK testimonials
// Remove this when we have Irish testimonials
type AllowedCountryPrefix = CountryUrlPrefix | 'ie';

const sharedStyles = css`
  flex-grow: 1;

  ${DesktopCard} {
    left: 24px;
    top: 253px;
    bottom: auto;
    width: 374px;
  }

  ${CaptionedImageContainer} {
    height: 275px;

    ${MQ.MD_UP.toString()} {
      // This margin offsets the card position on desktop.
      // Putting it on the image rather than the containing element
      // prevents collapsing margins with the following section
      margin-bottom: 200px;
      height: 360px;
    }
  }

  ${StyledHeading} {
    font-weight: ${DSTokenMap.PARAGRAPH_300_FONT_WEIGHT};
    font-size: ${DSTokenMap.PARAGRAPH_300_FONT_SIZE};
    margin-bottom: 0;
  }

  ${AttributionWrapper} {
    display: block;
  }
`;

const LeftStyledQuoteCaptionedImage = styled(QuoteCaptionedImage)`
  ${sharedStyles}
  ${MQ.MD_UP.toString()} {
    margin-right: ${Spacing.M.toString()};
  }
`;

const RightStyledQuoteCaptionedImage = styled(QuoteCaptionedImage)`
  ${sharedStyles}
  ${MQ.MD_UP.toString()} {
    margin-left: ${Spacing.M.toString()};
  }
`;

const sharedProps = {
  mobileBreakpoint: MQ.MD_UP,
  position: 'bottom-left',
} as const;

type CountryTestimonials = {
  left: QuoteCaptionedImageProps;
  right: QuoteCaptionedImageProps;
};

const DEFAULT_TESTIMONIALS = {
  left: {
    imageSrc: leftUSTestimonial,
    imageAriaLabel: 'Smiling woman sitting in the back of a car with her dog',
    quote:
      'I was nervous to leave Sam with strangers, but my worries quickly faded. Going forward Rover will be my first choice for pet sitting.',
    attribution: 'Molly S.',
  },
  right: {
    imageSrc: rightUSTestimonial,
    imageAriaLabel: 'Smiling woman holding a cat',
    quote:
      'My sitter took great care of my cat, above and beyond my expectations. I would book with Rover again in a heartbeat!',
    attribution: 'Danielle H.',
  },
};

const testimonialsMap: Partial<Record<AllowedCountryPrefix, CountryTestimonials>> = {
  us: {
    ...DEFAULT_TESTIMONIALS,
  },
  ca: {
    ...DEFAULT_TESTIMONIALS,
  },
  fr: {
    left: {
      imageSrc: leftFRTestimonial,
      imageAriaLabel: 'Femme souriante caressant un chien',
      quote:
        "Tout s'est super bien passé avec ma pet sitter ! Flexible sur les horaires, aux petits soins avec ma petite Holly (balades, jeux), donne des nouvelles avec des photos, bref je recommande sans hésiter !",
      attribution: 'Laura S.',
    },
    right: {
      imageSrc: rightFRTestimonial,
      imageAriaLabel: 'Homme souriant tenant un chat',
      quote:
        "Mon pet sitter est super. Il aime vraiment les animaux. Il s'est occupé de notre vieille chatte merveilleusement bien. Il a même su créer un lien avec elle, ce qui n'est pas toujours facile. Nous le recommandons les yeux fermés.",
      attribution: 'Michel A.',
    },
  },
  it: {
    left: {
      imageSrc: leftITESTestimonial,
      imageAriaLabel: 'Donna sorridente con un gatto',
      quote:
        'La pet sitter è stata gentile, affidabile e professionale. Il mio gatto doveva fare la terapia, lei è stata attenta a tutto. Consigliatissima!',
      attribution: 'Chiara R.',
    },
    right: {
      imageSrc: rightITESTestimonial,
      imageAriaLabel: 'Uomo sorridente con un cane',
      quote:
        "Il mio pet sitter è una persona molto disponibile. Si è adeguato al mio programma anche con breve preavviso. Ha due piccoli cani che sono andati molto d'accordo con il mio, hanno passeggiato insieme nel verde, e Ziggy era molto contento! Consigliatissimo!",
      attribution: 'Marco R.',
    },
  },
  es: {
    left: {
      imageSrc: leftITESTestimonial,
      imageAriaLabel: 'Mujer sonriente que sostiene un gato',
      quote:
        'Mi cuidadora se ha ocupado muy bien de nuestro gato. Un gato que necesita administración de medicación diaria. Nos ha enviado fotografías y comentarios, se nota que ha estado muy bien cuidado. Sin duda la recomendaremos.',
      attribution: 'Carlota M.',
    },
    right: {
      imageSrc: rightITESTestimonial,
      imageAriaLabel: 'Hombre que sonríe sosteniendo un perro',
      quote:
        'Mi cuidador es un encanto, responsable, atento y muy agradable. Es la primera vez que uso la aplicación y me alegro de que haya sido él con quien nos estrenamos Chispo y yo. ¡Seguiré contactándolo para los paseos!',
      attribution: 'José M.',
    },
  },
  uk: {
    left: {
      imageSrc: leftUKTestimonial,
      imageAriaLabel: 'Smiling woman with a dog outside',
      quote:
        'The sitter was an excellent communicator, very reliable and friendly; we had no concerns at all about having the sitter coming into our home to check in on Luna. Highly recommended!',
      attribution: 'George R.',
    },
    right: {
      imageSrc: rightUKTestimonial,
      imageAriaLabel: 'Smiling man holding a cat',
      quote:
        'I fully trusted my sitter with caring for my cat; was very attentive, asked pertinent questions, and double-checked if anything could be done better. Was flexible when dates had to be changed, kept me updated, and responded quickly to messages',
      attribution: 'Amelia M.',
    },
  },
  nl: {
    left: {
      imageSrc: leftNLTestimonial,
      imageAriaLabel: 'Glimlachende man die een hond aait',
      quote:
        'Vandaag heeft mijn oppas voor de tweede keer op onze Bobbi gepast en het was weer een groot succes. Leuk gespeeld met de andere honden, heerlijk buiten ge-ravot. Een heel tevreden en moe hondje is weer thuis.',
      attribution: 'Jan V.',
    },
    right: {
      imageSrc: rightNLTestimonial,
      imageAriaLabel: 'Glimlachende vrouw die een kat aait',
      quote:
        'De oppas is een heel zorgvuldige en betrokken kattenoppas! Graag laat ik een volgende keer weer alle zorg voor mijn oudere kat aan dezelfde oppas over.',
      attribution: 'Marleen S.',
    },
  },
  de: {
    left: {
      imageSrc: leftDETestimonial,
      imageAriaLabel: 'Lächelnder Mann, der einen Hund streichelt',
      quote:
        'Mein Sitter hat unsere Zuka bei sich zuhause betreut. Sie ist leider oft nervös und unsicher in neuen Situationen, aber mein sitter hat sie sehr gut beruhigen können. Vielen Dank für deine Bemühungen, auch wenn unsere Katze noch sehr unsicher war!',
      attribution: 'Anna P.',
    },
    right: {
      imageSrc: rightDETestimonial,
      imageAriaLabel: 'Lächelnde Frau, die hockt und eine Katze anschaut',
      quote:
        'Mein Sitter ist liebevoll mit meiner Hündin umgegangen. Hat flexibel auf meine Planung reagiert und war stets erreichbar. Außerdem wurden mir zwischendurch Fotos geschickt, sodass ich aus der Ferne auf dem Laufenden bleiben konnte. Danke!',
      attribution: 'Ben L.',
    },
  },
  se: {
    left: {
      imageSrc: leftSENOTestimonial,
      imageAriaLabel: 'Kvinna som håller och pussar en katt',
      quote:
        'Jag kan varmt rekommendera min djurvakt. Djurvakten tog hand om vår katt på allra bästa sätt och skickade mycket uppskattade bilder och meddelanden som gjorde att det kändes väldigt tryggt för oss. Perfekt helt enkelt!',
      attribution: 'Elsa P.',
    },
    right: {
      imageSrc: rightSENOTestimonial,
      imageAriaLabel: 'Leende man som klappar en hund',
      quote:
        'Djurvakten är helt underbar med hundarna. Härliga aktiva utevistelser, mys och lyx hemma och regelbundna uppdateringar i appen kring hur de har det. Vi kommer absolut boka in vår hund hos djurvakten igen - rekommenderar hen varmt.',
      attribution: 'Alex S.',
    },
  },
  no: {
    left: {
      imageSrc: leftSENOTestimonial,
      imageAriaLabel: 'Kvinne som holder og kysser en katt',
      quote:
        'Kattepasseren anbefales på det høyeste. Kjempelett å kommunisere med og koselig å prate med. Kattepasseren stilte spørsmål om instrukser og var så varm og god med Indie. Indie koset seg masse og håper på å komme på besøk igjen!',
      attribution: 'Anna P.',
    },
    right: {
      imageSrc: rightSENOTestimonial,
      imageAriaLabel: 'Smilende mann som klapper en hund',
      quote:
        'Hundepasseren var helt fantastisk med hundene våre! Passeren er punktlig, god i kommunikasjonen og imøtekommende. Vi fikk kjempe fine oppdateringer med nydelige bilder. Vi kommer til å booke passeren neste gang vi trenger hjelp med hundene.',
      attribution: 'Erik L.',
    },
  },
  ie: {
    left: {
      imageSrc: leftUKTestimonial,
      imageAriaLabel: 'Smiling woman with a dog outside',
      quote:
        'The sitter was an excellent communicator, very reliable and friendly; we had no concerns at all about having the sitter coming into our home to check in on Luna. Highly recommended!',
      attribution: 'George R.',
    },
    right: {
      imageSrc: rightUKTestimonial,
      imageAriaLabel: 'Smiling man holding a cat',
      quote:
        'I fully trusted my sitter with caring for my cat; was very attentive, asked pertinent questions, and double-checked if anything could be done better. Was flexible when dates had to be changed, kept me updated, and responded quickly to messages',
      attribution: 'Amelia M.',
    },
  },
};

const getTestimonials = (): CountryTestimonials | null => {
  const location = getURL();
  const urlPrefix = getCountryUrlPrefix(location.pathname) ?? 'us';

  // if urlPrefix (active country) without a testimonial, don't display anything
  return testimonialsMap[urlPrefix] || null;
};

const RoverTestimonials: React.FC = () => {
  const testimonials = getTestimonials();
  if (!testimonials) {
    return null;
  }

  const leftProps = {
    ...sharedProps,
    ...testimonials.left,
  };
  const rightProps = {
    ...sharedProps,
    ...testimonials.right,
  };

  return (
    <Flex flexWrap="wrap">
      <LeftStyledQuoteCaptionedImage {...leftProps} />
      <RightStyledQuoteCaptionedImage {...rightProps} />
    </Flex>
  );
};
export default RoverTestimonials;
