/* eslint-disable max-classes-per-file */
/* eslint-disable no-useless-constructor */
import { CONCEPTS } from '@rover/rsdk/src/concepts';
import type { ConsentPurpose } from '@rover/rsdk/src/modules/Analytics';
import { AnalyticsEvent } from '@rover/types/src/Analytics';

/**
 * &nbsp;
 *
 * # Description
 *
 * Fires when user clicks on the date pickers on mobile web homepage.
 *
 * &nbsp;
 */
export class CalendarClick extends AnalyticsEvent {
  eventName = 'mobileweb-home-page-calendar-click';

  consentPurpose: ConsentPurpose = 'performance';

  concept = CONCEPTS.bookings.dates;

  constructor(eventProperties: {
    loggedIn: boolean;
    serviceType?: string;
    postalCode?: string;
    dates?: { startDate?: Date | null; endDate?: Date | null };
  }) {
    super(eventProperties);
  }
}
