import React from 'react';
import styled from 'styled-components';

import { Heading } from '@rover/kibble/core';
import { DSTokenMap, FontSize, FontWeight, Spacing } from '@rover/kibble/styles';

import CaptionedImage, { Props as CaptionedImageProps } from '../CaptionedImage';

export const StyledHeading = styled(Heading)`
  font-family: inherit;
  color: inherit;
  font-size: 26px;
  font-weight: ${FontWeight.NORMAL.toString()};
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: ${Spacing.M.toString()};
`;

export const AttributionWrapper = styled.span`
  display: inline;
  color: ${DSTokenMap.TEXT_COLOR_TERTIARY.toString()};
`;

const SubTextWrapper = styled.div`
  margin-top: ${Spacing.L.toString()};
  font-size: ${FontSize.L.toString()};
`;

const renderSubtext = (subText, linkText, linkUrl) => {
  if (subText) {
    return (
      <SubTextWrapper>
        {subText}&nbsp;
        <a href={linkUrl}>{linkText}</a>
      </SubTextWrapper>
    );
  }

  return null;
};

export type Props = Omit<
  CaptionedImageProps & {
    quote: string;
    attribution: string;
    subText?: string;
    linkText?: string;
    linkUrl?: string;
  },
  'children' | 'ref'
>;

const QuoteCaptionedImage = ({
  imageSrc,
  imageCreditText,
  imageLocationText,
  position,
  quote,
  attribution,
  subText,
  linkText,
  linkUrl,
  ...other
}: Props): JSX.Element => (
  <CaptionedImage
    imageSrc={imageSrc}
    imageCreditText={imageCreditText}
    imageLocationText={imageLocationText}
    position={position}
    {...other}
  >
    {/* role="text" is not a valid ARIA role, but prevents screenreaders "splitting" elements: https://axesslab.com/text-splitting/ */}
    {/* eslint-disable-next-line jsx-a11y/aria-role */}
    <StyledHeading as="div" role="text">
      {quote}
      <AttributionWrapper>&nbsp;-&nbsp;{attribution}</AttributionWrapper>
    </StyledHeading>
    {renderSubtext(subText, linkText, linkUrl)}
  </CaptionedImage>
);

export default QuoteCaptionedImage;
