import React from 'react';
import { t, Trans } from '@lingui/macro';

export const MODAL_HEADING_TEXT = t`What is RoverProtect?`;
export const MODAL_SUB_HEADING_TEXT = (
  <Trans>
    <strong>
      Every booking is backed by tools to help keep you and your pet safe. You’ll have:
    </strong>
  </Trans>
);
export const SCREENED_PET_SITTERS_TEXT = (
  <Trans>
    <strong>Screened pet sitters</strong> have already passed a third-party background check and
    show verified reviews from other pet parents, like you.
  </Trans>
);
export const MESSAGE_PHOTO_TEXT = (
  <Trans>
    <strong>Messaging & photo updates</strong> from your sitter during each stay.
  </Trans>
);

export const ROVER_GUARANTEE_TEXT = (veterinaryCareLimit: string): JSX.Element => (
  <Trans>
    <strong>The Rover Guarantee</strong> can protect you and your pet for up to{' '}
    {veterinaryCareLimit} in eligible vet care.
  </Trans>
);

export const HOME_HEADING_TEXT = t`RoverProtect`;
export const HOME_SUB_HEADING_TEXT = t`Find peace of mind with every booking.`;
export const HOME_SUBMIT_BUTTON_TEXT = t`Book a local sitter`;

export const HOMEPAGE_ROVERPROTECT = (
  <Trans>
    Stay in touch with photos and messaging. Plus, your booking is backed by RoverProtect, including
    24/7 support and reimbursement for eligible vet care.
  </Trans>
);

export const HOMEPAGE_REVIEWS = (
  <Trans>
    Read verified reviews by pet parents like you and choose a screened sitter who’s a great match
    for you and your pets.
  </Trans>
);
export const SUPPORT_TEXT = (
  <Trans>
    <strong>24/7 support</strong> from the Rover team–here to help if you ever need someone to talk
    to.
  </Trans>
);
export const LEARN_MORE_BUTTON_TEXT = t`Learn more about RoverProtect`;
export const MODAL_SUBMIT_BUTTON_TEXT = t`Got it`;
export const LEARN_MORE_TEXT = t`Learn more`;

export const MEMBER_PROFILE_SIGNAL_TEXT = (
  <Trans>
    Each sitter joining Rover needs to pass a background check. And by booking on Rover, you can get
    photo & message updates, 24/7 support, and reimbursement for eligible vet care.
  </Trans>
);

export const CHECKOUT_SIGNAL_TEXT = (
  <Trans>
    This booking is backed by RoverProtect. You can get photo & message updates from your sitter,
    24/7 support, and reimbursement for eligible vet care.
  </Trans>
);

export const CONVERSATION_SIGNAL_TEXT = (
  <Trans>
    <strong>This booking is backed by RoverProtect.</strong> You can get photo & message updates
    from your sitter, 24/7 support, and reimbursement for eligible vet care.
  </Trans>
);

// Survey string
export const SURVEY_NEXT_BUTTON_TEXT = t`Next`;
export const SURVEY_SUBMIT_BUTTON_TEXT = t`Submit`;
