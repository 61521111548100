import React, { FC, useCallback, useState } from 'react';
import { MessageDescriptor } from '@lingui/core';
import { t } from '@lingui/macro';
import moment from 'moment';

import { Box, Button, Flex } from '@rover/kibble/core';
import { useA11yContext } from '@rover/react-lib/src/context/A11yContext';
import { ApiFrontendConfigurationRetrieve200SettingsDogSizeBuckets } from '@rover/rsdk/src/apiClient/latest';
import { useI18n } from '@rover/rsdk/src/modules/I18n';
import { getDateTimeFormatMapForLang } from '@rover/shared/js/constants/i18n/datetime';
import { SERVICE_TYPE_CHOICES } from '@rover/shared/js/constants/service';
import getIsCatOnlyPetType from '@rover/shared/js/search/utilities/getIsCatOnlyPetType';
import { ServiceTypeOption } from '@rover/types';
import { DateRangeEnum, DateRangeValidationError } from '@rover/types/src/datetime/DateRange';
import type { HomepageSearchFilters } from '@rover/types/src/HomepageSearchFilters';
import { Pet } from '@rover/types/src/Pet';
import { ServiceFrequencyEnum } from '@rover/types/src/ServiceFrequency';
import { ServiceType } from '@rover/types/src/ServiceType';

import BoardingTypeConnector from './connectors/BoardingTypeConnector';
import DateRangePickerConnector from './connectors/DateRangePickerConnector';
import DaycareTypeConnector from './connectors/DaycareTypeConnector';
import HomePageSearchBoxLocationInput from './connectors/LocationInputConnector';
import OneTimeRepeatToggleConnector from './connectors/OneTimeRepeatToggleConnector';
import PetsConnector from './connectors/PetsConnector';
import PetSizeConnector from './connectors/PetSizeConnector';
import PetTypeConnector from './connectors/PetTypeConnector';
import RepeatServiceSchedulerConnector from './connectors/RepeatServiceSchedulerConnector';
import ServiceTypeConnector from './connectors/ServiceTypeConnector';
import type { UpdateFiltersFunc } from './UpdateFiltersFunc';

export interface Props {
  dateRangePickerLabel: { id: string };
  endDatePlaceholder?: MessageDescriptor;
  filters: HomepageSearchFilters;
  language: string;
  pets: Pet[];
  petSizeBuckets: ApiFrontendConfigurationRetrieve200SettingsDogSizeBuckets;
  serviceType: ServiceType | undefined;
  serviceTypeOptions: ServiceTypeOption[];
  startDatePlaceholder?: MessageDescriptor;
  submit: () => void;
  updateFilters: UpdateFiltersFunc;
  weightUnit: string;
  className?: string;
  isDaycareFilterEarlierExperiment: boolean;
  isDaycareFilterEarlierExperimentRequired: boolean;
  isDaycareFilterOutsideMarketsInExperiment: boolean;
  isBoardingFilterOutsideMarketsInExperiment: boolean;
  hasFilterError: boolean;
}

const SearchBox: FC<Props> = (props: Props) => {
  const { filters, pets, serviceType, submit, serviceTypeOptions, updateFilters } = props;
  const { i18n } = useI18n();
  const hasPets = pets.length > 0;
  const { setScreenReaderAnnouncement } = useA11yContext();
  const [validationError, setValidationError] = useState<Partial<DateRangeValidationError>>({});
  const [repeatServiceSchedulerValidationError, setRepeatServiceSchedulerValidationError] =
    useState<string | undefined>();

  const handleSubmit = useCallback(() => {
    setScreenReaderAnnouncement(i18n._(t`Form submitted. Navigating to Search page.`));
    submit();
  }, [setScreenReaderAnnouncement, submit]);

  const areServicesLoaded = serviceTypeOptions?.length > 0;

  const setStartOrEndDate = (
    dateChanged: DateRangeEnum,
    message?: string,
    date?: Date,
    isDateValid?: boolean
  ): void => {
    if (dateChanged === DateRangeEnum.START_DATE) {
      updateFilters({
        dateRange: {
          startDate: !isDateValid ? date : filters?.dateRange?.startDate,
          endDate: filters?.dateRange?.endDate,
        },
      });
      setValidationError({ ...validationError, startDate: message });
    } else {
      updateFilters({
        dateRange: {
          startDate: filters?.dateRange?.startDate,
          endDate: !isDateValid ? date : filters?.dateRange?.endDate,
        },
      });
      setValidationError({ ...validationError, endDate: message });
    }
  };

  const onInputBlurDatePicker = (dateChanged, e, isDateValid): void => {
    if (!e?.target?.value) {
      return;
    }
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const minDateWithoutTime = filters.dateRange.startDate;
    minDateWithoutTime?.setHours(0, 0, 0, 0);
    const inputedDate = moment(
      e.target.value,
      getDateTimeFormatMapForLang(props.language).DATE_SHORT,
      true
    );

    if (!inputedDate.isValid()) {
      setStartOrEndDate(
        dateChanged,
        i18n._(t`Use the format ${getDateTimeFormatMapForLang(props.language).DATE_SHORT}`),
        undefined
      );
    } else if (inputedDate.toDate() < today) {
      setStartOrEndDate(
        dateChanged,
        i18n._(t`Please select a date on or after today`),
        inputedDate.toDate()
      );
    } else if (minDateWithoutTime && inputedDate.toDate() < minDateWithoutTime) {
      setStartOrEndDate(
        dateChanged,
        i18n._(t`Please select a date on or after the start date`),
        inputedDate.toDate()
      );
    } else {
      setStartOrEndDate(dateChanged, undefined, undefined, isDateValid);
    }
  };

  const onBlurRepeat = (e): void => {
    if (!e?.target?.value) {
      return;
    }
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const inputedDate = moment(
      e.target.value,
      getDateTimeFormatMapForLang(props.language).DATE_SHORT,
      true
    );

    if (!inputedDate.isValid()) {
      setRepeatServiceSchedulerValidationError(
        i18n._(t`Use the format ${getDateTimeFormatMapForLang(props.language).DATE_SHORT}`)
      );
    } else if (inputedDate.toDate() < today) {
      setRepeatServiceSchedulerValidationError(i18n._(t`Please select a date on or after today`));
    } else {
      setRepeatServiceSchedulerValidationError(undefined);
    }
  };

  return (
    <Box
      className={props.className}
      background="primary"
      width={[720, 720, 850]}
      data-qa-id="homepage-search-box-container"
      data-testid="homepage-search-box-container"
      borderRadius="secondary"
    >
      {!hasPets && <PetTypeConnector {...props} />}
      <Box
        height="100%"
        pt={hasPets ? '4x' : '2x'}
        pb="4x"
        pl="6x"
        pr="6x"
        mt={hasPets ? '8x' : '0x'}
        minHeight={areServicesLoaded ? undefined : '314px'}
      >
        {areServicesLoaded && (
          <>
            <ServiceTypeConnector {...props} />
            {serviceType?.slug === SERVICE_TYPE_CHOICES.boarding &&
              props.isBoardingFilterOutsideMarketsInExperiment &&
              !getIsCatOnlyPetType(filters.petType) && (
                <Box alignItems="flex-start">
                  <BoardingTypeConnector {...props} required />
                </Box>
              )}
            {serviceType?.slug === SERVICE_TYPE_CHOICES.dayCare &&
              (props.isDaycareFilterEarlierExperiment ||
                props.isDaycareFilterOutsideMarketsInExperiment) && (
                <Box alignItems="flex-start">
                  <DaycareTypeConnector
                    {...props}
                    isFilterRequired={
                      props.isDaycareFilterEarlierExperimentRequired ||
                      props.isDaycareFilterOutsideMarketsInExperiment
                    }
                  />
                </Box>
              )}
            <Flex alignItems="flex-start" mb="3x">
              <Box flex={serviceType?.supportsRepeatBookings ? '2' : '1'} mr="3x">
                <HomePageSearchBoxLocationInput {...props} />
              </Box>
              <Box
                flexDirection="column"
                alignItems="flex-start"
                flex={serviceType?.supportsRepeatBookings ? '3' : '2'}
              >
                {serviceType?.supportsRepeatBookings ? (
                  <OneTimeRepeatToggleConnector {...props} />
                ) : (
                  <DateRangePickerConnector
                    onInputBlurDatePicker={onInputBlurDatePicker}
                    shouldAutoSwitch={false}
                    validationErrors={validationError}
                    {...props}
                  />
                )}
              </Box>
            </Flex>
            {serviceType?.supportsRepeatBookings && (
              <Flex alignItems="flex-start" height="84px">
                {filters.frequency === ServiceFrequencyEnum.ONE_TIME ? (
                  <DateRangePickerConnector
                    {...props}
                    shouldAutoSwitch={false}
                    onInputBlurDatePicker={onInputBlurDatePicker}
                    validationErrors={validationError}
                  />
                ) : (
                  <RepeatServiceSchedulerConnector
                    onInputBlur={onBlurRepeat}
                    validationError={repeatServiceSchedulerValidationError}
                    {...props}
                  />
                )}
              </Flex>
            )}
            <Flex alignItems="flex-end">
              <Box flex="7" mr="3x">
                {hasPets ? <PetsConnector {...props} /> : <PetSizeConnector {...props} />}
              </Box>
              <Box flex="5">
                <Button
                  data-testid="search-box-submit"
                  size="large"
                  fullWidth
                  variant="primary"
                  onClick={handleSubmit}
                >
                  {i18n._(t`Search`)}
                </Button>
              </Box>
            </Flex>
          </>
        )}
      </Box>
    </Box>
  );
};

export default SearchBox;
