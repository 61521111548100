import React from 'react';
import { Trans } from '@lingui/macro';

import { Boarding } from '@rover/icons';
import { Flex, Heading, Text } from '@rover/kibble/core';
import { DSTokenMap } from '@rover/kibble/styles';
import Card from '@rover/react-lib/src/components/cards/Card';
import { SERVICE_TYPE_ICONS } from '@rover/react-lib/src/components/formFields/ServiceTypeFilter/ServiceTypeFilter';
import { useI18n } from '@rover/rsdk/src/modules/I18n';
import { SERVICE_TYPE_CHOICES } from '@rover/shared/js/constants/service';

import ServiceTypeDescription from '../../ServiceTypeDescription';

export type Props = {
  services?: ServiceTypeDescription[];
};

const RebookingSearch = ({ services = [] }: Props): JSX.Element => {
  const { i18n } = useI18n();
  const filteredServices = services.filter((s) => s.slug !== SERVICE_TYPE_CHOICES.training);
  return (
    <Card marginLeft="1x" display="flex" flexDirection="column">
      <Heading size="300">
        <Trans>Search for a sitter</Trans>
      </Heading>
      <Flex flexGrow="1" justifyContent="space-between" flexDirection="column">
        {filteredServices.map((service, index) => {
          const IconService = SERVICE_TYPE_ICONS[service.slug] || Boarding;
          return (
            service.name && (
              <Flex
                href={service.webAction}
                key={service.slug}
                borderRadius="5px"
                border={`${DSTokenMap.BORDER_WIDTH_PRIMARY.toString()} solid ${DSTokenMap.BORDER_COLOR_PRIMARY.toString()}`}
                alignItems="center"
                paddingY="2x"
                paddingX="4x"
                as="a"
                sx={{
                  '&:hover': {
                    backgroundColor: DSTokenMap.BACKGROUND_COLOR_SECONDARY.toString(),
                  },
                  '&:focus, &:visited, &:hover': {
                    textDecoration: 'none !important',
                  },
                }}
                mb={index !== filteredServices.length ? '3x' : undefined}
                flexGrow="1"
              >
                <IconService width="20px" fill={DSTokenMap.TEXT_COLOR_SECONDARY.toString()} />
                <Text size="100" ml="4x" fontWeight="regular" textColor="secondary">
                  {i18n._(service.name)}
                </Text>
              </Flex>
            )
          );
        })}
      </Flex>
    </Card>
  );
};

export default RebookingSearch;
