import React, { Component } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
`;
export const PrevComponentWrapper = styled.div<{
  visible: boolean;
  transitionTime: number;
}>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity ${(props) => props.transitionTime / 1000}s ease;
`;
export type Props = {
  children: any;
  transitionTime: number;
};
export type State = {
  currentComponent: JSX.Element | null | undefined;
  prevComponent?: JSX.Element;
  prevComponentVisible: boolean;
};
export default class Crossfader extends Component<Props, State> {
  currentRef: HTMLElement | null | undefined;

  prevRef: HTMLElement | null | undefined;

  static defaultProps = {
    transitionTime: 400,
  };

  state = {
    currentComponent: undefined,
    prevComponent: undefined,
    prevComponentVisible: true,
  };

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static getDerivedStateFromProps(props, state) {
    // when mounting the first time
    if (!state.currentComponent) {
      return {
        currentComponent: props.children,
      };
    }

    // if changing children
    if (props.children !== state.currentComponent) {
      return {
        prevComponent: state.currentComponent,
        currentComponent: props.children,
      };
    }

    return null;
  }

  componentDidUpdate(prevProps: Props, prevState: State): void {
    // if we have a new prevComponent, fade it out
    if (!prevState.prevComponent && this.state.prevComponent) {
      this.fadeOutPrevComponent();
    }
  }

  fadeOutPrevComponent = (): void => {
    // first setTimeout is to let the prevComponent mount before we start to transition it's opacity
    setTimeout(() => {
      // fade out
      this.setState((state) => ({ ...state, prevComponentVisible: false }));
      // and remove prevComponent
      setTimeout(() => {
        this.setState((state) => ({
          ...state,
          prevComponent: undefined,
          prevComponentVisible: true,
        }));
      }, this.props.transitionTime);
    });
  };

  render(): JSX.Element {
    const { transitionTime, ...other } = this.props;
    const { currentComponent, prevComponent, prevComponentVisible } = this.state;
    return (
      <Wrapper {...other}>
        {currentComponent}
        {prevComponent && (
          <PrevComponentWrapper visible={prevComponentVisible} transitionTime={transitionTime}>
            {prevComponent}
          </PrevComponentWrapper>
        )}
      </Wrapper>
    );
  }
}
