import React from 'react';
import { Helmet } from 'react-helmet';

const HtmlHeader = ({
  title,
  metaDescription,
}: {
  title: string;
  metaDescription: string;
}): JSX.Element => (
  <Helmet encodeSpecialCharacters={false}>
    <title>{title}</title>
    <meta property="og:title" content={title} />

    <meta name="twitter:card" content="summary" />
    <meta property="og:type" content="website" />

    <meta property="og:description" content={metaDescription} />
    <meta name="description" content={metaDescription} />
  </Helmet>
);

export default HtmlHeader;
