import { css } from 'styled-components';

import { Color } from '@rover/kibble/styles';

export const ICON_BRAND_2_COLORS_STYLES = css`
  fill: ${Color.BLUE_400.toString()};
`;

export const HEADING_BRAND_2_FONT_STYLES = css`
  font-family: Bogart-Semibold, Georgia, serif !important;
  font-weight: normal !important;
`;
