import React from 'react';

import { MQ } from '@rover/kibble/styles';
import { Connect } from '@rover/react-lib/src/components/utils/Redux';
import useRedirectToCorrectCountryPage from '@rover/react-lib/src/hooks/useRedirectToCorrectCountryPage';
import roverHomepageHeroDesktop from '@rover/react-lib/src/images/homepage/rover_homepage_hero_desktop.webp';
import roverHomepageHeroTablet from '@rover/react-lib/src/images/homepage/rover_homepage_hero_tablet.webp';
import { selectors as countryConfigurationSelectors } from '@rover/react-lib/src/redux/ducks/countryConfiguration.duck';
import { selectors as countryFooterLinksSelectors } from '@rover/react-lib/src/redux/ducks/countryFooterLinks.duck';
import { selectors as countryRegionLinksSelectors } from '@rover/react-lib/src/redux/ducks/countryRegionLinks.duck';
import {
  getCountryUrlPrefix,
  US_COUNTRY_URL_PREFIX,
} from '@rover/shared/js/constants/roverGeo.constants';
import { getURL } from '@rover/shared/js/utils/getFullUrl';
import { referrerIsRover } from '@rover/shared/js/utils/referrer';
import { redirect } from '@rover/utilities/url';

import EXPERIMENTS from '../../../constants/experiments.constants';

import HomePage from './HomePage';
import { effects as homePageEffects, selectors as homePageSelectors } from './HomePage.duck';

const selector = (state) => ({
  metaTags: homePageSelectors.getMetaTags(state),
  countryFooterLinks: countryFooterLinksSelectors.getCountryFooterLinks(state),
  countryRegionLinks: countryRegionLinksSelectors.getCountryRegionLinks(state),
  roverGuaranteeLink: countryConfigurationSelectors.getRoverGuaranteeUrl(state),
  localizedHelpLink: homePageSelectors.getLocalizedHelpLink(state),
  cxOperatingHours: countryConfigurationSelectors.getCxOperatingHours(state),
  shouldShowTrustpilotBadgeStatic: homePageSelectors.getShouldShowTrustpilotBadgeStatic(state),
  isRolloutRoverProtectCh2Active: homePageSelectors.getIsRolloutRoverProtectCh2Active(state),
  isRoverProtectCh2SurveyDisabled: homePageSelectors.getIsRoverProtectCh2SurveyDisabled(state),
  showRoverProtectSignalsWithSurvey: homePageSelectors.getIsRolloutRoverProtectCh2Experiment(state)(
    EXPERIMENTS.ROLLOUT_ROVER_PROTECT_CH_2_EXPERIMENT.roverProtectSignalsWithSurvey
  ),
  showRoverProtectNoSignalsWithSurvey: homePageSelectors.getIsRolloutRoverProtectCh2Experiment(
    state
  )(EXPERIMENTS.ROLLOUT_ROVER_PROTECT_CH_2_EXPERIMENT.roverProtectNoSignalsWithSurvey),
});

function redirectUsToIndex(): void {
  const location = getURL();
  const countryUrlPrefix = getCountryUrlPrefix(location.pathname);

  // always redirect `/us/` to `/`
  if (countryUrlPrefix === US_COUNTRY_URL_PREFIX) {
    // same exact location, just trim off the leading bit
    location.pathname = location.pathname.slice('/us'.length);
    if (process.env.JS_ENV_SERVER) {
      // reconstruct just the path because in dev `location.host` is incorrect
      redirect(location.pathname + location.search + location.hash);
    }
  }
}

function useRedirectToCountrySpecificHomepage(): void {
  useRedirectToCorrectCountryPage({
    canRedirect(requestCountryUrlPrefix) {
      // don't redirect non-US users coming to the US index from the site
      if (referrerIsRover() && requestCountryUrlPrefix === null) {
        return false;
      }
      return true;
    },
    getNewPath(currentPath, nextCountryUrlPrefix) {
      if (nextCountryUrlPrefix === US_COUNTRY_URL_PREFIX) return '/';
      return `/${nextCountryUrlPrefix}/`;
    },
  });
}

const HomePageContainer: React.FC & { earlyPrefetchData: any; preloadAssets: any } = () => {
  redirectUsToIndex();
  useRedirectToCountrySpecificHomepage();

  return (
    <Connect selector={selector}>{(connectedProps) => <HomePage {...connectedProps} />}</Connect>
  );
};

HomePageContainer.earlyPrefetchData = () => homePageEffects.prefetchData;
// preload the images rendered by `BackgroundImage`
// in `src/frontend/react-lib/src/components/heroes/Hero/Hero.tsx`
// using the appropriate media query so only one image gets preloaded
HomePageContainer.preloadAssets = [
  {
    href: roverHomepageHeroTablet,
    rel: 'preload',
    as: 'image',
    media: MQ.SM_DOWN,
  },
  {
    href: roverHomepageHeroDesktop,
    rel: 'preload',
    as: 'image',
    media: MQ.MD_UP,
  },
];

export default HomePageContainer;
