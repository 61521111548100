import React from 'react';
import styled from 'styled-components';

import { Box } from '@rover/kibble/core';
import { MQ } from '@rover/kibble/styles';
import useMatchMedia from '@rover/react-lib/src/hooks/useMatchMedia';
import DogTrainingPilotCard from '@rover/react-lib/src/pages/home/DogTrainingPilotCard';
import FacilityPilotCard from '@rover/react-lib/src/pages/home/FacilityPilotCard';
import useInTrainingPilot from '@rover/react-lib/src/pages/training/hooks/useInTrainingPilot';
import { ServiceLinksByCountry } from '@rover/react-lib/src/redux/ducks/serviceLinks.duck';
import { HomepageRebookingProfile } from '@rover/rsdk/src/apiClient/latest';

import ServiceTypeDescription from '../ServiceTypeDescription';

import SearchBoxContainer from './SearchBox/SearchBoxContainer';
import Rebooking from './Rebooking';

type Props = {
  hasDogTraining: boolean;
  isInGingrFacilitiesInSearchExperiment: boolean;
  rebookingProfiles: HomepageRebookingProfile[];
  serviceTypeDescriptions: ServiceTypeDescription[];
  language: string;
  serviceLinksByCountry: ServiceLinksByCountry | null;
  countryCode: string;
};

const StyledSearchBoxContainer = styled(SearchBoxContainer)`
  display: none;
  ${MQ.SM_UP.toString()} {
    display: block;
  }
`;

const RebookingOrSearchBox = ({
  hasDogTraining,
  isInGingrFacilitiesInSearchExperiment,
  rebookingProfiles,
  serviceTypeDescriptions,
  language,
  serviceLinksByCountry,
  countryCode,
}: Props): JSX.Element => {
  const isMobile = useMatchMedia(MQ.XS.getBreakpointString());
  const { shouldShowTrainingEntrypoints } = useInTrainingPilot();

  return (
    <Box
      sx={{
        marginBottom: '-12px',
      }}
      maxWidth={['720px', '720px', '850px']}
      width="100%"
    >
      {rebookingProfiles.length > 0 ? (
        <Rebooking
          language={language}
          services={serviceTypeDescriptions}
          sitters={rebookingProfiles}
        />
      ) : (
        <StyledSearchBoxContainer />
      )}
      <Box my={isMobile ? '0x' : '5x'} maxWidth={['720px', '720px', '850px']}>
        {/* shouldShowTrainingEntrypoints = true means that the rover training information has to be displayed on the DogTrainingPilotCard banner; 
        otherwise, the GoodPup will be shown and in that case, facility banner has priority. */}
        {isInGingrFacilitiesInSearchExperiment && !shouldShowTrainingEntrypoints ? (
          <Box display={['none', 'flex', 'flex']}>
            <FacilityPilotCard />
          </Box>
        ) : (
          <DogTrainingPilotCard
            countryCode={countryCode}
            hasDogTraining={hasDogTraining}
            serviceLinksByCountry={serviceLinksByCountry}
          />
        )}
      </Box>
    </Box>
  );
};

export default RebookingOrSearchBox;
