import styled from 'styled-components';

import { MQ } from '@rover/kibble/styles';
import PageLayout from '@rover/react-lib/src/components/layouts/PageLayout';

export const HomePageLayout = styled(PageLayout)`
  margin-top: 0;
  margin-bottom: 0;
  padding: 0 16px;

  ${MQ.XS.toString()} {
    margin: 0;
  }
`;
