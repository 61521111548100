import React, { useEffect, useState } from 'react';

import type { ScreenReferenceEnum } from '@rover/react-lib/src/components/RoverProtectSignals/utils/analyticsTypes';

import RoverProtectSurvey from './RoverProtectSurvey';

const SHOW_SURVEY_HOMEPAGE_TIMEOUT_MS = 5000;

type Props = {
  showRoverProtectSignalsWithSurvey: boolean;
  showRoverProtectNoSignalsWithSurvey: boolean;
  screenReference: ScreenReferenceEnum;
};

export const RoverProtectSurveyDelay = (props: Props): JSX.Element | null => {
  const [isCountDownUp, setIsCountDownUp] = useState<boolean>(false);
  const [scrolledToMiddle, setScrolledToMiddle] = useState<boolean>(false);
  const [isClient, setIsClient] = useState<boolean>(false);

  useEffect(() => {
    setIsClient(true);
    setTimeout(() => {
      setIsCountDownUp(true);
    }, SHOW_SURVEY_HOMEPAGE_TIMEOUT_MS);
  }, []);

  useEffect(() => {
    const handleScroll = (): void => {
      if (process.env.JS_ENV_CLIENT) {
        const position = Math.floor(window.scrollY);
        // Only execute if user has not scrolled to middle yet, and once they have, set the state to true
        if (scrolledToMiddle === false && position > window.innerHeight / 2) {
          setScrolledToMiddle(true);
        }
      }
    };

    // Only start checking scroll position once the countdown is up,
    // and stop checking once the user has scrolled to the middle once
    if (scrolledToMiddle === false && isCountDownUp) {
      if (process.env.JS_ENV_CLIENT) {
        window.addEventListener('scroll', handleScroll, { passive: true });
      }
    }
    return () => {
      if (process.env.JS_ENV_CLIENT) {
        window.removeEventListener('scroll', handleScroll);
      }
    };
  }, [scrolledToMiddle, isCountDownUp]);

  if (!isClient || !scrolledToMiddle || !isCountDownUp) return null;

  return <RoverProtectSurvey {...props} />;
};

export default RoverProtectSurveyDelay;
