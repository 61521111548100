import React, { FC, Suspense, useCallback, useMemo, useState } from 'react';
import { t } from '@lingui/macro';

import { Box, Flex, Heading, Text } from '@rover/kibble/core';
import BottomSheet from '@rover/kibble/official-patterns/BottomSheet';
import Modal from '@rover/kibble/official-patterns/Modal';
import ClickableInput from '@rover/kibble/patterns/ClickableInput';
import { getTranslatedString } from '@rover/react-lib/src/pages/contact-sitter/ContactSitterPage/components/ContactSitterForm/components/ServiceScheduler/utils';
import { useI18n } from '@rover/rsdk/src/modules/I18n';
import { isMobileBrowser } from '@rover/rsdk/src/modules/Network/userAgent';
import { ServiceTypeOption, ServiceTypeSlug } from '@rover/types';

import { ServiceList } from './ServiceList';
import { iconStyles } from './styles';

const ServiceListLazy = React.lazy(() =>
  import('./ServiceList').then((module) => ({ default: module.ServiceList }))
);

type Props = {
  inputFontSize?: string;
  label: string;
  serviceType: ServiceTypeSlug;
  serviceTypeOptions: ServiceTypeOption[];
  onChange: (value: ServiceTypeSlug) => void;
  onOpen?: () => void;
};

export const ServiceSelectorV2: FC<Props> = ({
  serviceType,
  serviceTypeOptions,
  onChange,
  label,
  inputFontSize,
  onOpen = () => {},
}) => {
  const isMobile = isMobileBrowser();
  const [isOpen, setIsOpen] = useState(false);
  const { i18n } = useI18n();

  const selectedService = useMemo(() => {
    return serviceTypeOptions.find(({ value }) => value === serviceType) || serviceTypeOptions[0];
  }, [serviceType, serviceTypeOptions]);

  const { icon: Icon } = selectedService;

  const handleClose = useCallback(() => setIsOpen(false), []);
  const handleOpen = useCallback(() => {
    onOpen();
    setIsOpen(true);
  }, [onOpen]);

  const inputValue = useMemo(() => {
    return `${getTranslatedString(i18n, selectedService.title)} • ${getTranslatedString(
      i18n,
      selectedService.subtitle
    )}`;
  }, [selectedService, i18n]);

  return (
    <>
      <ClickableInput
        onClick={handleOpen}
        inputLabel={label}
        {...(inputFontSize ? { inputFontSize } : {})}
      >
        <Flex alignItems="center">
          <Flex alignItems="center" marginRight="2x" flex="0 0 auto">
            {Icon && <Icon style={iconStyles} />}
          </Flex>
          <Text
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {inputValue}
          </Text>
        </Flex>
      </ClickableInput>
      {isMobile ? (
        <BottomSheet onRequestClose={handleClose} isOpen={isOpen}>
          <Heading fontWeight="semibold" textAlign="center">
            {i18n._(t`Which service do you need?`)}
          </Heading>
          <Suspense fallback={null}>
            <ServiceListLazy
              serviceTypeOptions={serviceTypeOptions}
              onChange={(serviceTypeSlug: ServiceTypeSlug) => {
                onChange(serviceTypeSlug);
                handleClose();
              }}
              serviceType={serviceType}
            />
          </Suspense>
        </BottomSheet>
      ) : (
        <Modal
          onRequestClose={handleClose}
          isOpen={isOpen}
          headerText={i18n._(t`Select a service`)}
          showCloseIcon
        >
          {/* there is no capability right now to make the modal expand to the full width of the screen,
          so we need to take the max width (600px) and subtract the padding (24px on each side)
        */}
          <Box width={600 - 24 * 2} paddingBottom="6x">
            <ServiceList
              serviceTypeOptions={serviceTypeOptions}
              onChange={(serviceTypeSlug: ServiceTypeSlug) => {
                onChange(serviceTypeSlug);
                handleClose();
              }}
              serviceType={serviceType}
            />
          </Box>
        </Modal>
      )}
    </>
  );
};
