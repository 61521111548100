import React from 'react';
import { MessageDescriptor } from '@lingui/core';
import styled, { css } from 'styled-components';

import { MQ } from '@rover/kibble/styles';
import type { ExtractProps } from '@rover/types/src/ExtractProps';

import BulletedListItem from './BulletedListItem';

type Props = {
  items: Array<ExtractProps<typeof BulletedListItem>>;
  horizontal?: boolean;
};

type WrapperProps = {
  horizontal: boolean | null | undefined;
};

const Wrapper = styled.ul<WrapperProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 0;
  margin-bottom: 0;

  ${(props) =>
    props.horizontal
      ? css`
          ${MQ.SM_UP.toString()} {
            flex-direction: row;
            flex-wrap: wrap;
            align-items: baseline;
          }
        `
      : ''};
`;

export const StyledListItem = styled(BulletedListItem)<{ isLast: boolean }>`
  ${({ horizontal }) =>
    horizontal
      ? css<{ isLast: boolean }>`
          ${MQ.SM_UP.toString()} {
            flex: 1;
            ${({ isLast }) =>
              !isLast
                ? css`
                    padding-right: 10px;
                  `
                : ''};
          }
        `
      : ''};
`;

const BulletedList = ({ items, horizontal = false, ...other }: Props): JSX.Element => {
  return (
    <Wrapper horizontal={horizontal} {...other} role="list">
      {items.map((item, index) => (
        <StyledListItem // use item.id if available
          // use content.id if exist else content
          key={
            item.id ||
            String((item.content && (item.content as MessageDescriptor).id) || item.content)
          }
          horizontal={horizontal}
          isLast={index === items.length - 1}
          role="listitem"
          {...item}
        />
      ))}
    </Wrapper>
  );
};

export default BulletedList;
