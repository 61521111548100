import React from 'react';
import { MessageDescriptor } from '@lingui/core';
import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import styled from 'styled-components';

import { Boarding, Daycare, DogTraining, DropIn, Sitting, Walking } from '@rover/icons';
import { Box, Link } from '@rover/kibble/core';
import Display from '@rover/kibble/core/Display';
import { Color, DSTokenMap, FontSize, FontWeight, MQ, Spacing } from '@rover/kibble/styles';
import Image from '@rover/react-lib/src/components/images/Image/Image';
import { Alignment } from '@rover/react-lib/src/components/layouts/layout.constants';
import ResponsiveLayout from '@rover/react-lib/src/components/layouts/ResponsiveLayout';
import VerticalLayout from '@rover/react-lib/src/components/layouts/VerticalLayout';
import BulletedList, { StyledListItem } from '@rover/react-lib/src/components/lists/BulletedList';
import {
  Bullet,
  ContentColumn,
} from '@rover/react-lib/src/components/lists/BulletedList/BulletedListItem';
import HomePageSignals from '@rover/react-lib/src/components/RoverProtectSignals/HomePageSignals';
import Connect from '@rover/react-lib/src/components/utils/Redux/Connect';
import servicesPhoto1 from '@rover/react-lib/src/images/homepage/services-photo-1@2x.webp';
import brand2ServicesPhoto2 from '@rover/react-lib/src/images/homepage/services-photo-brand-2-2@2x.webp';
import useInTrainingPilot from '@rover/react-lib/src/pages/training/hooks/useInTrainingPilot';
import {
  selectors as countryConfigSelectors,
  selectors as countryConfigurationSelectors,
} from '@rover/react-lib/src/redux/ducks/countryConfiguration.duck';
import { selectors as frontendConfigurationSelectors } from '@rover/react-lib/src/redux/ducks/frontendConfiguration.duck';
import {
  selectors as serviceLinksSelectors,
  ServiceLinksByCountry,
} from '@rover/react-lib/src/redux/ducks/serviceLinks.duck';
import type { CountryConfiguration } from '@rover/rsdk/src/apiClient/latest';
import { ServiceLandingUrlSlugEnum } from '@rover/rsdk/src/apiClient/latest';
import { useI18n } from '@rover/rsdk/src/modules/I18n';

import { HEADING_BRAND_2_FONT_STYLES } from '../brand2ColorStyles.constants';

import TrustAndSafetyCard from './TrustAndSafetyCard';

type ServiceDescription = {
  IconClass?: React.ComponentType<{ fill?: Color }>;
  title: MessageDescriptor;
  content: MessageDescriptor;
  color: Color;
  hasBorder: false;
};

const OVERNIGHT_BOARDING: ServiceDescription = {
  IconClass: Boarding,
  color: Color.GREEN_600,
  title: t`Boarding`,
  content: t`Your pets stay overnight in your sitter’s home. They’ll be treated like part of the family in a comfortable environment.`,
  hasBorder: false,
};

const OVERNIGHT_TRAVELING: ServiceDescription = {
  IconClass: Sitting,
  color: Color.GREEN_600,
  title: t`House Sitting`,
  content: t`Your sitter takes care of your pets and your house. Your pets will get all the attention they need from the comfort of home.`,
  hasBorder: false,
};

const DAYCARE: ServiceDescription = {
  IconClass: Daycare,
  color: Color.GREEN_600,
  title: t`Doggy Day Care`,
  content: t`Your dog spends the day at your sitter’s home. Drop them off in the morning and pick up a happy pup in the evening.`,
  hasBorder: false,
};

const DROP_IN: ServiceDescription = {
  IconClass: DropIn,
  color: Color.GREEN_600,
  title: t`Drop-In Visits`,
  content: t`Your sitter drops by your home to play with your pets, offer food, and give potty breaks or clean the litter box.`,
  hasBorder: false,
};

const WALKING: ServiceDescription = {
  IconClass: Walking,
  color: Color.GREEN_600,
  title: t`Dog Walking`,
  content: t`Your dog gets a walk around your neighborhood. Perfect for busy days and dogs with extra energy to burn.`,
  hasBorder: false,
};

const StyledVerticalLayout = styled(VerticalLayout)`
  margin-bottom: ${Spacing.M.toString()};

  ${MQ.SM_UP.toString()} {
    margin-top: ${Spacing.XL.toString()};
    margin-bottom: ${Spacing.XXL.toString()};
  }
`;

const StyledDiv = styled.div`
  min-width: 50%;
`;

const StyledH3 = styled.h3`
  font-weight: ${FontWeight.BOLD.toString()};
  margin-bottom: 20px;
  ${HEADING_BRAND_2_FONT_STYLES}
`;

type StyledBulletedListProps = { isMobile?: boolean };

const StyledBulletedList = styled(BulletedList)<StyledBulletedListProps>`
  padding-left: ${Spacing.M.toString()};

  ${ContentColumn} {
    margin-left: ${Spacing.M.toString()};
    padding-right: ${Spacing.M.toString()};
  }

  ${StyledListItem} {
    padding: ${Spacing.M.toString()};
    padding-top: 0;
    line-height: 1.6;
    color: ${DSTokenMap.TEXT_COLOR_TERTIARY.toString()};
    font-size: ${FontSize.L.toString()};

    ${MQ.XS_DOWN.toString()} {
      padding: 0;
    }

    ${Bullet}, svg {
      height: ${({ isMobile }) => (isMobile ? '32px' : '50px')};
      width: ${({ isMobile }) => (isMobile ? '32px' : '42px')};
      fill: ${Color.BRAND_ROVER.toString()};
    }

    span {
      color: ${DSTokenMap.TEXT_COLOR_SECONDARY.toString()};
    }

    i {
      font-size: ${FontSize.XXL.toString()};
      margin-right: ${Spacing.M.toString()};
      ${MQ.XS_DOWN.toString()} {
        font-size: ${FontSize.XL.toString()};
      }
    }
  }
`;

const StyledImageWrapper = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  grid-gap: ${Spacing.M.toString()};

  width: 100%;
  margin-bottom: ${Spacing.L.toString()};

  ${MQ.SM_UP.toString()} {
    display: none;
  }
`;

const StyledImage = styled(Image)`
  object-fit: cover;
  width: 100%;
  max-height: 100%;
`;

const StyledSpan = styled.span`
  display: block;
  margin: ${Spacing.M.toString()} 0;
  text-align: center;
`;

type ContextProps = {
  shouldDisplayRoverProtectCh2SignalsWithSurvey: boolean;
};

type Props = ContextProps & {
  countryConfigurationHasDogTraining: boolean;
  countryConfiguration: CountryConfiguration | null | undefined;
  roverGuaranteeLink?: string;
  serviceLinksByCountry: ServiceLinksByCountry | null;
  countryCode: string;
};

const ServicesForEveryPet = ({
  countryConfigurationHasDogTraining,
  countryConfiguration,
  roverGuaranteeLink,
  serviceLinksByCountry,
  countryCode,
  shouldDisplayRoverProtectCh2SignalsWithSurvey,
}: Props): JSX.Element => {
  const { i18n } = useI18n();
  const { shouldShowTrainingEntrypoints } = useInTrainingPilot();

  const DOG_TRAINING: ServiceDescription = {
    IconClass: DogTraining,
    color: Color.GREEN_600,
    title: shouldShowTrainingEntrypoints
      ? ('Dog Training' as unknown as MessageDescriptor)
      : t`Dog Training through GoodPup`,
    content: shouldShowTrainingEntrypoints
      ? ('You and your dog work with a private trainer to build new skills and address behaviors. Meet your trainer in your home or neighborhood.' as unknown as MessageDescriptor)
      : t`Private, virtual dog training through GoodPup. Train from the comfort of your home through 1-1 video chat.`,
    hasBorder: false,
  };

  const getAvailableServices = (): ServiceDescription[] => {
    if (countryConfigurationHasDogTraining || shouldShowTrainingEntrypoints) {
      return [OVERNIGHT_BOARDING, OVERNIGHT_TRAVELING, WALKING, DAYCARE, DROP_IN, DOG_TRAINING];
    }
    return [OVERNIGHT_BOARDING, OVERNIGHT_TRAVELING, WALKING, DAYCARE, DROP_IN];
  };

  const dogTrainingUrl =
    serviceLinksByCountry?.[countryCode.toLowerCase()]?.[
      ServiceLandingUrlSlugEnum['dog-training']
    ] || '/dog-training/';

  const getFooterText = (): JSX.Element => {
    // if the country supports dog training as a service,
    // we need to mention GoodPup. Otherwise, we don't show the link.

    if (countryConfiguration?.hasDogTraining) {
      return (
        <Trans>
          Services booked on Rover are backed by{' '}
          <Link size="100" href="/rover-protect/">
            RoverProtect
          </Link>{' '}
          and{' '}
          <Link size="100" href="/terms/reservation-protection/">
            reservation protection
          </Link>
          .{' '}
          <Link size="100" href="/blog/daycare-terms-and-conditions/">
            Modified terms
          </Link>{' '}
          apply to bookings with day care centers.{' '}
          <Link size="100" href={dogTrainingUrl}>
            GoodPup not included
          </Link>
          .
        </Trans>
      );
    }
    return (
      <Trans>
        Services booked on Rover are backed by{' '}
        <Link size="100" href="/rover-protect/">
          RoverProtect
        </Link>{' '}
        and{' '}
        <Link size="100" href="/terms/reservation-protection/">
          reservation protection
        </Link>
        .
      </Trans>
    );
  };

  return (
    <StyledVerticalLayout hAlign={Alignment.CENTER}>
      {shouldDisplayRoverProtectCh2SignalsWithSurvey ? (
        <Box display={['block', 'none']}>
          <Display size="400" mb="2x" mt="8x" textAlign="center">
            <Trans>Services for every dog and cat</Trans>
          </Display>
        </Box>
      ) : (
        <StyledH3>
          <Trans>Services for every dog and cat</Trans>
        </StyledH3>
      )}
      <StyledImageWrapper>
        <StyledImage src={servicesPhoto1} alt={i18n._('Woman hugging a happy dog')} />
        <StyledImage
          src={brand2ServicesPhoto2}
          alt={i18n._('Woman snuggling an orange cat outdoors')}
        />
      </StyledImageWrapper>
      <ResponsiveLayout>
        <StyledDiv>
          {shouldDisplayRoverProtectCh2SignalsWithSurvey && (
            <Box display={['none', 'block']}>
              <Display size="400" pl="8x" mb="8x">
                <Trans>Services for every dog and cat</Trans>
              </Display>
            </Box>
          )}
          <StyledBulletedList items={getAvailableServices()} />
        </StyledDiv>
        <StyledDiv>
          {shouldDisplayRoverProtectCh2SignalsWithSurvey ? (
            <HomePageSignals />
          ) : (
            <TrustAndSafetyCard />
          )}
          {roverGuaranteeLink && <StyledSpan>{getFooterText()}</StyledSpan>}
        </StyledDiv>
      </ResponsiveLayout>
    </StyledVerticalLayout>
  );
};

const selector = (state) => ({
  countryConfigurationHasDogTraining: countryConfigSelectors.getNavigationHasDogTraining(state),
  countryConfiguration: countryConfigSelectors.getNavigationCountryConfiguration(state),
  roverGuaranteeLink: countryConfigurationSelectors.getRoverGuaranteeUrl(state),
  serviceLinksByCountry: serviceLinksSelectors.getServiceLinksByCountry(state),
  countryCode: frontendConfigurationSelectors.getSettings(state).countryCode,
});

const ServicesForEveryPetContainer = (props: ContextProps): JSX.Element => (
  <Connect selector={selector}>
    {(connectedProps) => <ServicesForEveryPet {...props} {...connectedProps} />}
  </Connect>
);

export default ServicesForEveryPetContainer;
