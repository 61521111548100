import React, { useCallback, useEffect, useState } from 'react';
import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';

import { Link, Paragraph } from '@rover/kibble/core';
import Modal from '@rover/kibble/official-patterns/Modal';
import { CountryConfigurationUrls } from '@rover/rsdk/src/apiClient/latest';
import { emitAnalyticsEvent } from '@rover/rsdk/src/modules/Analytics';
import { useI18n } from '@rover/rsdk/src/modules/I18n';
import type { MarketingOptInIntent } from '@rover/types';

import { MarketingOptInView } from './analyticsEvents';

type Props = {
  shouldShowMarketingOptInModal: boolean;
  isImpersonating: boolean;
  fetchShouldShowMarketingOptInModal: () => void;
  submitMarketingOptInIntent: (notification: MarketingOptInIntent) => void;
  currentUrls: CountryConfigurationUrls;
};

const MarketingOptIn = (props: Props): JSX.Element => {
  const {
    shouldShowMarketingOptInModal,
    isImpersonating,
    fetchShouldShowMarketingOptInModal,
    submitMarketingOptInIntent,
  } = props;
  const [showModal, setShowModal] = useState(false);
  const { i18n } = useI18n();

  const turnNotificationOn = useCallback(
    (value) => {
      submitMarketingOptInIntent({ turnNotificationsOn: value });
      setShowModal(false);
    },
    [submitMarketingOptInIntent]
  );

  useEffect(() => {
    fetchShouldShowMarketingOptInModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showModal) {
      emitAnalyticsEvent(new MarketingOptInView());
    }
  }, [showModal]);

  useEffect(() => {
    if (shouldShowMarketingOptInModal && !isImpersonating) {
      setShowModal(true);
    }
  }, [shouldShowMarketingOptInModal, isImpersonating]);

  return (
    <Modal
      isOpen={showModal}
      headerText={i18n._(
        /* i18n: The title of a modal that will prompt the user to opt-in to email marketing emails */
        t`Stay updated`
      )}
      submitButtonText={i18n._('Accept')}
      cancelButtonText={i18n._('Decline')}
      onRequestClose={() => {}}
      onClickCancel={() => turnNotificationOn(false)}
      onClickSubmit={() => turnNotificationOn(true)}
      showCloseIcon={false}
      aria-modal
    >
      <Paragraph>
        <Trans>
          Yes, I want to receive marketing emails and messages from Rover and its affiliates. I can
          unsubscribe at any time.{' '}
          <Link href={props.currentUrls?.privacyPolicyUrl ?? ''} target="_blank">
            Privacy Statement.
          </Link>
        </Trans>
      </Paragraph>
    </Modal>
  );
};

export default MarketingOptIn;
