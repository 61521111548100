import React, { useId } from 'react';
import { t } from '@lingui/macro';

import Label from '@rover/react-lib/src/components/typography/Label';
import LocationInputContainer from '@rover/react-lib/src/containers/LocationInputContainer';
import type { UpdateFiltersFunc } from '@rover/react-lib/src/pages/index/HomePage/components/SearchBox/UpdateFiltersFunc';
import { useLocationInputTexts } from '@rover/react-lib/src/pages/search/SearchPage/utilities/useLocationInputTexts';
import { useI18n } from '@rover/rsdk/src/modules/I18n';
import type { ServiceType } from '@rover/types/src/ServiceType';

export type Props = {
  serviceType: ServiceType | undefined;
  updateFilters: UpdateFiltersFunc;
  inputHeight?: string;
  inputFontSize?: string;
  label?: string;
  placeholder?: string;
  labelMb?: string;
  labelColor?: string;
  inputValue?: string;
};

const HomePageSearchBoxLocationInput = ({
  serviceType,
  updateFilters,
  inputHeight,
  inputFontSize,
  label,
  placeholder,
  labelMb,
  labelColor,
  inputValue,
}: Props): JSX.Element => {
  const id = useId();
  const { i18n } = useI18n();
  const { getPlaceholderText, getTitleText } = useLocationInputTexts({
    titleTextControl: i18n._(t`${serviceType?.displayName} near`),
  });

  return (
    <>
      <Label mb={labelMb} color={labelColor} htmlFor={id}>
        {label ?? getTitleText()}
      </Label>
      <LocationInputContainer
        inputHeight={inputHeight}
        {...(inputFontSize ? { inputFontSize } : {})}
        enableLatLng
        inputId={id}
        {...(inputValue ? { inputValue } : {})}
        placeholder={placeholder ?? getPlaceholderText()}
        onInputChange={(value) =>
          updateFilters({
            centerlat: undefined,
            centerlng: undefined,
            location: value,
            locationType: undefined,
            rawLocationTypes: undefined,
          })
        }
        onChange={({ value, lat, lng, locationType, rawLocationTypes }) => {
          updateFilters({
            centerlat: lat,
            centerlng: lng,
            location: value,
            locationType,
            rawLocationTypes,
          });
        }}
        validationType="popover"
      />
    </>
  );
};

export default HomePageSearchBoxLocationInput;
