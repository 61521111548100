import React, { useEffect, useId, useRef, useState } from 'react';
import { MessageDescriptor } from '@lingui/core';

import { Box } from '@rover/kibble/core';
import type { QuestionDefinitionSelect } from '@rover/rsdk/src/apiClient/latest';
import { useI18n } from '@rover/rsdk/src/modules/I18n';
import { isMobileBrowser } from '@rover/rsdk/src/modules/Network/userAgent';

import BottomSheetSurveyContent, { SurveySteps } from './BottomSheetSurveyContent';
import BottomSheetSurveyHeader from './BottomSheetSurveyHeader';

export type Props = {
  confirmationText: string;
  introButtonText: MessageDescriptor;
  introText: string;
  showIntroduction?: boolean;
  submitButtonText: MessageDescriptor;
  questionDefinition: QuestionDefinitionSelect;
  autocloseTimeoutMs?: number;
  onSubmit: (arg0: Record<string, boolean>) => void;
  onStepChange?: (arg0: SurveySteps) => void;
};

const BottomSheetSurvey = ({
  confirmationText,
  introButtonText,
  introText,
  showIntroduction = false,
  submitButtonText,
  questionDefinition,
  autocloseTimeoutMs = 3000,
  onSubmit,
  onStepChange,
}: Props): JSX.Element | null => {
  const { i18n } = useI18n();
  const [showSurvey, setShowSurvey] = useState<boolean>(true);
  const [surveyStep, setSurveyStep] = useState<SurveySteps>(
    showIntroduction ? SurveySteps.INTRODUCTION : SurveySteps.QUESTIONS
  );
  const ref = useRef<HTMLElement>(null);
  const headerId = useId();
  const onClose = (): void => {
    setShowSurvey(false);
  };

  useEffect(() => {
    onStepChange?.(surveyStep);
    // @ts-expect-error TS2353
    ref.current?.focus({ preventScroll: false, focusVisible: false });
    if (surveyStep === SurveySteps.CONFIRMATION) {
      setTimeout(onClose, autocloseTimeoutMs);
    }
  }, [surveyStep, autocloseTimeoutMs, onStepChange]);

  const isMobile = isMobileBrowser();

  return showSurvey ? (
    <Box
      ref={ref}
      role="dialog"
      aria-modal="false"
      aria-labelledby={headerId}
      aria-live="assertive"
      tabIndex={-1}
      py="6x"
      px="4x"
      bottom="0x"
      position="fixed"
      background="primary"
      left={isMobile ? '0px' : 'auto'}
      right={isMobile ? '0px' : '20px'}
      width={isMobile ? '100%' : '360px'}
      zIndex="1000"
      variant="elevation_mid"
      sx={{
        borderRadius: '16px 16px 0px 0px',
        '&:focus': { outline: 'none' },
      }}
    >
      <BottomSheetSurveyHeader id={headerId} onClose={onClose} />
      <BottomSheetSurveyContent
        confirmationText={confirmationText}
        introButtonText={i18n._(introButtonText)}
        introText={introText}
        surveyStep={surveyStep}
        submitButtonText={i18n._(submitButtonText)}
        questionDefinition={questionDefinition}
        onSubmit={onSubmit}
        onStepChange={setSurveyStep}
      />
    </Box>
  ) : null;
};

export default BottomSheetSurvey;
