import { FC, useId } from 'react';

import { Text } from '@rover/kibble/core';
import FancyRadio from '@rover/react-lib/src/components/formFields/FancyRadioGroup/FancyRadio';
import RowList from '@rover/react-lib/src/components/RowList';
import { getTranslatedString } from '@rover/react-lib/src/pages/contact-sitter/ContactSitterPage/components/ContactSitterForm/components/ServiceScheduler/utils';
import { useI18n } from '@rover/rsdk/src/modules/I18n';
import { ServiceTypeOption, ServiceTypeSlug } from '@rover/types';

import { iconStyles } from './styles';

type Props = {
  serviceTypeOptions: ServiceTypeOption[];
  onChange: (value: ServiceTypeSlug) => void;
  serviceType: ServiceTypeSlug;
};

type ServiceRowProps = ServiceTypeOption & Omit<Props, 'serviceTypeOptions'>;

const ServiceRow: FC<ServiceRowProps> = ({
  serviceType,
  title,
  subtitle,
  value,
  icon: Icon,
  onChange,
}): JSX.Element => {
  const { i18n } = useI18n();

  const inputId = useId();
  return (
    <RowList.Row
      key={value}
      label={
        <Text textColor="primary" marginBottom="0x" fontWeight="semibold" size={['100', '200']}>
          {getTranslatedString(i18n, title)}
        </Text>
      }
      sublabel={
        <Text textColor="secondary" marginBottom="0x" size={['100', '200']} fontWeight="regular">
          {getTranslatedString(i18n, subtitle)}
        </Text>
      }
      labelHtmlFor={inputId}
      leftItem={Icon && <Icon style={iconStyles} />}
      onClick={() => onChange(value)}
      rightItem={
        <FancyRadio
          actualId={inputId}
          id={`${value}`}
          labelIsProvidedViaHtmlFor
          value={`${serviceType === value}`}
          checked={serviceType === value}
          validationType="inline"
          name="ServiceType"
          onChange={() => onChange(value)}
          optionWidth="24"
          style={{ margin: '0', padding: '0' }}
        />
      }
    />
  );
};

export const ServiceList: FC<Props> = ({
  serviceType,
  serviceTypeOptions,
  onChange,
}): JSX.Element => {
  return (
    <RowList>
      {serviceTypeOptions.map((option) => (
        <ServiceRow key={option.value} {...option} serviceType={serviceType} onChange={onChange} />
      ))}
    </RowList>
  );
};
