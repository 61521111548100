import React, { FC } from 'react';
import { MessageDescriptor } from '@lingui/core';
import { t } from '@lingui/macro';

import type { Day } from '@rover/types';
import type { HomepageSearchFilters } from '@rover/types/src/HomepageSearchFilters';
import getMaxRequestDate from '@rover/react-lib/src/utils/getMaxRequestDate';
import RepeatServiceScheduler from '@rover/shared/js/search/components/RepeatServiceScheduler';

import type { UpdateFiltersFunc } from '../UpdateFiltersFunc';

const MAX_REQUEST_DATE = getMaxRequestDate();

export interface Props {
  filters: HomepageSearchFilters;
  language: string;
  startDatePlaceholder?: MessageDescriptor;
  updateFilters: UpdateFiltersFunc;
  validationError?: string;
  onInputBlur?: (e?: React.FocusEvent<HTMLDivElement, Element>) => void;
}

const RepeatServiceSchedulerConnector: FC<Props> = ({
  filters,
  language,
  startDatePlaceholder,
  updateFilters,
  onInputBlur,
  validationError,
}: Props) => {
  return (
    <RepeatServiceScheduler
      dayOfWeekLabel={t`For which days?`}
      direction="row"
      language={language}
      maxDate={MAX_REQUEST_DATE}
      onSelectedDaysChange={(selectedDays: Day[]) => updateFilters({ selectedDays })}
      onStartDateChange={(startDate) => updateFilters({ startDate })}
      repeatStartDateLabel={startDatePlaceholder}
      selectedDays={filters.selectedDays || []}
      serviceTypeSlug={filters.serviceTypeSlug}
      showLabel
      startDate={filters.startDate}
      startPlaceholder={startDatePlaceholder}
      onInputBlur={onInputBlur}
      validationError={validationError}
    />
  );
};

export default RepeatServiceSchedulerConnector;
