import React from 'react';
import { Trans } from '@lingui/macro';

import { TrainingPilot } from '@rover/icons';
import { Box, Button, Flex, Heading, Link, Text } from '@rover/kibble/core';
import CalloutBadge from '@rover/react-lib/src/components/callouts/CalloutBadge';
import Card from '@rover/react-lib/src/components/cards/Card';
import TrainingUrls from '@rover/react-lib/src/pages/training/constants/urls';
import useInTrainingPilot from '@rover/react-lib/src/pages/training/hooks/useInTrainingPilot';
import { ServiceLinksByCountry } from '@rover/react-lib/src/redux/ducks/serviceLinks.duck';
import { ServiceLandingUrlSlugEnum } from '@rover/rsdk/src/apiClient/latest';

import emitMetricOnCtaClick from '../emitMetricOnCtaClick';

type Props = {
  hasDogTraining: boolean;
  serviceLinksByCountry: ServiceLinksByCountry | null;
  countryCode: string;
};

const DogTrainingPilotCard = ({
  hasDogTraining,
  serviceLinksByCountry,
  countryCode,
}: Props): JSX.Element | null => {
  const { shouldShowTrainingEntrypoints } = useInTrainingPilot();

  if (
    !hasDogTraining ||
    !serviceLinksByCountry?.[countryCode.toLowerCase()]?.[ServiceLandingUrlSlugEnum['dog-training']]
  )
    return null;

  const url = shouldShowTrainingEntrypoints
    ? TrainingUrls.SEARCH
    : `${
        serviceLinksByCountry[countryCode.toLowerCase()][ServiceLandingUrlSlugEnum['dog-training']]
      }?tags=desktop-homepage`;

  return (
    <Card display={['none', 'block']}>
      <Flex alignItems="center">
        <TrainingPilot />
        <Flex mx="4x" flexDirection="column">
          <Heading as="div" m="0x" display="flex" size="200">
            <Trans>Dog Training</Trans>
            {shouldShowTrainingEntrypoints && (
              <Box ml="2x">
                <CalloutBadge value="NEW" />
              </Box>
            )}
          </Heading>
          <Text textColor="tertiary">
            {shouldShowTrainingEntrypoints ? (
              // DEV-104195 TODO: translate
              'With a private trainer'
            ) : (
              <Trans>
                1-1 virtual dog training through GoodPup, the newest member of the Rover family.
              </Trans>
            )}
          </Text>
        </Flex>
        <Flex justifyContent="end" flexShrink="0" flexGrow="1">
          <Box
            onClick={() => {
              emitMetricOnCtaClick('training-homepage-cta-click');
            }}
          >
            {shouldShowTrainingEntrypoints ? (
              <Button href={url}>Find a trainer</Button>
            ) : (
              <Link target="_blank" href={url} py="2x" px="4x" fontWeight="semibold">
                <Trans>Start your free trial</Trans>
              </Link>
            )}
          </Box>
        </Flex>
      </Flex>
    </Card>
  );
};

export default DogTrainingPilotCard;
