import React from 'react';
import { t, Trans } from '@lingui/macro';

import { Close, RPawLogo } from '@rover/icons';
import { Button, Flex, Heading } from '@rover/kibble/core';
import { useI18n } from '@rover/rsdk/src/modules/I18n';

export type Props = {
  id?: string;
  onClose?: () => void;
};

const BottomSheetSurveyHeader = ({ id, onClose }: Props): JSX.Element => {
  const { i18n } = useI18n();

  return (
    <Flex alignItems="center" justifyContent="space-between" mb="4x" height="6x">
      <Flex alignItems="center">
        <RPawLogo />
        <Heading
          id={id}
          as="h2"
          size="100"
          ml="2x"
          mb="0x"
          textColor="secondary"
          fontWeight="semibold"
        >
          <Trans>Rover Surveys</Trans>
        </Heading>
      </Flex>
      <Button
        icon={Close}
        circular
        onClick={onClose}
        aria-label={i18n._(t`Close`)}
        size="large"
        p="0x"
        sx={{ boxShadow: 'none' }}
      />
    </Flex>
  );
};

export default BottomSheetSurveyHeader;
