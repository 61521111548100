import { StringSystemType } from '@rover/kibble/core/styledSystemPropTypes';
import { DSTokenMap, ThemeBackgroundColors } from '@rover/kibble/styles';

type CardStyleProps = {
  background?: ThemeBackgroundColors;
  border: StringSystemType;
  color?: string;
};

export type CardStyleOptions = 'default' | 'inset' | 'ledger';

export const CardStyle: Record<CardStyleOptions, CardStyleProps> = {
  default: {
    background: 'primary',
    border: `1px solid ${DSTokenMap.BORDER_COLOR_PRIMARY.toString()}`,
  },
  inset: {
    background: 'secondary',
    border: '0 none',
  },
  ledger: {
    background: 'secondary',
    border: `1px solid ${DSTokenMap.BORDER_COLOR_PRIMARY.toString()}`,
    color: DSTokenMap.TEXT_COLOR_SECONDARY.toString(),
  },
};

export default CardStyle;
