import React from 'react';
import styled from 'styled-components';

import { Box, Display, Flex, Heading, Image, Text } from '@rover/kibble/core';
import { Props as BoxProps } from '@rover/kibble/core/Box';
import { Props as DisplayProps } from '@rover/kibble/core/Display';
import { Props as FlexProps } from '@rover/kibble/core/Flex';
import type { Props as HeadingProps } from '@rover/kibble/core/Heading';

import calendar from './images/calendar.svg?url';
import chat from './images/chat.svg?url';
import collar from './images/collar.webp';
import leash from './images/leash.svg?url';
import magnifyingGlass from './images/magnifyingGlass.webp';
import paws from './images/paws.webp';
import phone from './images/phone.webp';

export const ImageOptions = {
  COLLAR: collar,
  MAGNIFYING_GLASS: magnifyingGlass,
  PAWS: paws,
  PHONE: phone,
  CALENDAR: calendar,
  CHAT: chat,
  LEASH: leash,
};

const ListItemBox = styled(Box)`
  box-shadow: 0px 2px 12px -1px #1b1f233d;
`;

export type ListItemProps = BoxProps & {
  image: string;
  ariaLabel?: string;
};

const HowRoverWorks = (props: BoxProps): JSX.Element => {
  return <Box mx={['4x', '0x']} my="12x" data-qa-id="service-landing-how-rover-works" {...props} />;
};

HowRoverWorks.Display = (props: DisplayProps): JSX.Element => {
  return (
    <Display
      size="500"
      as="h3"
      textAlign="center"
      mb={['0x', '6x']}
      responsive={false}
      {...props}
    />
  );
};

HowRoverWorks.List = (props: FlexProps): JSX.Element => {
  return (
    <Flex justifyContent="center">
      <Flex as="ol" pl="0x" mt="6x" flexDirection={['column', 'row']} {...props}>
        {props.children}
      </Flex>
    </Flex>
  );
};

HowRoverWorks.ListItem = (props: ListItemProps): JSX.Element => {
  return (
    <ListItemBox
      as="li"
      borderRadius="4px"
      sx={{ listStyleType: 'none' }}
      textAlign={['left', 'center']}
      flex={['1 1 auto', '1 1 33.3333%']}
      background="accent"
      my={['8x', '0x']}
      mx={['0x', '3x']}
      maxWidth="320px"
      {...props}
    >
      <Flex alignItems="flex-start" px="5x" pb="5x" flexDirection="column">
        <Flex
          width="100%"
          height={['75px', '75px', '100px']}
          position="relative"
          top="-20px"
          justifyContent="center"
          {...props}
        >
          <Image
            src={props.image}
            aria-label={props.ariaLabel}
            width="auto"
            height="100%"
            description=""
          />
        </Flex>
        <Box mt={['0x', '0x', '4x']} textAlign="center" width="100%">
          {props.children}
        </Box>
      </Flex>
    </ListItemBox>
  );
};

HowRoverWorks.ItemHeading = (props: HeadingProps & { step: number }): JSX.Element => (
  <Heading size="300" mb={['4x', '1x']}>
    {props.step}. {props.children}
  </Heading>
);

HowRoverWorks.ItemContent = Text;

export default HowRoverWorks;
