import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';

import { Box, Button, Flex } from '@rover/kibble/core';
import { DSTokenMap } from '@rover/kibble/styles';
import { useA11yContext } from '@rover/react-lib/src/context/A11yContext';
import { ServiceSelectorV2 } from '@rover/react-lib/src/pages/contact-sitter/ContactSitterPage/components/ContactSitterForm/components/ServiceScheduler/components/ServiceSelector';
import {
  DAY_CARE_PILOT_SERVICE_OPTION,
  SERVICE_SELECTION_OPTIONS,
  TRAINING_PILOT_SERVICE_OPTION,
} from '@rover/react-lib/src/pages/contact-sitter/ContactSitterPage/components/ContactSitterForm/components/ServiceScheduler/components/ServiceSelector/constants';
import emitMetricOnCtaClick from '@rover/react-lib/src/pages/home/emitMetricOnCtaClick';
import NarrowDeviceSearchBoxPetSelector from '@rover/react-lib/src/pages/index/HomePage/components/IntuitiveOwnerWizard/NarrowDeviceSearchBoxPetSelector';
import HomePageSearchBoxLocationInput from '@rover/react-lib/src/pages/index/HomePage/components/SearchBox/connectors/LocationInputConnector';
import type {
  PetCounterData,
  UpdateFiltersFunc,
} from '@rover/react-lib/src/pages/index/HomePage/components/SearchBox/UpdateFiltersFunc';
import {
  DaycareTypeFilter,
  SpacesRequired,
} from '@rover/react-lib/src/pages/search/SearchPage/components/Filters';
import SimplifiedMobileOwnerSearchFormScheduler from '@rover/react-lib/src/pages/search/SearchPage/components/Filters/Dates/SimplifiedMobileOwnerSearchFormScheduler';
import { shouldShowDateRange } from '@rover/react-lib/src/pages/search/SearchPage/utilities/shouldShowControls';
import TrainingUrls from '@rover/react-lib/src/pages/training/constants/urls';
import useInTrainingPilot from '@rover/react-lib/src/pages/training/hooks/useInTrainingPilot';
import { ServiceLinksByCountry } from '@rover/react-lib/src/redux/ducks/serviceLinks.duck';
import { ServiceLandingUrlSlugEnum } from '@rover/rsdk/src/apiClient/latest';
import { emitAnalyticsEvent } from '@rover/rsdk/src/modules/Analytics';
import { useI18n } from '@rover/rsdk/src/modules/I18n';
import { SERVICE_TYPE_CHOICES } from '@rover/shared/js/constants/service';
import {
  MobileWebHomePageServiceTypeChange,
  MobileWebHomePageServiceTypeDropdownClick,
} from '@rover/shared/js/search/utilities/analyticsEvents';
import getIsCatOnlyPetType from '@rover/shared/js/search/utilities/getIsCatOnlyPetType';
import type { Pet, ServiceType, ServiceTypeOption } from '@rover/types';
import { HomepageSearchFilters } from '@rover/types/src/HomepageSearchFilters';
import { PetType } from '@rover/types/src/Pet';

import BoardingTypeFilter from '../../../../search/SearchPage/components/Filters/BoardingTypeFilter';

import { CalendarClick } from './analyticsEvents';

export interface NarrowDeviceSearchBoxProps {
  isAuthenticated: boolean;
  pets: Pet[];
  filters: HomepageSearchFilters;
  serviceType: ServiceType | undefined;
  serviceTypeOptions: ServiceTypeOption[];
  countryHasDogTraining: boolean;
  isDaycareFilterEarlierExperiment: boolean;
  isDaycareFilterEarlierExperimentRequired: boolean;
  isDaycareFilterOutsideMarketsInExperiment: boolean;
  isBoardingFilterOutsideMarketsInExperiment: boolean;
  hasFilterError: boolean;
  serviceLinksByCountry: ServiceLinksByCountry | null;
  countryCode?: string;
  updateFilters: UpdateFiltersFunc;
  submit: () => void;
  currentUserPostalCode?: string;
  rolloutPriceTransparency?: boolean;
}

const NarrowDeviceSearchBox: FC<NarrowDeviceSearchBoxProps> = ({
  isAuthenticated,
  pets,
  serviceType,
  serviceTypeOptions,
  filters,
  isDaycareFilterEarlierExperiment,
  isDaycareFilterEarlierExperimentRequired,
  isDaycareFilterOutsideMarketsInExperiment,
  isBoardingFilterOutsideMarketsInExperiment,
  hasFilterError,
  countryHasDogTraining,
  serviceLinksByCountry,
  countryCode,
  updateFilters,
  submit,
  currentUserPostalCode,
}) => {
  const [isDropInOrWalking, setIsDropInOrWalking] = useState(false);
  const [isDogTraining, setIsDogTraining] = useState(false);
  const [petCounterData, setPetCounterData] = useState<PetCounterData>(() => ({
    counter: filters?.petType?.length ?? 1,
    dogCount: filters?.petType?.includes(PetType.DOG) ? 1 : 0,
    catCount: filters?.petType?.includes(PetType.CAT) ? 1 : 0,
  }));
  const [serviceRecurrenceCount, setSrviceRecurrenceCount] = useState(1);

  const { shouldShowTrainingEntrypoints } = useInTrainingPilot();

  const { i18n, locale } = useI18n();
  const showDateRange = shouldShowDateRange({ serviceType });
  const { setScreenReaderAnnouncement } = useA11yContext();

  const eventProperties = {
    loggedIn: isAuthenticated,
    serviceType: serviceType?.slug,
    postalCode: currentUserPostalCode,
    dates: { startDate: filters.startDate, endDate: filters.endDate },
  };

  useEffect(() => {
    setIsDropInOrWalking(
      serviceType?.slug === SERVICE_TYPE_CHOICES.dropIn ||
        serviceType?.slug === SERVICE_TYPE_CHOICES.walking
    );
    setIsDogTraining(serviceType?.slug === SERVICE_TYPE_CHOICES.training && countryHasDogTraining);
  }, [serviceType?.slug, countryHasDogTraining]);

  useEffect(() => {
    const newSpacesRequired = isDropInOrWalking ? serviceRecurrenceCount : petCounterData.counter;

    if (newSpacesRequired !== filters?.spacesRequired) {
      updateFilters({ spacesRequired: newSpacesRequired });
    }
  }, [filters, isDropInOrWalking, serviceRecurrenceCount, petCounterData.counter, updateFilters]);

  const dogTrainingUrl = useMemo((): string => {
    if (shouldShowTrainingEntrypoints) {
      return TrainingUrls.SEARCH;
    }
    return serviceLinksByCountry && countryCode && countryHasDogTraining
      ? `${
          serviceLinksByCountry?.[countryCode?.toLowerCase()]?.[
            ServiceLandingUrlSlugEnum['dog-training']
          ] || '/dog-training/'
        }?tags=mobile-homepage`
      : '';
  }, [serviceLinksByCountry, countryHasDogTraining, countryCode, shouldShowTrainingEntrypoints]);
  const isDaycareFilterRequired =
    isDaycareFilterEarlierExperimentRequired || isDaycareFilterOutsideMarketsInExperiment;

  const handleChange = useCallback(
    (newFilters: Partial<HomepageSearchFilters>, newPetCounterData?: PetCounterData): void => {
      const updates = { ...newFilters };

      if (!isDropInOrWalking && newPetCounterData?.counter) {
        updates.spacesRequired = newPetCounterData.counter;
      }

      // this enables us to set the daycare type filter to have a default value of 'both' initially
      // if the experiment is required, we keep the default values as undefined so that the user has to make a choice
      if (
        newFilters.serviceTypeSlug === SERVICE_TYPE_CHOICES.dayCare &&
        isDaycareFilterEarlierExperiment &&
        !isDaycareFilterRequired
      ) {
        updates.inSittersHome = true;
        updates.atDaycareFacility = true;
      }
      // Call updateFilters before updating counters to prevent redundant updateFilters call triggered by counter changes in the useEffect hook.
      updateFilters(updates);

      if (newPetCounterData) {
        setPetCounterData(newPetCounterData);
      } else if (newFilters.spacesRequired) {
        setSrviceRecurrenceCount(newFilters.spacesRequired);
      }
    },
    [filters.serviceTypeSlug, isDropInOrWalking, updateFilters]
  );

  const handleDateRangeChange = useCallback(
    (frequency) => handleChange({ frequency }),
    [handleChange]
  );

  const handleSelectedDaysChange = useCallback(
    (selectedDays): void => {
      handleChange({ selectedDays });
    },
    [handleChange]
  );

  const handleChangeDateRange = useCallback(
    (dateRange): void => {
      if (dateRange) {
        handleChange({
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
          dateRange: {
            startDate: dateRange.startDate,
            endDate: dateRange.endDate,
          },
        });
      }
    },
    [handleChange]
  );

  const serviceTypes = useMemo((): ServiceTypeOption[] => {
    const hasMatchingServiceType = (option): boolean =>
      serviceTypeOptions.some((service) => service.value === option.value);

    return SERVICE_SELECTION_OPTIONS.filter((option) =>
      countryHasDogTraining
        ? hasMatchingServiceType(option)
        : hasMatchingServiceType(option) && option.value !== SERVICE_TYPE_CHOICES.training
    ).map((option) => {
      if (option.value === SERVICE_TYPE_CHOICES.training && shouldShowTrainingEntrypoints) {
        return TRAINING_PILOT_SERVICE_OPTION;
      }
      // For pilot cities, override the Day Care service type to override the option text. (DEV-117678)
      if (option.value === SERVICE_TYPE_CHOICES.dayCare && isDaycareFilterEarlierExperiment) {
        return DAY_CARE_PILOT_SERVICE_OPTION;
      }
      return option;
    });
  }, [serviceTypeOptions, countryHasDogTraining, shouldShowTrainingEntrypoints]);

  const handleSubmit = useCallback(() => {
    setScreenReaderAnnouncement(i18n._(t`Form submitted. Navigating to Search page.`));
    handleChange({ bypassInterstitial: true });
    submit();
  }, [setScreenReaderAnnouncement, handleChange, submit]);

  return (
    <Flex
      data-testid="narrow-device-search-box"
      flexDirection="column"
      background="primary"
      paddingY="6x"
      paddingX="4x"
      marginX="4x"
      variant="elevation_low"
      borderRadius={DSTokenMap.BORDER_RADIUS_PRIMARY.toString()}
    >
      {!isDogTraining && (
        <Box mb="6x">
          <NarrowDeviceSearchBoxPetSelector
            isAuthenticated={isAuthenticated}
            pets={pets}
            filters={filters}
            serviceType={serviceType}
            updateFilters={handleChange}
            dogCount={petCounterData.dogCount}
            catCount={petCounterData.catCount}
          />
        </Box>
      )}
      {serviceTypeOptions.length > 0 && (
        <Box mb="6x">
          <ServiceSelectorV2
            label={i18n._(t`Which service do you need?`)}
            inputFontSize={DSTokenMap.TEXT_200_FONT_SIZE}
            serviceTypeOptions={serviceTypes}
            serviceType={filters.serviceTypeSlug}
            onOpen={() =>
              emitAnalyticsEvent(
                new MobileWebHomePageServiceTypeDropdownClick({
                  serviceType: filters.serviceTypeSlug,
                })
              )
            }
            onChange={(serviceTypeSlug) => {
              if (filters.serviceTypeSlug !== serviceTypeSlug) {
                emitAnalyticsEvent(
                  new MobileWebHomePageServiceTypeChange({
                    serviceType: serviceTypeSlug,
                  })
                );
              }
              handleChange({ serviceTypeSlug });
            }}
          />
        </Box>
      )}
      {isDogTraining ? (
        <Button
          data-testid="search-box-dog-training-redirect"
          size="small"
          fullWidth
          variant="primary"
          href={dogTrainingUrl}
          target="_blank"
          onClick={() => emitMetricOnCtaClick('training-homepage-cta-click')}
        >
          {shouldShowTrainingEntrypoints ? i18n._(t`Search`) : i18n._(t`Start a free trial`)}
        </Button>
      ) : (
        <>
          {filters.serviceTypeSlug === SERVICE_TYPE_CHOICES.boarding &&
            isBoardingFilterOutsideMarketsInExperiment &&
            !getIsCatOnlyPetType(filters.petType) && (
              <BoardingTypeFilter
                inSittersHome={filters.inSittersHome}
                atDaycareFacility={filters.atDaycareFacility}
                onChange={(newInSittersHome, newAtDaycareFacility) => {
                  handleChange({
                    inSittersHome: newInSittersHome,
                    atDaycareFacility: newAtDaycareFacility,
                  });
                }}
                defaultSetting={false}
                required
                error={hasFilterError}
                placement="homepage-search"
              />
            )}
          {filters.serviceTypeSlug === SERVICE_TYPE_CHOICES.dayCare &&
            (isDaycareFilterEarlierExperiment || isDaycareFilterOutsideMarketsInExperiment) && (
              <DaycareTypeFilter
                title={
                  isDaycareFilterRequired
                    ? i18n._(t`Show day care options (required):`)
                    : i18n._(t`Show day care options:`)
                }
                onChange={(newInSittersHome, newAtDaycareFacility) => {
                  handleChange({
                    inSittersHome: newInSittersHome,
                    atDaycareFacility: newAtDaycareFacility,
                  });
                }}
                inSittersHome={filters.inSittersHome}
                atDaycareFacility={filters.atDaycareFacility}
                defaultSetting={!isDaycareFilterRequired}
                required={isDaycareFilterRequired}
                error={hasFilterError}
                placement="homepage-search"
              />
            )}
          <Box mb="6x">
            <HomePageSearchBoxLocationInput
              updateFilters={handleChange}
              serviceType={serviceType}
              inputHeight={DSTokenMap.SPACE_12X}
              inputFontSize={DSTokenMap.TEXT_200_FONT_SIZE}
              placeholder={i18n._(t`Add your address`)}
              label={i18n._(t`What's your address?`)}
              labelMb={DSTokenMap.SPACE_1X.toString()}
              labelColor={DSTokenMap.TEXT_COLOR_PRIMARY.toString()}
              {...(filters.location ? { inputValue: filters.location } : {})}
            />
          </Box>

          {isDropInOrWalking && (
            <Box mb="6x">
              <SpacesRequired
                searchFilters={{
                  serviceType: filters.serviceTypeSlug,
                  spacesRequired: serviceRecurrenceCount,
                }}
                onChange={(newSpacesRequired) =>
                  handleChange({ spacesRequired: newSpacesRequired })
                }
                mb="4px"
              />
            </Box>
          )}
          <Box mb="6x">
            <SimplifiedMobileOwnerSearchFormScheduler
              language={locale}
              onChange={handleDateRangeChange}
              onDateRangeChange={handleChangeDateRange}
              onSelectedDaysChange={handleSelectedDaysChange}
              onStartDateChange={handleChangeDateRange}
              filters={filters}
              serviceType={serviceType}
              showDateRange={showDateRange}
              emitEvent={() => emitAnalyticsEvent(new CalendarClick(eventProperties))}
              showSubTitle={false}
              bottomPadding="0x"
            />
          </Box>
          <Button
            data-testid="search-box-submit"
            size="small"
            fullWidth
            variant="primary"
            onClick={handleSubmit}
          >
            <Trans>Search</Trans>
          </Button>
        </>
      )}
    </Flex>
  );
};

export default NarrowDeviceSearchBox;
