import React, { FC, useMemo } from 'react';
import { t, Trans } from '@lingui/macro';

import dataLayer from '@rover/globals/dataLayer';
import { Box, Flex, Image, Link, Text } from '@rover/kibble/core';
import { SpacingSystemType } from '@rover/kibble/core/styledSystemPropTypes';
import { Color } from '@rover/kibble/styles';
import { TextSizes } from '@rover/kibble/tokens/build/es6/theme';
import { useI18n } from '@rover/rsdk/src/modules/I18n';

import TrustPilotLogoDark from './images/trustpilot_logo_dark.svg?url';
import TrustPilotLogoLight from './images/trustpilot_logo_light.svg?url';
import TrustPilotStarsImage from './images/trustpilot_stars.svg?url';
import getTrustpilotUrl from './getTrustPilotUrl';

const REVIEW_COUNT = 24000;
const STARS_IMAGE_HEIGHTS = ['12px', '17px', '24px'];
const LOGO_HEIGHTS = ['20px', '25px', '32px'];
const LOGO_MARGIN_BOTTOM: SpacingSystemType = ['1x', '2x', '2x'];
const TEXT_SIZES: TextSizes[] = ['50', '100', '300'];

export enum SizeOverride {
  small,
  medium,
  large,
}

export type TrustPilotProps = React.ComponentProps<typeof Box> & {
  languageCode?: string;
  sizeOverride?: SizeOverride;
  hideOnMobileView?: boolean;
  inverse?: boolean;
};

const trustPilotClickHandler = (): void => {
  dataLayer.push({
    event: 'trustpilot-widget-click',
  });
};

const Trustpilot: FC<TrustPilotProps> = ({
  languageCode,
  hideOnMobileView,
  sizeOverride,
  inverse = false, // When true, the text is a light color with a dark opaque background. When false, the text is a dark color with no background
  ...boxProps
}) => {
  const { i18n, locale } = useI18n();
  const trustpilotUrl = useMemo(() => getTrustpilotUrl(languageCode), [languageCode]);
  const display = hideOnMobileView ? ['none', 'flex'] : 'flex';
  const formattedTrustpilotReviewCount = REVIEW_COUNT.toLocaleString(locale);

  return (
    <Box
      display={display}
      width="auto"
      data-testid="trustpilot-widget-component"
      mt="4x"
      mb="2x"
      px="4x"
      py="2x"
      borderRadius="3px"
      sx={inverse ? { backgroundColor: Color.NEUTRAL_900.withOpacity(0.5) } : {}}
      {...boxProps}
    >
      <Link
        href={trustpilotUrl.toString()}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={i18n._(t`External link to Trustpilot page for Rover.com`)}
        underline={false}
        onClick={trustPilotClickHandler}
        variant={inverse ? 'secondaryContrast' : 'secondary'}
      >
        <Flex justifyContent="center" flexWrap="wrap" alignItems="center" gap="2x">
          <Flex
            height={
              sizeOverride !== undefined ? STARS_IMAGE_HEIGHTS[sizeOverride] : STARS_IMAGE_HEIGHTS
            }
            alignItems="center"
          >
            <Image
              src={TrustPilotStarsImage}
              description="Trustpilot star ratings"
              eager
              width="auto"
              height="100%"
            />
          </Flex>

          <Text
            fontWeight="semibold"
            textAlign="center"
            size={sizeOverride !== undefined ? TEXT_SIZES[sizeOverride] : TEXT_SIZES}
            textColor={inverse ? 'secondary_inverse' : 'secondary'}
            sx={{
              whiteSpace: 'nowrap',
              flexBasis: 'max-content',
            }}
          >
            <Trans>{formattedTrustpilotReviewCount}+ reviews on</Trans>
          </Text>
          <Flex
            mb={sizeOverride !== undefined ? LOGO_MARGIN_BOTTOM[sizeOverride] : LOGO_MARGIN_BOTTOM}
            height={sizeOverride !== undefined ? LOGO_HEIGHTS[sizeOverride] : LOGO_HEIGHTS}
            alignItems="center"
          >
            <Image
              src={inverse ? TrustPilotLogoLight : TrustPilotLogoDark}
              description="Trustpilot logo"
              eager
              width="auto"
              height="100%"
            />
          </Flex>
        </Flex>
      </Link>
    </Box>
  );
};

export default Trustpilot;
