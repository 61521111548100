import { Component } from 'react';

// code from https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/docs/guides/scroll-restoration.md

export default class ScrollToTopOnMount extends Component {
  componentDidMount(): void {
    window.scrollTo(0, 0);
  }

  render(): null {
    return null;
  }
}
