import React from 'react';
import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import styled from 'styled-components';

import { CheckSmall } from '@rover/icons';
import { Button, Flex, Heading } from '@rover/kibble/core';
import { Color, DSTokenMap, MQ, Spacing } from '@rover/kibble/styles';
import Card from '@rover/react-lib/src/components/cards/Card';
import BulletedList, { StyledListItem } from '@rover/react-lib/src/components/lists/BulletedList';
import { Connect } from '@rover/react-lib/src/components/utils/Redux';
import trustLogoSource from '@rover/react-lib/src/images/homepage/trust-logo@2x.webp';
import { selectors as countryConfigurationSelectors } from '@rover/react-lib/src/redux/ducks/countryConfiguration.duck';
import { selectors as frontendConfigurationSelectors } from '@rover/react-lib/src/redux/ducks/frontendConfiguration.duck';
import { selectors as serviceLinksSelectors } from '@rover/react-lib/src/redux/ducks/serviceLinks.duck';
import type { CountryConfiguration } from '@rover/rsdk/src/apiClient/latest';

import { selectors as homePageSelectors } from '../HomePage.duck';

type Props = {
  countryConfiguration: CountryConfiguration | null | undefined;
};

const Wrapper = styled.div`
  ${MQ.XS_DOWN.toString()} {
    margin-top: ${Spacing.XL.toString()};
  }
`;

const TrustLogo = styled.div`
  background-image: url(${trustLogoSource});
  background-repeat: no-repeat;
  background-size: contain;
  height: 28px;
  margin-right: ${Spacing.M.toString()};
  position: relative;
  vertical-align: middle;
  width: 28px;
`;

const StyledBulletedList = styled(BulletedList)`
  color: ${DSTokenMap.TEXT_COLOR_SECONDARY.toString()};
  margin-top: 0;
  ${StyledListItem} {
    &:not(:first-child) {
      margin-top: 5px;
    }
  }

  svg {
    fill: ${Color.GREEN_600.toString()};
  }
`;

const backgroundCheckListItem = {
  IconClass: CheckSmall,
  color: Color.GREEN_600,
  content: t`All new sitters pass a basic background check`,
  hasBorder: false,
};

const brand2BackgroundCheckListItem = {
  ...backgroundCheckListItem,
  content: t`All sitters pass a background check`,
};

const identityVerificationListItem = {
  IconClass: CheckSmall,
  color: Color.GREEN_600,
  content: t`All new sitters pass identity verification`,
  hasBorder: false,
};

const detailedProfileListItem = {
  IconClass: CheckSmall,
  color: Color.GREEN_600,
  content: t`All sitters provide a detailed profile and personal information`,
  hasBorder: false,
};

const sittersApprovalListItem = {
  IconClass: CheckSmall,
  color: Color.GREEN_600,
  content: t`All sitters are approved by our team of sitter specialists`,
  hasBorder: false,
};

const nonEuBrand2ListItems = [
  brand2BackgroundCheckListItem,
  detailedProfileListItem,
  sittersApprovalListItem,
];
const euListItems = [
  identityVerificationListItem,
  detailedProfileListItem,
  sittersApprovalListItem,
];

const TrustAndSafetyCard = ({ countryConfiguration }: Props): JSX.Element => {
  const useI18nText = countryConfiguration && countryConfiguration.shouldUseI18nTemplatesPostLaunch;
  const getListItmes = () => {
    if (useI18nText) {
      return euListItems;
    }
    return nonEuBrand2ListItems;
  };

  return (
    <Wrapper>
      <Card isStacked cardStyle="ledger" padding="5x">
        <Flex justifyContent="center" alignItems="center">
          <TrustLogo />
          <Heading fontWeight="regular" size="200" marginBottom="0x">
            <Trans>
              Book with pet sitters you can <strong>trust</strong>
            </Trans>
          </Heading>
        </Flex>
      </Card>
      <Card isStacked padding="5x">
        <StyledBulletedList items={getListItmes()} />
        <Button fullWidth href="/search/" mt="6x" variant="primary">
          <Trans>Book a local sitter</Trans>
        </Button>
      </Card>
    </Wrapper>
  );
};

const selector = (state) => ({
  countryConfiguration: countryConfigurationSelectors.getNavigationCountryConfiguration(state),
  roverGuaranteeLink: countryConfigurationSelectors.getRoverGuaranteeUrl(state),
  localizedHelpLink: homePageSelectors.getLocalizedHelpLink(state),
  cxOperatingHours: countryConfigurationSelectors.getCxOperatingHours(state),
  serviceLinksByCountry: serviceLinksSelectors.getServiceLinksByCountry(state),
  countryCode: frontendConfigurationSelectors.getSettings(state).countryCode,
});

const ConnectedTrustAndSafetyCard = (): JSX.Element => (
  <Connect selector={selector}>{(redux) => <TrustAndSafetyCard {...redux} />}</Connect>
);

export default ConnectedTrustAndSafetyCard;
