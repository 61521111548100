import { t } from '@lingui/macro';

import { Boarding, Daycare, DogTraining, DropIn, Sitting, Walking } from '@rover/icons';
import { SERVICE_TYPE_CHOICES } from '@rover/shared/js/constants/service';
import { ServiceTypeOption } from '@rover/types';

export const SERVICE_SELECTION_OPTIONS: ServiceTypeOption[] = [
  {
    title: t`Boarding`,
    subtitle: t`in the sitter's home`,
    icon: Boarding,
    value: SERVICE_TYPE_CHOICES.boarding,
  },
  {
    title: t`House Sitting`,
    subtitle: t`in your home`,
    icon: Sitting,
    value: SERVICE_TYPE_CHOICES.traveling,
  },
  {
    title: t`Drop-In Visits`,
    subtitle: t`visits in your home`,
    icon: DropIn,
    value: SERVICE_TYPE_CHOICES.dropIn,
  },
  {
    title: t`Doggy Day Care`,
    subtitle: t`in the sitter's home`,
    icon: Daycare,
    value: SERVICE_TYPE_CHOICES.dayCare,
  },
  {
    title: t`Dog Walking`,
    subtitle: t`in your neighborhood`,
    icon: Walking,
    value: SERVICE_TYPE_CHOICES.walking,
  },
  {
    title: t`Dog Training`,
    subtitle: t`1-1 virtual sessions through GoodPup`,
    icon: DogTraining,
    value: SERVICE_TYPE_CHOICES.training,
  },
];

// For pilot areas, we want to override the Doggy Day Care option text. (DEV-117678)
export const DAY_CARE_PILOT_SERVICE_OPTION: ServiceTypeOption = {
  title: 'Doggy Day Care',
  subtitle: "in a sitter's home or day care center",
  icon: Daycare,
  value: SERVICE_TYPE_CHOICES.dayCare,
};

export const TRAINING_PILOT_SERVICE_OPTION: ServiceTypeOption = {
  title: 'Dog Training',
  // DEV-104195 TODO: translate
  subtitle: 'With a private trainer',
  value: SERVICE_TYPE_CHOICES.training,
  icon: DogTraining,
};
