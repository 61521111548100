import React, { FC, useId } from 'react';
import styled from 'styled-components';

import Box from '@rover/kibble/core/Box';
import Flex from '@rover/kibble/core/Flex';
import { A11yHidden, DSTokenMap } from '@rover/kibble/styles';
import {
  IconWrapper,
  SELECT_BUTTON_IS_SELECTED_CLASS,
  SelectButtonLabel,
} from '@rover/react-lib/src/components/buttons/SelectButton/SelectButton';
import ServiceTypeFilter from '@rover/react-lib/src/components/formFields/ServiceTypeFilter';
import Label from '@rover/react-lib/src/components/typography/Label';
import { useI18n } from '@rover/rsdk/src/modules/I18n';
import { ServiceTypeOption } from '@rover/types';
import type { HomepageSearchFilters } from '@rover/types/src/HomepageSearchFilters';

import type { UpdateFiltersFunc } from '../UpdateFiltersFunc';

// This is a dupe of WHILE_YOURE_AT_WORK_SERVICE_TYPES from aplaceforrover/services/constants.py
// Removing the duplication will involve adding this info to the service types API
const WHILE_YOURE_AT_WORK_SERVICE_TYPE_SLUGS = ['doggy-day-care', 'dog-walking'];

const StyledServiceTypeFilter = styled(ServiceTypeFilter)`
  min-height: 64px;
  margin-bottom: 12px;

  ${IconWrapper},
  svg {
    min-width: unset;
    max-width: unset;
    min-height: unset;
    max-height: unset;
    margin-bottom: 0;
    height: 20px;
    width: 20px;
  }

  svg {
    fill: ${DSTokenMap.TEXT_COLOR_TERTIARY.toString()};
  }

  ${SelectButtonLabel} {
    padding: 8px;

    &:hover {
      svg {
        fill: ${DSTokenMap.TEXT_COLOR_SECONDARY.toString()};
      }
    }
    &.${SELECT_BUTTON_IS_SELECTED_CLASS} {
      color: ${DSTokenMap.TEXT_COLOR_PRIMARY.toString()};
      svg {
        fill: ${DSTokenMap.TEXT_COLOR_PRIMARY.toString()};
      }
    }
  }
`;

const A11yHiddenLabel = styled.label`
  ${A11yHidden};
`;

export interface Props {
  filters: HomepageSearchFilters;
  serviceTypeOptions: ServiceTypeOption[];
  updateFilters: UpdateFiltersFunc;
}

const ServiceTypeConnector: FC<Props> = ({ filters, serviceTypeOptions, updateFilters }: Props) => {
  const { i18n } = useI18n();
  const fieldId = useId();

  const whenAwayOpts = serviceTypeOptions.filter(
    (i) => !WHILE_YOURE_AT_WORK_SERVICE_TYPE_SLUGS.includes(i.value)
  );
  const whenAtWorkOpts = serviceTypeOptions.filter((i) =>
    WHILE_YOURE_AT_WORK_SERVICE_TYPE_SLUGS.includes(i.value)
  );

  return (
    <Flex width="100%" flexDirection="column">
      <Flex>
        <Box flex={whenAwayOpts.length.toString()}>
          <Label aria-hidden="true">{i18n._("For When You're Away")}</Label>
        </Box>
        {whenAtWorkOpts.length ? (
          <Box flex={whenAtWorkOpts.length.toString()} ml="6x">
            <Label aria-hidden="true">{i18n._("For When You're At Work")}</Label>
          </Box>
        ) : null}
      </Flex>
      <Box>
        <A11yHiddenLabel id={fieldId}>{i18n._('Service type')}</A11yHiddenLabel>
        <StyledServiceTypeFilter
          displayMode="horizontal"
          serviceTypeOptions={serviceTypeOptions}
          serviceTypeFilterId={fieldId}
          searchFilters={{ serviceType: filters.serviceTypeSlug }}
          onChange={({ serviceType: serviceTypeSlug }) => updateFilters({ serviceTypeSlug })}
        />
      </Box>
    </Flex>
  );
};

export default ServiceTypeConnector;
