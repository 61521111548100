import React from 'react';
import { Trans } from '@lingui/macro';

import { Link } from '@rover/kibble/core';
import HowRoverWorks, { ImageOptions } from '@rover/react-lib/src/components/HowRoverWorks';
import MarketingOptIn from '@rover/react-lib/src/components/modals/MarketingOptIn';
import { RoverProtectSurveyDelay } from '@rover/react-lib/src/components/RoverProtectSignals/RoverProtectSurvey';
import { ScreenReferenceEnum } from '@rover/react-lib/src/components/RoverProtectSignals/utils/analyticsTypes';
import {
  HOMEPAGE_REVIEWS,
  HOMEPAGE_ROVERPROTECT,
} from '@rover/react-lib/src/components/RoverProtectSignals/utils/RoverProtectSignals.constants';
import ScrollToTopOnMount from '@rover/react-lib/src/components/utils/ScrollToTopOnMount';
import type { CountryFooterLink, FetchCountryRegionLinksResponse } from '@rover/types';
import type { HomepageMetaTags } from '@rover/types/src/HomepageConfiguration';

import AppStoreCallout from '../../shared/AppStoreCallout/AppStoreCallout';
import { HomePageLayout } from '../common/styles';

import Footer from './components/FooterLinks/Footer';
import Hero from './components/Hero';
import RoverTestimonials from './components/RoverTestimonials';
import ServicesForEveryPetContainer from './components/ServicesForEveryPet';
import HtmlHeader from './HtmlHeader';

type Props = {
  countryFooterLinks: CountryFooterLink[];
  countryRegionLinks: FetchCountryRegionLinksResponse;
  localizedHelpLink: string;
  roverGuaranteeLink?: string;
  metaTags: Record<HomepageMetaTags, string>;
  cxOperatingHours: string;
  isRolloutRoverProtectCh2Active: boolean;
  isRoverProtectCh2SurveyDisabled: boolean;
  showRoverProtectSignalsWithSurvey: boolean;
  showRoverProtectNoSignalsWithSurvey: boolean;
};

const HomePage = ({
  metaTags: { title, metaDescription },
  countryFooterLinks,
  countryRegionLinks,
  localizedHelpLink,
  roverGuaranteeLink,
  cxOperatingHours,
  isRolloutRoverProtectCh2Active,
  isRoverProtectCh2SurveyDisabled,
  showRoverProtectSignalsWithSurvey,
  showRoverProtectNoSignalsWithSurvey,
}: Props): JSX.Element => {
  const shouldDisplayRoverProtectCh2SignalsAndSurvey =
    isRolloutRoverProtectCh2Active && showRoverProtectSignalsWithSurvey;
  const shouldDisplaySurvey = isRolloutRoverProtectCh2Active && !isRoverProtectCh2SurveyDisabled;
  return (
    <>
      <HtmlHeader title={title} metaDescription={metaDescription} />
      <ScrollToTopOnMount />
      <Hero />
      <MarketingOptIn />
      <HomePageLayout>
        <ServicesForEveryPetContainer
          shouldDisplayRoverProtectCh2SignalsWithSurvey={
            shouldDisplayRoverProtectCh2SignalsAndSurvey
          }
        />
        <RoverTestimonials />
        <HowRoverWorks py={['0x', '8x']}>
          <HowRoverWorks.Display>
            <Trans>Meet local sitters who will treat your pets like family</Trans>
          </HowRoverWorks.Display>
          <HowRoverWorks.List>
            <HowRoverWorks.ListItem image={ImageOptions.MAGNIFYING_GLASS}>
              <HowRoverWorks.ItemHeading step={1}>
                <Trans>Search</Trans>
              </HowRoverWorks.ItemHeading>
              <HowRoverWorks.ItemContent>
                {shouldDisplayRoverProtectCh2SignalsAndSurvey ? (
                  HOMEPAGE_REVIEWS
                ) : (
                  <Trans>
                    Read verified reviews by pet parents like you and choose a sitter who’s a great
                    match for you and your pets.
                  </Trans>
                )}
              </HowRoverWorks.ItemContent>
            </HowRoverWorks.ListItem>
            <HowRoverWorks.ListItem image={ImageOptions.PHONE}>
              <HowRoverWorks.ItemHeading step={2}>
                <Trans>Book & pay</Trans>
              </HowRoverWorks.ItemHeading>
              <HowRoverWorks.ItemContent>
                <Trans>
                  No cash or checks needed—we make it simple to book and make secured payments
                  through our website or app.
                </Trans>
              </HowRoverWorks.ItemContent>
            </HowRoverWorks.ListItem>
            <HowRoverWorks.ListItem image={ImageOptions.COLLAR}>
              <HowRoverWorks.ItemHeading step={3}>
                <Trans>Relax</Trans>
              </HowRoverWorks.ItemHeading>
              <HowRoverWorks.ItemContent>
                {shouldDisplayRoverProtectCh2SignalsAndSurvey
                  ? HOMEPAGE_ROVERPROTECT
                  : cxOperatingHours &&
                    localizedHelpLink &&
                    roverGuaranteeLink && (
                      <Trans>
                        Your pets are in great hands with a loving sitter. Plus, your booking is
                        backed by <Link href={localizedHelpLink}>{cxOperatingHours}</Link> and the{' '}
                        <Link href={roverGuaranteeLink || ''}>Rover Guarantee</Link>.
                      </Trans>
                    )}
              </HowRoverWorks.ItemContent>
            </HowRoverWorks.ListItem>
          </HowRoverWorks.List>
        </HowRoverWorks>
        <AppStoreCallout />
      </HomePageLayout>
      {shouldDisplaySurvey && (
        <RoverProtectSurveyDelay
          showRoverProtectSignalsWithSurvey={showRoverProtectSignalsWithSurvey}
          showRoverProtectNoSignalsWithSurvey={showRoverProtectNoSignalsWithSurvey}
          screenReference={ScreenReferenceEnum.HOMEPAGE}
        />
      )}
      <Footer countryFooterLinks={countryFooterLinks} countryRegionLinks={countryRegionLinks} />
    </>
  );
};

export default HomePage;
