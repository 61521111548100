import React from 'react';

import { Flex, Text } from '@rover/kibble/core';

export type Props = {
  text: string;
};

const BottomSheetSurveyConfirmation = ({ text }: Props): JSX.Element => (
  <Flex>
    <Text size="200">{text}</Text>
  </Flex>
);

export default BottomSheetSurveyConfirmation;
