import type {
  ActionCreator,
  DuckOptions,
  EffectCreator,
  SelectorCreator,
} from '@rover/react-lib/src/lib/redux-duck';
import { createDuck } from '@rover/react-lib/src/lib/redux-duck';
import { emitAnalyticsEvent } from '@rover/rsdk/src/modules/Analytics';
import type { MarketingOptInIntent } from '@rover/types';

import { get, post } from '../../apis/marketingOptIn.api';
import {
  MarketingOptInAccept,
  MarketingOptInDecline,
} from '../../components/modals/MarketingOptIn/analyticsEvents';

export type State = {
  loading: boolean;
  errorMessage: string | undefined;
  submitting: boolean;
  shouldShowMarketingOptInModal: boolean;
};

type Actions = {
  fetchShouldShowMarketingOptInModalPending: ActionCreator<void>;
  fetchShouldShowMarketingOptInModalSuccess: ActionCreator<boolean>;
  fetchShouldShowMarketingOptInModalFailure: ActionCreator<{ message?: string }>;
  submitMarketingOptInIntentPending: ActionCreator<void>;
  submitMarketingOptInIntentSuccess: ActionCreator<void>;
  submitMarketingOptInIntentFailure: ActionCreator<{ message?: string }>;
};

type Effects = {
  fetchShouldShowMarketingOptInModal: EffectCreator<void>;
  submitMarketingOptInIntent: EffectCreator<MarketingOptInIntent>;
};

type Selectors = {
  getShouldShowMarketingOptInModal: SelectorCreator<boolean>;
};

type Options = DuckOptions<State, Actions, Effects, Selectors>;

export const options: Options = {
  name: 'marketingOptIn',
  initialState: {
    loading: false,
    submitting: false,
    errorMessage: '',
    shouldShowMarketingOptInModal: false,
  },
  actions: {
    fetchShouldShowMarketingOptInModalPending: () => (state) => ({ ...state, loading: true }),
    fetchShouldShowMarketingOptInModalSuccess: (showModal) => (state) => ({
      ...state,
      loading: false,
      shouldShowMarketingOptInModal: showModal,
    }),
    fetchShouldShowMarketingOptInModalFailure:
      ({ message }) =>
      (state) => ({
        ...state,
        loading: false,
        errorMessage: message,
      }),
    submitMarketingOptInIntentPending: () => (state) => ({ ...state, submitting: true }),
    submitMarketingOptInIntentSuccess: () => (state) => ({
      ...state,
      submitting: false,
      errorMessage: '',
    }),
    submitMarketingOptInIntentFailure:
      ({ message }) =>
      (state) => ({
        ...state,
        submitting: false,
        errorMessage: message,
      }),
  },
  effects: {
    fetchShouldShowMarketingOptInModal: () => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.fetchShouldShowMarketingOptInModalPending());
      try {
        const { showModal } = await get();
        dispatch(duckActions.fetchShouldShowMarketingOptInModalSuccess(showModal));
      } catch (error) {
        dispatch(duckActions.fetchShouldShowMarketingOptInModalFailure(error as Error));
      }
    },
    submitMarketingOptInIntent:
      ({ turnNotificationsOn }) =>
      async (dispatch, getState, duckActions): Promise<void> => {
        dispatch(duckActions.submitMarketingOptInIntentPending());
        try {
          await post(turnNotificationsOn);
          if (turnNotificationsOn) {
            emitAnalyticsEvent(new MarketingOptInAccept());
          } else {
            emitAnalyticsEvent(new MarketingOptInDecline());
          }
          dispatch(duckActions.submitMarketingOptInIntentSuccess());
        } catch (error) {
          dispatch(duckActions.submitMarketingOptInIntentFailure(error as Error));
        }
      },
  },
  selectors: {
    getShouldShowMarketingOptInModal: (getState, createSelector) =>
      createSelector([getState], (state) => state.shouldShowMarketingOptInModal),
  },
};

export const { actions, selectors, effects, reducer } = createDuck(options);
