import React from 'react';

import { Connect, Provider } from '@rover/react-lib/src/components/utils/Redux';
import {
  effects as countryConfigurationEffects,
  reducer as countryConfiguration,
  selectors as countryConfigurationSelectors,
} from '@rover/react-lib/src/redux/ducks/countryConfiguration.duck';
import {
  effects as currentUserEffects,
  reducer as currentUser,
  selectors as currentUserSelectors,
} from '@rover/react-lib/src/redux/ducks/currentUser.duck';
import {
  effects as experimentsEffects,
  reducer as experiments,
} from '@rover/react-lib/src/redux/ducks/experiments.duck';
import {
  effects as marketingOptInEffects,
  reducer as marketingOptIn,
  selectors,
} from '@rover/react-lib/src/redux/ducks/marketingOptIn.duck';

import MarketingOptIn from './MarketingOptIn';

const effects = {
  fetchShouldShowMarketingOptInModal: marketingOptInEffects.fetchShouldShowMarketingOptInModal,
  submitMarketingOptInIntent: marketingOptInEffects.submitMarketingOptInIntent,
};
const selector = (state: boolean) => ({
  shouldShowMarketingOptInModal: selectors.getShouldShowMarketingOptInModal(state),
  currentUrls: countryConfigurationSelectors.getCurrentCountryUrls(state),
  isImpersonating: currentUserSelectors.isImpersonating(state),
});

const MarketingOptInContainer = ({
  shouldFetchOnMount = false,
}: {
  shouldFetchOnMount?: boolean;
}): JSX.Element | null => (
  <Provider reducers={{ marketingOptIn, experiments, countryConfiguration, currentUser }}>
    <Connect
      selector={selector}
      effects={effects}
      onMount={
        shouldFetchOnMount
          ? [
              countryConfigurationEffects.fetchConfiguration,
              experimentsEffects.fetchExperiments,
              currentUserEffects.fetchCurrentUser,
            ]
          : []
      }
    >
      {(redux) => <MarketingOptIn {...redux} />}
    </Connect>
  </Provider>
);

export default MarketingOptInContainer;
