import React from 'react';

import { reducer as locationInput } from '@rover/react-lib/src/components/formFields/LocationInput/LocationInput.duck';
import { Connect, Provider } from '@rover/react-lib/src/components/utils/Redux';
import { A11yContextProvider } from '@rover/react-lib/src/context/A11yContext';
import { reducer as countryConfiguration } from '@rover/react-lib/src/redux/ducks/countryConfiguration.duck';
import { reducer as experiments } from '@rover/react-lib/src/redux/ducks/experiments.duck';
import {
  reducer as frontendConfiguration,
  selectors as frontendConfigurationSelectors,
} from '@rover/react-lib/src/redux/ducks/frontendConfiguration.duck';
import {
  reducer as pets,
  selectors as petsSelectors,
} from '@rover/react-lib/src/redux/ducks/pets.duck';
import { reducer as serviceTypes } from '@rover/react-lib/src/redux/ducks/serviceTypes.duck';

import SearchBox from './SearchBox';
import {
  actions as searchBoxActions,
  effects as searchBoxEffects,
  reducer as searchBox,
  selectors as searchBoxSelectors,
} from './SearchBox.duck';

export interface Props {
  className?: string;
}

const actions = {
  updateFilters: searchBoxActions.updateFilters,
};

const effects = {
  submit: searchBoxEffects.submit,
};

const selector = (state) => ({
  dateRangePickerLabel: searchBoxSelectors.getDateRangePickerLabel(state),
  endDatePlaceholder: searchBoxSelectors.getEndDatePlaceholder(state),
  filters: searchBoxSelectors.getFilters(state),
  language: frontendConfigurationSelectors.getLanguage(state),
  pets: petsSelectors.getPets(state),
  petSizeBuckets: frontendConfigurationSelectors.getSettings(state).dogSizeBuckets,
  serviceType: searchBoxSelectors.getServiceType(state),
  serviceTypeOptions: searchBoxSelectors.getPetTypeBrowsableServiceTypeOptions(state),
  startDatePlaceholder: searchBoxSelectors.getStartDatePlaceholder(state),
  weightUnit: frontendConfigurationSelectors.getSettings(state).weightUnit,
  isDaycareFilterEarlierExperiment: searchBoxSelectors.getIsDaycareFilterEarlierInExperiment(state),
  isDaycareFilterEarlierExperimentRequired:
    searchBoxSelectors.getIsDaycareFilterEarlierExperimentRequired(state),
  isDaycareFilterOutsideMarketsInExperiment:
    searchBoxSelectors.getIsDaycareFilterOutsideMarketsInExperiment(state),
  isBoardingFilterOutsideMarketsInExperiment:
    searchBoxSelectors.getIsBoardingFilterOutsideMarketsInExperiment(state),
  hasFilterError: searchBoxSelectors.getHasFilterError(state),
});

// Connect.jsx is currently untyped
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const SearchBoxContainer = (props: Props): JSX.Element => {
  return (
    <Provider
      reducers={{
        countryConfiguration,
        frontendConfiguration,
        locationInput,
        pets,
        searchBox,
        serviceTypes,
        experiments,
      }}
    >
      <Connect actions={actions} effects={effects} selector={selector}>
        {(connectedProps) => (
          <A11yContextProvider>
            <SearchBox {...connectedProps} {...props} />
          </A11yContextProvider>
        )}
      </Connect>
    </Provider>
  );
};

export default SearchBoxContainer;
