import React, { FC } from 'react';
import { t } from '@lingui/macro';

import type { HomepageSearchFilters } from '@rover/types/src/HomepageSearchFilters';
import { ApiFrontendConfigurationRetrieve200SettingsDogSizeBuckets } from '@rover/rsdk/src/apiClient/latest';
import { useI18n } from '@rover/rsdk/src/modules/I18n';
import PetSize from '@rover/shared/js/search/components/PetSize';
import getIsCatOnlyPetType from '@rover/shared/js/search/utilities/getIsCatOnlyPetType';

import type { UpdateFiltersFunc } from '../UpdateFiltersFunc';

export interface Props {
  filters: HomepageSearchFilters;
  petSizeBuckets: ApiFrontendConfigurationRetrieve200SettingsDogSizeBuckets;
  updateFilters: UpdateFiltersFunc;
  weightUnit: string;
}

const PetSizeConnector: FC<Props> = ({
  filters,
  petSizeBuckets,
  updateFilters,
  weightUnit,
}: Props) => {
  const { i18n } = useI18n();

  if (getIsCatOnlyPetType(filters.petType)) {
    return null;
  }

  return (
    <PetSize
      displayAsHomePageSearchBoxButtons
      label={i18n._(t`My Dog Size`)}
      onChange={(sizeKey) => {
        updateFilters({ [sizeKey]: !filters[sizeKey] });
      }}
      petSizeBuckets={petSizeBuckets}
      searchFilters={filters}
      weightUnit={weightUnit}
    />
  );
};

export default PetSizeConnector;
