import React, { FC, useId } from 'react';
import { MessageDescriptor } from '@lingui/core';
import { Trans } from '@lingui/react';

import { Flex } from '@rover/kibble/core';
import { CalendarDirection } from '@rover/react-lib/src/components/datetime/DatePicker';
import DateRangePicker from '@rover/react-lib/src/components/datetime/DateRangePicker';
import getMaxRequestDate from '@rover/react-lib/src/utils/getMaxRequestDate';
import FilterLabel from '@rover/shared/js/search/components/FilterLabel';
import type {
  DateRange,
  DateRangeEnum,
  DateRangeValidationError,
} from '@rover/types/src/datetime/DateRange';
import type { HomepageSearchFilters } from '@rover/types/src/HomepageSearchFilters';

import type { UpdateFiltersFunc } from '../UpdateFiltersFunc';

export interface Props {
  dateRangePickerLabel: { id: string };
  endDatePlaceholder?: MessageDescriptor;
  filters: HomepageSearchFilters;
  language: string;
  startDatePlaceholder?: MessageDescriptor;
  updateFilters: UpdateFiltersFunc;
  marginBottomPixelHack?: string;
  onInputBlurDatePicker?: (
    dateRange: DateRangeEnum,
    e?: React.FocusEvent<HTMLDivElement, Element>,
    isDateValid?: boolean
  ) => void;
  shouldAutoSwitch?: boolean;
  validationErrors?: DateRangeValidationError;
}

const DateRangePickerConnector: FC<Props> = ({
  dateRangePickerLabel,
  endDatePlaceholder,
  filters,
  language,
  startDatePlaceholder,
  updateFilters,
  marginBottomPixelHack = '-15px',
  onInputBlurDatePicker,
  shouldAutoSwitch,
  validationErrors,
}: Props) => {
  const dateRangeInputGroup = useId();
  const maxDate = getMaxRequestDate();

  return (
    // @ts-expect-error the value passed to `mb` is legit, but the types don't handle it yet
    <Flex flexDirection="column" flexGrow="1" alignItems="flex-end" mb={marginBottomPixelHack}>
      <FilterLabel id={dateRangeInputGroup}>
        <Trans id={dateRangePickerLabel.id} />
      </FilterLabel>
      <DateRangePicker
        allowKeyboardInput
        calendarDirection={CalendarDirection.FLEX}
        endDate={filters.dateRange.endDate}
        endPlaceholder={endDatePlaceholder}
        id={dateRangeInputGroup}
        maxDate={maxDate}
        language={language}
        onChange={(dateRange: DateRange | null | undefined) => {
          updateFilters({
            dateRange: { startDate: dateRange?.startDate, endDate: dateRange?.endDate },
          });
        }}
        showArrow
        startDate={filters.dateRange.startDate}
        startPlaceholder={startDatePlaceholder}
        onInputBlurDatePicker={onInputBlurDatePicker}
        shouldAutoSwitch={shouldAutoSwitch}
        validationErrors={validationErrors}
      />
    </Flex>
  );
};

export default DateRangePickerConnector;
